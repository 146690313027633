import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Garden() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Garden Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="QFM offers specialised garden freight services for transporting landscaping supplies, horticultural equipment, and garden products. Our secure logistics solutions ensure your shipments reach their destination safely and on time across Australia." />
        <meta name="keywords" content="Garden Freight Services, Landscaping Supplies Transport, Horticultural Equipment Shipping, Garden Products Logistics, Australia Freight, Secure Garden Transport, QFM" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Garden Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Secure garden freight services for transporting landscaping supplies, horticultural equipment, and garden products. Trust QFM for customised logistics that deliver your shipments safely across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-garden.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/garden-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/garden-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/garden-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/garden-freight-services",
                "name": "Garden Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised garden freight services for transporting landscaping supplies, horticultural equipment, and garden products. Our secure logistics solutions ensure your shipments reach their destination safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/garden-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/garden-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Garden Freight Services",
                    "item": "https://qfmlogistics.com.au/garden-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/garden-freight-services#service",
                "name": "Garden Freight Services",
                "description": "Our garden freight services are designed for the secure transport of landscaping supplies, horticultural equipment, and garden products. We provide customised logistics solutions that ensure your shipments are handled with care and delivered on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-garden.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your garden freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-garden.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-garden.jpg",
                "description": "Secure transport for landscaping supplies and garden equipment, ensuring careful handling during transit.",
                "name": "Garden Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyr ightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/garden-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-garden-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-garden-two.jpg",
                "description": "Efficient freight solutions for landscaping supplies, garden tools, and horticultural equipment, ensuring secure and timely delivery.",
                "name": "Garden Freight Services - Reliable Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/garden-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/garden-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of garden products do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle the secure transport of various garden supplies, including landscaping materials, garden tools, irrigation systems, fencing, decorative stones, and outdoor furniture. Our logistics solutions support nurseries, garden centres, and landscaping suppliers."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you transport bulk garden materials like soil and mulch?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We do not provide freight services for loose bulk materials such as soil, mulch, or sand, as we do not operate tipper trucks. However, we transport bagged or palletised landscaping products, including decorative stones, packaged fertilisers, and pre-assembled garden structures."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide freight solutions for commercial landscaping projects?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we support commercial landscaping businesses with tailored transport solutions. Whether you need to move large volumes of fencing, irrigation supplies, or garden décor, we ensure efficient and timely deliveries to project sites across Australia."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for garden freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our freight services cover Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Whether your shipment is destined for a metropolitan garden centre or a remote landscaping project, we provide reliable transport solutions."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I track my garden freight shipment?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We offer real-time shipment tracking, allowing you to monitor your freight’s progress from dispatch to final delivery. You’ll receive estimated arrival times and status updates for added peace of mind."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I request a quote for garden freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a quote, visit our Contact Us page or call our team. Provide details such as pickup and delivery locations, shipment volume, and any special handling requirements, and we’ll tailor a competitive solution for your needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/garden-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}
        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Garden Freight Services</h1>

            <p>
              Our garden freight services are designed for the secure transport of landscaping supplies, gardening tools, and horticultural equipment. We focus on customised logistics solutions to ensure that your garden products are handled with care throughout the journey.
              With a nationwide network spanning urban and remote areas of Australia, we guarantee timely delivery that supports your landscaping projects and retail operations.
            </p>
            {!isMobile && (
              <p>
                We specialise in the secure transport of garden products and supplies, including planters, irrigation systems, and bulk soil deliveries.
                Our logistics network spans Australia, ensuring reliable delivery to garden centres, wholesale distributors, and project sites, helping businesses maintain stock levels and meet seasonal demands.
              </p>
            )}

          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-garden.jpg"
              alt="Secure transport for landscaping supplies and horticultural equipment across Australia"
              title="Garden Freight Services - Secure & Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>
        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Customised Garden Freight Solutions for Your Landscaping Needs</h5>
            )}
            <p>
              Our tailored logistics ensure that your garden freight is handled securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>
        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Garden Freight by QFM</h2>
            <ul>
              <li>
                <b>Specialised Transport for Garden Products:</b> We provide secure freight solutions for garden décor, irrigation systems, planters, greenhouses, and outdoor equipment, ensuring reliable deliveries for nurseries and retailers.
              </li>
              <li>
                <b>Efficient Logistics for Landscaping Supplies:</b> From garden edging and pavers to fencing materials and synthetic turf, we offer tailored freight solutions to meet the needs of garden centres, landscapers, and outdoor living suppliers.
              </li>
              <li>
                <b>Nationwide Distribution Network:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas, we provide consistent and dependable deliveries for businesses across Australia.
              </li>
              <li>
                <b>Streamlined Freight Management:</b> With scheduled deliveries, real-time tracking, and dedicated customer support, we help businesses optimise their supply chain and meet seasonal demands efficiently.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-garden-two.jpg"
              loading="lazy"
              alt="Efficient freight solutions for landscaping supplies, garden tools, and horticultural equipment, ensuring secure and timely delivery"
              title="Garden Freight Services - Reliable Transport by QFM"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of garden products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle the secure transport of various garden supplies, including landscaping materials, garden tools, irrigation systems, fencing, decorative stones, and outdoor furniture. Our logistics solutions support nurseries, garden centres, and landscaping suppliers.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you transport bulk garden materials like soil and mulch?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  We do not provide freight services for loose bulk materials such as soil, mulch, or sand, as we do not operate tipper trucks. However, we transport bagged or palletised landscaping products, including decorative stones, packaged fertilisers, and pre-assembled garden structures.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you provide freight solutions for commercial landscaping projects?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, we support commercial landscaping businesses with tailored transport solutions. Whether you need to move large volumes of fencing, irrigation supplies, or garden décor, we ensure efficient and timely deliveries to project sites across Australia.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Which regions do you service for garden freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '280px' : '0px' }}>
                <p>
                  Our freight services cover Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Whether your shipment is destined for a metropolitan garden centre or a remote landscaping project, we provide reliable transport solutions.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I track my garden freight shipment?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '250px' : '0px' }}>
                <p>
                  We offer real-time shipment tracking, allowing you to monitor your freight’s progress from dispatch to final delivery. You’ll receive estimated arrival times and status updates for added peace of mind.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How do I request a quote for garden freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To receive a quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our team. Provide details such as pickup and delivery locations, shipment volume, and any special handling requirements, and we’ll tailor a competitive solution for your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Garden;