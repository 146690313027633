import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Audio() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/goods-and-products"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Audio Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM offers expert transport solutions for audio equipment across Melbourne, Sydney, Brisbane, and Australia-wide. We handle speakers, amplifiers, mixing consoles, and professional sound gear with care and precision." />
                <meta name="keywords" content="Audio Freight Services, Audio Equipment Transport, Speaker Freight, Amplifier Shipping, Music Equipment Logistics, Professional Sound Freight, Event Equipment Transport, Concert Gear Shipping, Melbourne Audio Logistics, Secure Freight Handling" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Audio Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Reliable freight solutions for audio equipment, including speakers, amplifiers, and mixing consoles. We offer nationwide coverage across Australia with professional handling and secure transit." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-audio.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/audio-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/audio-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/audio-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/audio-freight-services",
                                "name": "Audio Freight Services | Secure Transport by QFM",
                                "description": "Specialised transport solutions for audio equipment including speakers, amplifiers, and event gear. Trusted logistics provider across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/audio-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/audio-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Goods and Products",
                                        "item": "https://qfmlogistics.com.au/goods-and-products"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Audio Freight Services",
                                        "item": "https://qfmlogistics.com.au/audio-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/audio-freight-services#service",
                                "name": "Audio Freight Services",
                                "description": "Professional and secure transport for audio equipment, including concert and studio gear. Reliable solutions across Melbourne, Sydney, Brisbane, and regional Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": [
                                    "https://qfmlogistics.com.au/images/qfm-commodity-audio.jpg",
                                    "https://qfmlogistics.com.au/images/qfm-commodity-audio-two.jpg"
                                ],
                                "potentialAction": {
                                    "@type": "Action",
                                    "name": "Request a Quote",
                                    "url": "https://qfmlogistics.com.au/contact-us",
                                    "description": "Get a custom quote for your audio freight needs."
                                }
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-audio.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-audio.jpg",
                                "description": "Secure transport of speakers, amplifiers, and professional sound equipment across Australia.",
                                "name": "Audio Freight Services - Secure Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": { "@id": "https://qfmlogistics.com.au/audio-freight-services#webpage" }
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-audio-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-commodity-audio-two.jpg",
                                "description": "Specialised logistics for high-value audio equipment including speakers, amplifiers, and concert gear.",
                                "name": "Professional Audio Freight Handling - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": { "@id": "https://qfmlogistics.com.au/audio-freight-services#webpage" }
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/audio-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What areas do you service for audio freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We provide nationwide audio freight services, covering major cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional areas. Whether you need to move individual equipment pieces or large-scale production setups, we offer tailored transport solutions."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do you ensure the safety of audio equipment during transit?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We utilise industry-leading handling techniques, secure tie-downs, and vibration control measures to protect your audio gear during transport. Our logistics team follows best practices to minimise movement and prevent potential damage."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you transport audio equipment for concerts and events?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer specialised transport solutions for concerts, music festivals, corporate events, and live productions. Our team works with event organisers to ensure timely delivery of sound systems, mixing consoles, and related equipment."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there restrictions on the size or weight of shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We can transport audio freight of all sizes, from small speaker sets to full-scale stage and production setups. If you have large or heavy items, get in touch, and we’ll recommend the best freight solution for your needs."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide express delivery for urgent audio shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer express freight services for urgent deliveries. Whether you need last-minute equipment for an event or a time-sensitive studio setup, we can arrange fast and reliable transport to meet your deadlines."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for audio freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "To get a personalised quote, visit our Contact Us page and provide details such as pickup and delivery locations, type of equipment, and any specific requirements. Our team will get back to you promptly with a tailored solution."
                                        }
                                    }
                                ],
                                "isPartOf": {
                                    "@id": "https://qfmlogistics.com.au/audio-freight-services#webpage"
                                }
                            }
                        ]
                    })}
                </script>

                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-C758R2N8C5');
        `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Audio Freight Services</h1>
                        {!isMobile && (
                            <p>
                                Moving professional audio equipment demands precision and expert handling. At QFM, we provide secure and efficient freight solutions for studio gear, live event sound systems, and broadcasting equipment. Whether you're a retailer, event organiser, or production company, we ensure your shipments arrive safely, on time, and in pristine condition.
                            </p>
                        )}
                        <p>
                            From compact speakers to large-scale concert sound systems, we offer tailored transport options, including express delivery and advanced tracking for real-time shipment visibility. Our nationwide network spans Melbourne, Sydney, Brisbane, Perth, and regional locations, providing seamless logistics to support the audio and entertainment industries.
                        </p>
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-commodity-audio.jpg"
                            alt="Secure audio freight transport for speakers, mixers, and studio equipment across Australia"
                            title="Audio Freight Services - Professional logistics for sound and production gear by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Specialised Freight Solutions for Audio Equipment</h5>
                        )}
                        <p>
                            Ensure safe and timely delivery of professional audio gear, whether for live events, studio installations, or retail distribution. Our Melbourne-based logistics services provide secure transport with expert handling. <Link to="/goods-and-products">Explore our full range of freight solutions</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Audio Freight by QFM</h2>
                        <ul>
                            <li><b>Specialised Audio Freight Services:</b> Secure transport for speakers, amplifiers, mixing consoles, and professional sound systems with expert handling.</li>
                            <li><b>Reliable & Time-Sensitive Logistics:</b> Fast and efficient freight solutions tailored for live events, studios, and retail distribution across Australia.</li>
                            <li><b>Precision Handling for Delicate Equipment:</b> Minimising shock and vibration during transit to protect high-value audio gear. *Note: QFM provides transport services only, not packaging.*</li>
                            <li><b>Extensive Nationwide Reach:</b> Covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with seamless delivery solutions.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-commodity-audio-two.jpg"
                            loading="lazy"
                            alt="Specialised transport for audio equipment including speakers, amplifiers, and consoles across Melbourne and Australia"
                            title="Audio Freight Services - Secure and Reliable Logistics by Quan Freight Management"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/goods-and-products"><h4>View all Commodities</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="express-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>What areas do you service for audio freight?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    We provide nationwide audio freight services, covering major cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional areas. Whether you need to move individual equipment pieces or large-scale production setups, we offer tailored transport solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>How do you ensure the safety of audio equipment during transit?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}>
                                <p>
                                    We utilise industry-leading handling techniques, secure tie-downs, and vibration control measures to protect your audio gear during transport. Our logistics team follows best practices to minimise movement and prevent potential damage.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>Do you transport audio equipment for concerts and events?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}>
                                <p>
                                    Yes, we offer specialised transport solutions for concerts, music festivals, corporate events, and live productions. Our team works with event organisers to ensure timely delivery of sound systems, mixing consoles, and related equipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>Are there restrictions on the size or weight of shipments?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}>
                                <p>
                                    We can transport audio freight of all sizes, from small speaker sets to full-scale stage and production setups. If you have large or heavy items, get in touch, and we’ll recommend the best freight solution for your needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                            <h5>Do you provide express delivery for urgent audio shipments?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "270px" : "0px" }}>
                                <p>
                                    Yes, we offer express freight services for urgent deliveries. Whether you need last-minute equipment for an event or a time-sensitive studio setup, we can arrange fast and reliable transport to meet your deadlines.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
                            <h5>How can I request a quote for audio freight?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}>
                                <p>
                                    To get a personalised quote, visit our <Link to="/contact-us">Contact Us</Link> page and provide details such as pickup and delivery locations, type of equipment, and any specific requirements. Our team will get back to you promptly with a tailored solution.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Audio;