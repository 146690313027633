import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Fitness() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Fitness Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised fitness freight services for transporting gym equipment, sports machinery, and fitness accessories. Our secure and customised logistics ensure your high-value fitness assets are delivered safely across Australia."
        />
        <meta
          name="keywords"
          content="Fitness Freight Services, Gym Equipment Transport, Sports Machinery Shipping, Fitness Logistics, Australia Freight, Secure Gym Transport, Melbourne Fitness Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Fitness Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and reliable freight solutions for gym equipment and sports machinery. Trust QFM to deliver your high-value fitness assets safely and efficiently across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-fitness.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/fitness-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/fitness-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/fitness-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/fitness-freight-services",
                "name": "Fitness Freight Services | Logistics Solutions by QFM",
                "description":
                  "Secure and specialised freight services for transporting gym equipment, sports machinery, and fitness accessories across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/fitness-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/fitness-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Fitness Freight Services",
                    "item": "https://qfmlogistics.com.au/fitness-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/fitness-freight-services#service",
                "name": "Fitness Freight Services",
                "description": "Our fitness freight services provide secure transport for gym equipment, sports machinery, and fitness accessories. We offer customised logistics solutions that ensure your high-value fitness assets are delivered safely and on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-fitness.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-fitness-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your fitness freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-fitness.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-fitness.jpg",
                "description": "Secure transport solutions for gym equipment and fitness accessories across Australia.",
                "name": "Fitness Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/fitness-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-fitness-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-fitness-two.jpg",
                "description": "Specialised logistics for transporting gym equipment and fitness accessories with precision and care.",
                "name": "Fitness Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/fitness-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/fitness-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of fitness equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle the freight of various fitness equipment, including treadmills, weight machines, free weights, spin bikes, and rowing machines. Our logistics solutions cater to both individual shipments and large-scale gym outfitting projects."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide logistics solutions for gym fit-outs?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer customised freight solutions for fitness centres, personal training studios, and commercial gyms. Whether you're setting up a new facility or upgrading existing equipment, we streamline the delivery process to meet your project timelines."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you handle bulk fitness equipment shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We manage high-volume freight for gyms, wholesalers, and retailers, ensuring seamless transport of bulk fitness equipment. Our freight options include palletised shipping and scheduled multi-drop deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for fitness freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide freight services covering all major cities, including Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote areas. Our extensive network ensures reliable delivery no matter the location."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I request a quote for fitness freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Requesting a quote is easy. Visit our <a href='https://qfmlogistics.com.au/contact-us'>Contact Us</a> page or call our team with details such as pickup and delivery locations, equipment type, quantity, and any special handling needs. We’ll provide a tailored freight solution that fits your requirements."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/fitness-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Fitness Freight Services</h1>
            {!isMobile && (
              <p>
                Whether you are equipping a new gym, upgrading an existing facility, or managing large-scale sports infrastructure, our fitness freight services provide secure and reliable transport for gym equipment and sports machinery. From treadmills and weight racks to commercial fitness stations, we ensure precision handling and on-time delivery to keep your facility running smoothly.
              </p>
            )}
            <p>
              Leveraging a nationwide logistics network, we guarantee that your fitness assets reach their destination safely—supporting fitness centres and sports facilities across Australia.
              We specialise in the secure transport of gym equipment using advanced logistics, ensuring reliable delivery from urban centres to remote regions.
            </p>

          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-fitness.jpg"
              alt="Secure transport for gym equipment and fitness accessories across Australia"
              title="Fitness Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Tailored Fitness Freight Solutions for Your Gym Equipment</h5>
            )}
            <p>
              Our customised freight services ensure your fitness equipment is delivered securely and on schedule. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Fitness Equipment Freight by QFM</h2>
            <ul>
              <li>
                <b>Expert Freight for Gym & Fitness Equipment:</b> Secure transport for treadmills, weight machines, free weights, and commercial gym setups with industry-leading handling techniques.
              </li>
              <li>
                <b>Customised Delivery Solutions:</b> Whether setting up a new fitness centre or replenishing stock, we offer tailored freight services to match your schedule and equipment needs.
              </li>
              <li>
                <b>Nationwide Distribution Network:</b> Reliable logistics solutions for gyms, sports facilities, and fitness retailers across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations.
              </li>
              <li>
                <b>End-to-End Shipment Visibility:</b> Track your fitness equipment in real-time with live updates, ensuring on-time delivery and secure handling from pickup to final destination.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-fitness-two.jpg"
              loading="lazy"
              alt="Professional freight for gym equipment ensuring secure and timely delivery"
              title="Fitness Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
  <div className="faq-text-content">
    <h2>FAQ</h2>

    {/* FAQ 1 */}
    <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
      <h5>What types of fitness equipment do you transport?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
        <p>
          We handle the freight of various fitness equipment, including treadmills, weight machines, free weights, spin bikes, and rowing machines. Our logistics solutions cater to both individual shipments and large-scale gym outfitting projects.
        </p>
      </div>
    </div>

    {/* FAQ 2 */}
    <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
      <h5>Do you provide logistics solutions for gym fit-outs?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
        <p>
          Yes, we offer customised freight solutions for fitness centres, personal training studios, and commercial gyms. Whether you're setting up a new facility or upgrading existing equipment, we streamline the delivery process to meet your project timelines.
        </p>
      </div>
    </div>

    {/* FAQ 3 */}
    <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
      <h5>Can you handle bulk fitness equipment shipments?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
        <p>
          Absolutely. We manage high-volume freight for gyms, wholesalers, and retailers, ensuring seamless transport of bulk fitness equipment. Our freight options include palletised shipping and scheduled multi-drop deliveries.
        </p>
      </div>
    </div>

    {/* FAQ 4 */}
    <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
      <h5>Which regions do you service for fitness freight?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
        <p>
          We provide nationwide freight services covering all major cities, including Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote areas. Our extensive network ensures reliable delivery no matter the location.
        </p>
      </div>
    </div>

    {/* FAQ 5 */}
    <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
      <h5>How do I request a quote for fitness freight services?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
        <p>
          Requesting a quote is easy. Visit our <Link to="/contact-us">Contact Us</Link> page or call our team with details such as pickup and delivery locations, equipment type, quantity, and any special handling needs. We’ll provide a tailored freight solution that fits your requirements.
        </p>
      </div>
    </div>
  </div>
</div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Fitness;