// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="App-footer">
      {/* <p>&copy; 2025 Quan Freight Management Pty Ltd. All rights reserved.</p> */}
      <footer>
  <p>
    © 2025 Quan Freight Management Pty Ltd. <Link to="/terms-and-conditions" className="terms-link">Terms & Conditions.</Link>
  </p>
</footer>
      <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=k8FWjCZaaeqkz3hWzzub1iTiEb1XV9HDxdGvWBI3oQ3v3E19E8mVxI43yQGh"></script></span>
    </footer>
  );
}

export default Footer;