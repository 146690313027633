import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Textiles() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Textiles Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised textiles freight services for transporting clothing, fabrics, and accessories. Our secure and efficient logistics solutions ensure your textile shipments are delivered on time across Australia."
        />
        <meta
          name="keywords"
          content="Textiles Freight Services, Clothing Transport, Fabric Shipping, Textile Logistics, Australia Freight, Secure Textile Transport, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Textiles Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure textiles freight services for transporting clothing, fabrics, and accessories. Trust QFM to deliver your textile shipments safely and on schedule across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-textiles.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/textiles-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/textiles-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/textiles-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/textiles-freight-services",
                "name": "Textiles Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised textiles freight services for transporting clothing, fabrics, and accessories. Our secure logistics solutions ensure your textile shipments are delivered safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/textiles-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/textiles-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Textiles Freight Services",
                    "item": "https://qfmlogistics.com.au/textiles-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/textiles-freight-services#service",
                "name": "Textiles Freight Services",
                "description": "Our textiles freight services specialise in transporting clothing, fabrics, and accessories with care. We provide secure, tailored logistics solutions that ensure your textile shipments are handled efficiently and delivered on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-textiles.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-textiles-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your textiles freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-textiles.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-textiles.jpg",
                "description": "Secure transport for clothing, fabrics, and accessories ensuring high-quality handling throughout transit.",
                "name": "Textiles Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/textiles-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-textiles-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-textiles-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-textiles-two.jpg",
                "description": "Reliable transport solutions for fabrics, clothing, and textile materials across Australia.",
                "name": "Melbourne Textiles Freight - Secure and Efficient Transport by Quan Freight Management",
                "author": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "license": {
                  "@id": "https://qfmlogistics.com.au#license"
                },
                "creator": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/textiles-freight-services#webpage"
                }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/textiles-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of textile products can you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a wide range of textile freight, including fabric rolls, raw materials, finished garments, home textiles, and upholstery. Whether you're a clothing manufacturer, wholesaler, or retailer, we provide secure transport solutions to meet your business needs. We also cater to bulk orders for fashion houses, event planners, and commercial textile distributors."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer specialised handling for delicate fabrics?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we use protective wrapping, secure strapping, and climate-sensitive transport methods to prevent damage to delicate fabrics such as silk, lace, and designer textiles. Our team takes extra precautions when handling high-value materials, ensuring they remain free from dust, moisture, and creases during transit."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you accommodate large-scale textile shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We specialise in bulk textile shipments for manufacturers, distributors, and retailers across Australia. Whether you need to transport thousands of metres of fabric, packaged apparel, or industrial-grade textiles, we offer scalable freight solutions, including palletised and containerised transport, to ensure seamless delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What regions do you cover for textile freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide textile freight services, covering major Australian cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and rural locations. Our logistics network ensures timely and efficient transport, regardless of distance, helping textile businesses maintain a steady supply chain."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I get a quote for textile freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can request a quote by visiting our Contact Us page or calling our team. Please provide key details, including the type of textiles, shipment volume, pickup and delivery locations, and any special handling requirements. We will provide a customised freight solution tailored to your specific transport needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide express freight for urgent textile deliveries?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer express and time-sensitive freight solutions for urgent textile shipments. Whether you need last-minute deliveries for fashion retailers, fast turnaround for manufacturers, or emergency restocks for designers, our expedited transport services ensure your textiles reach their destination quickly and efficiently."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/textiles-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Textiles Freight Services</h1>
            <p>
              From delicate fabrics to bulk garment shipments, our textiles freight services are built to handle the unique demands of the fashion and textile industries. We cater to manufacturers, wholesalers, and retailers, ensuring that everything from high-end apparel to industrial textiles is transported with care and efficiency.

            </p>
            {!isMobile && (
              <p>
                With a dedicated logistics network covering Australia, we provide secure and timely deliveries, keeping your supply chain efficient and responsive to market demands.
                We specialise in the reliable transport of clothing, upholstery fabrics, and textile goods.
                Our nationwide reach ensures smooth deliveries from production hubs to retail outlets and distribution centres.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-textiles.jpg"
              alt="Secure transport for clothing, fabrics, and accessories across Australia"
              title="Textiles Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Tailored Textiles Freight Solutions for Seamless Supply Chains</h5>
            )}
            <p>
              We provide specialised freight solutions designed to transport textiles, apparel, and fabric materials with precision and care.
              <Link to="/goods-and-products"> Explore our full range of goods and products</Link> for a complete logistics solution tailored to your industry.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Textiles Freight by QFM</h2>
            <ul>
              <li>
                <b>Delicate Fabric Handling:</b> We ensure the safe transport of delicate fabrics, apparel, and raw textiles, preventing creases and damage during transit.
              </li>
              <li>
                <b>Custom Freight Solutions:</b> Whether shipping bulk fabric rolls or high-end fashion apparel, our logistics adapt to your unique business requirements.
              </li>
              <li>
                <b>Nationwide Distribution:</b> Reliable freight services connecting manufacturers, retailers, and warehouses across Melbourne, Sydney, Brisbane, Perth, and beyond.
              </li>
              <li>
                <b>Time-Sensitive Deliveries:</b> We offer scheduled and express freight options to ensure fashion and textile businesses meet seasonal and production deadlines.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-textiles-two.jpg"
              loading="lazy"
              alt="Reliable transport solutions for fabrics, clothing, and textile materials across Australia"
              title="Melbourne Textiles Freight - Secure and Efficient Transport by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of textile products can you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  We handle a wide range of textile freight, including fabric rolls, raw materials, finished garments, home textiles, and upholstery. Whether you're a clothing manufacturer, wholesaler, or retailer, we provide secure transport solutions to meet your business needs. We also cater to bulk orders for fashion houses, event planners, and commercial textile distributors.
                </p>
              </div>
            </div>

            <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer specialised handling for delicate fabrics?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "280px" : "0px" }}>
                <p>
                  Yes, we use protective wrapping, secure strapping, and climate-sensitive transport methods to prevent damage to delicate fabrics such as silk, lace, and designer textiles. Our team takes extra precautions when handling high-value materials, ensuring they remain free from dust, moisture, and creases during transit.
                </p>
              </div>
            </div>

            <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you accommodate large-scale textile shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "280px" : "0px" }}>
                <p>
                  Absolutely. We specialise in bulk textile shipments for manufacturers, distributors, and retailers across Australia. Whether you need to transport thousands of metres of fabric, packaged apparel, or industrial-grade textiles, we offer scalable freight solutions, including palletised and containerised transport, to ensure seamless delivery.
                </p>
              </div>
            </div>

            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>What regions do you cover for textile freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "280px" : "0px" }}>
                <p>
                  We provide nationwide textile freight services, covering major Australian cities such as Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and rural locations. Our logistics network ensures timely and efficient transport, regardless of distance, helping textile businesses maintain a steady supply chain.
                </p>
              </div>
            </div>

            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I get a quote for textile freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "280px" : "0px" }}>
                <p>
                  You can request a quote by visiting our <Link to="/contact-us">Contact Us</Link> page or calling our team. Please provide key details, including the type of textiles, shipment volume, pickup and delivery locations, and any special handling requirements. We will provide a customised freight solution tailored to your specific transport needs.
                </p>
              </div>
            </div>

            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>Do you provide express freight for urgent textile deliveries?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "280px" : "0px" }}>
                <p>
                  Yes, we offer express and time-sensitive freight solutions for urgent textile shipments. Whether you need last-minute deliveries for fashion retailers, fast turnaround for manufacturers, or emergency restocks for designers, our expedited transport services ensure your textiles reach their destination quickly and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div >
  );
}

export default Textiles;