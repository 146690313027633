import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Envelope() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Envelope Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM offers reliable and efficient envelope freight services across Melbourne, Brisbane, and Australia-wide. Designed for essential documents and lightweight shipments, our services ensure secure handling, timely delivery, and advanced tracking solutions." />
                <meta name="keywords" content="Envelope Freight Services, Quan Freight Management, QFM Logistics, Envelope Transport Australia, Melbourne Envelope Freight, Brisbane Envelope Logistics, Document Delivery, Secure Logistics, Road Freight, Air Freight, Timely Delivery, Safe Freight Handling, Cost-Effective Freight Services, Advanced Freight Technology" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Envelope Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's envelope freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide secure and timely delivery of essential documents with advanced logistics solutions." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-envelope.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/envelope-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/envelope-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                          "@context": "https://schema.org",
                          "@graph": [
                            {
                              "@type": "WebPage",
                              "@id": "https://qfmlogistics.com.au/envelope-freight-services#webpage",
                              "url": "https://qfmlogistics.com.au/envelope-freight-services",
                              "name": "Envelope Freight Services | Secure Delivery by QFM",
                              "description": "QFM provides professional and secure envelope freight services, connecting Melbourne with cities across Australia, including Sydney, Brisbane, Perth, and Adelaide.",
                              "publisher": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "inLanguage": "en-AU",
                              "breadcrumb": {
                                "@id": "https://qfmlogistics.com.au/envelope-freight-services#breadcrumb"
                              }
                            },
                            {
                              "@type": "Organization",
                              "@id": "https://qfmlogistics.com.au#organization",
                              "name": "Quan Freight Management",
                              "url": "https://qfmlogistics.com.au",
                              "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                              "contactPoint": {
                                "@type": "ContactPoint",
                                "email": "contact@qfmlogistics.com.au",
                                "contactType": "Customer Service",
                                "areaServed": "AU"
                              },
                              "sameAs": [
                                "https://www.linkedin.com/company/qfm-logistics"
                              ]
                            },
                            {
                              "@type": "BreadcrumbList",
                              "@id": "https://qfmlogistics.com.au/envelope-freight-services#breadcrumb",
                              "itemListElement": [
                                {
                                  "@type": "ListItem",
                                  "position": 1,
                                  "name": "Home",
                                  "item": "https://qfmlogistics.com.au/"
                                },
                                {
                                  "@type": "ListItem",
                                  "position": 2,
                                  "name": "Types of Freight",
                                  "item": "https://qfmlogistics.com.au/types-of-freight"
                                },
                                {
                                  "@type": "ListItem",
                                  "position": 3,
                                  "name": "Envelope Freight Services",
                                  "item": "https://qfmlogistics.com.au/envelope-freight-services"
                                }
                              ]
                            },
                            {
                              "@type": "Service",
                              "@id": "https://qfmlogistics.com.au/envelope-freight-services#service",
                              "name": "Envelope Freight Services",
                              "description": "Our envelope freight services offer secure and reliable delivery solutions across Australia, including handling confidential and legal documents.",
                              "provider": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "areaServed": "Australia",
                              "availableChannel": {
                                "@type": "ServiceChannel",
                                "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                              },
                              "image": "https://qfmlogistics.com.au/images/qfm-item-types-envelope.jpg"
                            },
                            {
                              "@type": "ImageObject",
                              "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-envelope.jpg",
                              "url": "https://qfmlogistics.com.au/images/qfm-item-types-envelope.jpg",
                              "description": "Professional envelope handling and secure logistics across Australia",
                              "name": "Envelope Freight Services - Reliable Logistics Solutions",
                              "author": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "license": {
                                "@id": "https://qfmlogistics.com.au#license"
                              },
                              "creator": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "copyrightNotice": "© Quan Freight Management",
                              "creditText": "Quan Freight Management",
                              "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                              "@type": "ImageObject",
                              "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-envelope-two.jpg",
                              "url": "https://qfmlogistics.com.au/images/qfm-item-types-envelope-two.jpg",
                              "description": "Secure handling and reliable envelope delivery logistics",
                              "name": "Melbourne Freight Handling - Specialised Envelope Services by QFM",
                              "author": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "license": {
                                "@id": "https://qfmlogistics.com.au#license"
                              },
                              "creator": {
                                "@id": "https://qfmlogistics.com.au#organization"
                              },
                              "copyrightNotice": "© Quan Freight Management",
                              "creditText": "Quan Freight Management",
                              "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                              "@type": "FAQPage",
                              "@id": "https://qfmlogistics.com.au/envelope-freight-services#faq",
                              "mainEntity": [
                                {
                                  "@type": "Question",
                                  "name": "Which areas do you serve for envelope freight services?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Our envelope freight services connect Melbourne with major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote areas across Australia."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                },
                                {
                                  "@type": "Question",
                                  "name": "What types of envelopes can you handle?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "We handle all standard envelope sizes, including A4, legal, and custom sizes, ensuring secure transport for confidential and important documents."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                },
                                {
                                  "@type": "Question",
                                  "name": "Are envelopes tracked during transit?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Yes, all envelope shipments include tracking to provide real-time updates and ensure complete transparency from pickup to delivery."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                },
                                {
                                  "@type": "Question",
                                  "name": "How can I request a quote for envelope freight?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Visit our Contact Us page at https://qfmlogistics.com.au/contact-us and provide the pickup and delivery locations, number of envelopes, and any special requirements. Our team will provide a customised quote."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                },
                                {
                                  "@type": "Question",
                                  "name": "Do you offer express delivery for envelopes?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Yes, we provide express delivery options, including same-day and overnight services, to ensure your envelopes reach their destination promptly."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                },
                                {
                                  "@type": "Question",
                                  "name": "Can you handle confidential or legal documents?",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Absolutely. We prioritise security and confidentiality for sensitive documents, ensuring safe handling and secure delivery at every stage."
                                  },
                                  "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/envelope-freight-services",
                                    "name": "Envelope Freight Services FAQ"
                                  }
                                }
                              ]
                            }
                          ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}

                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Envelope Freight Services</h1>
                        <p>
                            In the realm of high-stakes communication and time-sensitive materials, our envelope freight service stands apart. Meticulously crafted for secure document handling and rapid, confidential delivery, our process ensures that your vital correspondence and lightweight shipments are treated with the utmost care.
                        </p>
                        {!isMobile && (
                            <p>
                                Operating from Melbourne and extending its reach across Australia, our envelope freight services guarantee that your essential items arrive swiftly and securely—whether they’re destined for bustling metropolitan centres or remote regional areas—backed by cutting-edge tracking and dedicated support.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-envelope.jpg"
                            alt="Dependable envelope freight services ensuring secure and rapid delivery across Australia"
                            title="Envelope Freight Services – Secure Logistics by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>

                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Precision envelope freight services tailored for timely and confidential delivery</h5>
                        )}
                        <p>
                            From Melbourne to key logistics hubs nationwide, our envelope freight services prioritise speed, security, and discretion. Whether it’s critical documents or compact parcels, we ensure seamless and reliable delivery that aligns with your business needs. <Link to="/types-of-freight">Browse the types of freight services we specialise in</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Envelope Freight by QFM</h2>
                        <ul>
                            <li><b>Precision Delivery:</b> Tailored services that prioritise the secure handling and expedited transport of important documents and small packages.</li>
                            <li><b>Dependability:</b> Consistently reliable service ensures your sensitive shipments reach their destination on time.</li>
                            <li><b>Enhanced Security:</b> Robust handling and specialised packaging to prevent tampering and damage during transit.</li>
                            <li><b>Nationwide Connectivity:</b> Extensive coverage from Melbourne to all major Australian cities and regional areas, ensuring prompt delivery.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-envelope-two.jpg"
                            loading="lazy"
                            alt="Professional envelope handling and secure logistics across Australia"
                            title="Melbourne Freight Handling – Secure Envelope Delivery by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="envelope-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which areas do you serve for envelope freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our envelope freight services connect Melbourne to all major cities, including Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations across Australia.
                                    <br />
                                    Whether you need secure delivery within the city or across the country, we’ve got you covered.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>What types of envelopes can you handle?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    We handle all standard-sized envelopes, including A4, legal, and custom sizes. Whether it’s confidential documents, invoices, contracts, or personal correspondence, we ensure secure handling and delivery.
                                    <br />
                                    Let us know your specific requirements, and we’ll ensure the right solution for your envelope needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Are envelopes tracked during transit?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, all envelope shipments come with tracking options to monitor their progress. You’ll receive updates at every major milestone, ensuring complete visibility from pickup to delivery.
                                    <br />
                                    For peace of mind, contact us for more details on tracking options.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do I request a quote for envelope freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is simple. Visit our <Link to="/contact-us">Contact Us</Link> page and provide the pickup and delivery locations, the number of envelopes, and any specific needs. Our team will review your details and provide a tailored quote promptly.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you offer express envelope delivery services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer express delivery options for urgent envelope shipments. Whether it’s same-day, overnight, or priority delivery, we’ll ensure your envelopes reach their destination on time.
                                    <br />
                                    Contact us to discuss the best express options for your requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>Can you handle confidential or legal documents?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. We prioritise security and confidentiality when transporting legal or sensitive documents. Our secure handling processes and reliable delivery options ensure your items remain safe and private throughout transit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Envelope;


