import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Warehouse() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Chemical Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised chemical freight services, ensuring the safe and compliant transport of industrial and commercial chemicals across Australia. Our secure logistics solutions prioritise safety, efficiency, and regulatory compliance."
        />
        <meta
          name="keywords"
          content="Chemical Freight Services, Hazardous Freight Transport, Industrial Chemical Logistics, Safe Chemical Handling, Australia Freight, Secure Chemical Shipping, QFM Logistics"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Chemical Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Specialised chemical freight services by QFM ensure the safe and compliant transport of hazardous and industrial chemicals across Australia. Trust QFM for reliable, regulated logistics solutions."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-chemical.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/chemical-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/chemical-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/chemical-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/chemical-freight-services",
                "name": "Chemical Freight Services | Logistics Solutions by QFM",
                "description": "QFM provides specialised chemical freight services, handling hazardous and non-hazardous chemicals with strict safety and compliance measures. Our secure logistics network ensures reliable bulk transport across Australia, meeting regulatory standards for chemical shipments.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/chemical-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/chemical-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Chemical Freight Services",
                    "item": "https://qfmlogistics.com.au/chemical-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/chemical-freight-services#service",
                "name": "Chemical Freight Services",
                "description": "Our chemical freight services are designed to transport hazardous and non-hazardous chemicals securely and in full compliance with industry regulations. We provide tailored logistics solutions for bulk chemical shipments across Australia, ensuring safe handling and timely deliveries.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-chemical.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-chemical-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your chemical freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-chemical.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-chemical.jpg",
                "description": "Specialised transport solutions for chemical freight, ensuring safe and compliant delivery across Australia.",
                "name": "Chemical Freight - Secure Logistics by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/chemical-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-chemical-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-chemical-two.jpg",
                "description": "Reliable freight solutions for bulk chemical transport, including hazardous materials, across metropolitan and regional Australia.",
                "name": "Bulk Chemical Transport - Professional Freight by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/chemical-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/chemical-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of dangerous goods do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle a wide range of dangerous goods, including flammable liquids, corrosive substances, industrial chemicals, and compressed gases. Each shipment is transported according to strict safety regulations to ensure compliance and security. Since different types of chemicals have unique handling and storage needs, contact our team to confirm the specific freight you need to transport."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide bulk chemical transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer bulk transport solutions for chemical shipments across Australia. Whether you need to move large quantities in drums, IBCs, or full tanker loads, we have the capability to handle large-scale and industrial shipments. Our fleet includes semi-trailers and B-Doubles, ensuring efficient transportation for both long-haul and local deliveries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure chemical freight is transported safely?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We adhere to stringent safety protocols to transport chemicals securely. Our trained drivers use UN-certified containers, secure tie-downs, and proper ventilation when required. We also comply with national regulations for hazardous material transport to reduce risk and ensure the safe handling of chemical freight."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer nationwide delivery for chemical freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our services cover major Australian cities including Melbourne, Sydney, Brisbane, Perth, and Adelaide, along with regional and remote areas. Whether you need local deliveries or long-distance transport, we have an extensive logistics network to ensure reliable and timely delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for chemical freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To get a quote, visit our Contact Us page and provide details such as the type of chemical, volume, packaging method (drums, IBCs, bulk), and pickup and delivery locations. Our team will assess your requirements and provide a competitive and compliant transport solution."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What documentation is required for shipping chemicals?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Transporting chemicals requires documents such as Material Safety Data Sheets (MSDS), dangerous goods declarations, and transport permits. Additional paperwork may be required depending on the type of chemical. Our team can guide you through the required documentation to ensure full compliance with transport regulations."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/chemical-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Chemical Freight Services</h1>
            {!isMobile && (
              <p>
                QFM provides specialised chemical freight solutions, ensuring the safe and compliant transport of various dangerous goods (DG), including bulk chemical shipments. Our expertise spans industrial chemicals, hazardous materials, and regulated substances, with strict adherence to safety protocols and regulatory requirements.
              </p>
            )}
            <p>
              Whether you need local distribution or nationwide bulk chemical transport, our secure logistics network covers metropolitan hubs and remote regions across Australia. We work closely with chemical manufacturers, industrial suppliers, and businesses to provide tailored freight solutions, ensuring efficiency, compliance, and reliability in every shipment.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-chemical.jpg"
              alt="Specialised freight solutions for transporting chemicals and dangerous goods safely across Australia"
              title="Chemical Freight Services - Secure and Compliant Transport by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Safe and Compliant Chemical Freight Solutions Across Australia</h5>
            )}
            <p>
              Our specialised logistics ensure the secure transport of chemicals and dangerous goods, meeting strict handling regulations. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive freight solutions.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Chemical Freight by QFM</h2>
            <ul>
              <li>
                <b>Dangerous Goods Compliance:</b> We adhere to strict Australian regulations for transporting hazardous chemicals, ensuring full compliance with safety protocols.
              </li>
              <li>
                <b>Secure Handling & Containment:</b> Specialised freight solutions designed for chemical drums, IBCs, and bulk liquid transport with spill-prevention measures.
              </li>
              <li>
                <b>Bulk & Local Deliveries:</b> Whether moving large-scale industrial chemicals nationwide or local shipments, we provide efficient transport tailored to your needs.
              </li>
              <li>
                <b>End-to-End Tracking:</b> Real-time monitoring of chemical freight, offering visibility and peace of mind throughout transit.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-chemical-two.jpg"
              alt="Reliable transport solutions for chemicals and dangerous goods across Australia"
              title="Chemical Freight Services - Safe and Compliant Logistics by Quan Freight Management"
              loading="lazy"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="pallet-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of dangerous goods do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  We handle a wide range of dangerous goods, including flammable liquids, corrosive substances, industrial chemicals, and compressed gases. Our services are tailored to meet strict regulatory requirements, ensuring safe and compliant transport of hazardous materials. Since different types of chemicals have unique handling and storage needs, it is important to consult with our team to confirm that your specific freight can be moved safely.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you provide bulk chemical transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "280px" : "0px" }}>
                <p>
                  Yes, we specialise in bulk chemical transport across Australia, offering solutions for both local and interstate shipments. Whether you require transport for intermediate bulk containers (IBCs), large drums, or full tanker loads, we have the equipment and expertise to ensure secure and efficient movement of chemicals. Our fleet includes semi-trailers and B-Doubles, allowing us to accommodate both small and large-scale industrial shipments with precision.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
              <h5>How do you ensure chemical freight is transported safely?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "280px" : "0px" }}>
                <p>
                  Chemical freight requires strict safety measures to prevent leaks, contamination, and environmental hazards. Our team follows best practices, including the use of UN-certified containers, secure tie-downs, and proper ventilation where required. All drivers are trained in the handling of hazardous materials, and our vehicles are equipped with safety features to mitigate risks during transit. We also comply with all national transport regulations to ensure full legal compliance and peace of mind for our customers.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you offer nationwide delivery for chemical freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "270px" : "0px" }}>
                <p>
                  Yes, our chemical freight services cover all major Australian cities, including Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote areas. Whether you need local deliveries for manufacturing facilities or bulk shipments for distribution across state lines, we provide flexible and scalable logistics solutions. Our extensive network ensures that even time-sensitive deliveries are made efficiently and reliably.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for chemical freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "250px" : "0px" }}>
                <p>
                  Getting a quote for chemical freight is simple. Visit our <Link to="/contact-us">Contact Us</Link> page and provide details such as the type of chemical, quantity, required packaging (drums, IBCs, bulk), and pickup and delivery locations. Our team will assess your requirements and provide a competitive and customised quote to ensure compliance with safety regulations and efficiency in transport. If you have urgent freight needs, we also offer priority services.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>What documentation is required for shipping chemicals?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "260px" : "0px" }}>
                <p>
                  Transporting chemicals requires specific documentation to ensure safety and compliance. This includes Material Safety Data Sheets (MSDS), dangerous goods declarations, and relevant transport permits. Depending on the type of chemical, additional regulatory documents may be required. Our team can assist you in preparing the necessary paperwork and ensuring that all legal requirements are met before your shipment is dispatched.
                </p>
              </div>
            </div>

          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Warehouse;