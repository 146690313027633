import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Melbourne() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/locations-we-service"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Melbourne Freight Services | Australia-Wide Logistics by QFM</title>
                <meta name="description" content="QFM provides comprehensive freight management solutions from Melbourne, servicing businesses across Australia. Our road, rail, and air freight services ensure reliable and efficient transport for all cargo types." />
                <meta name="keywords" content="Melbourne freight services, Australia-wide logistics, QFM Logistics, road freight, rail freight, air freight, Melbourne transport solutions, freight management, reliable freight services, Melbourne logistics hub" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Melbourne Freight Services | Australia-Wide Logistics by QFM" />
                <meta property="og:description" content="QFM offers expert freight services from Melbourne, delivering goods across Australia via road, rail, and air. We ensure secure and timely transport solutions tailored to your business needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-melbourne-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/melbourne-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/melbourne-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/melbourne-freight-services",
                                "name": "Melbourne Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides premium freight services from Melbourne, delivering goods Australia-wide via road, rail, and air. Our secure and timely transport solutions are designed for businesses of all sizes.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/melbourne-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/stillage-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Locations",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Melbourne Freight Services",
                                        "item": "https://qfmlogistics.com.au/melbourne-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/melbourne-freight-services#service",
                                "name": "Melbourne Freight Services",
                                "description": "Reliable freight solutions from Melbourne, servicing all major Australian cities and regional areas via road, rail, and air freight.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-melbourne-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-freight.jpg",
                                "description": "Reliable Melbourne freight services connecting businesses to all major Australian cities and regional areas.",
                                "name": "Melbourne Freight Services - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-freight-two.jpg",
                                "description": "Expert freight handling solutions from Melbourne, servicing Australia-wide with road, rail, and air transport.",
                                "name": "Melbourne Freight Handling - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Do you service all areas from Melbourne?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, QFM provides freight services from Melbourne to all major cities and regional areas across Australia. Whether you need transport within Victoria or interstate, we have a network that ensures your goods are delivered efficiently."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide local freight services within Melbourne?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer local freight services throughout Melbourne, catering to businesses that require fast and reliable transport within the metropolitan area. Our solutions include same-day and next-day deliveries."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you deliver to regional areas in Victoria?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Absolutely! We provide freight solutions to all regional areas in Victoria, including Geelong, Bendigo, Ballarat, and beyond. No matter where your shipment needs to go, we have the network and expertise to get it there."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of freight can you transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We handle a variety of freight types, including palletised goods, oversized freight, general cargo, and express shipments. Our Melbourne freight services cater to industries such as manufacturing, retail, and e-commerce."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide same-day or express freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer express and same-day freight services for urgent shipments. Whether you need local Melbourne deliveries or time-sensitive freight sent interstate, our fast-tracked services ensure on-time delivery."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a freight quote?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "You can request a quote by visiting our Contact Us page at https://qfmlogistics.com.au/contact-us. Simply provide your shipment details, including size, weight, and destination, and our team will get back to you with a tailored quote."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer tracking for shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we provide tracking options for all shipments, allowing you to monitor your freight in real-time and receive delivery updates at key milestones."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I arrange regular scheduled freight pickups?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer recurring freight pickups for businesses that require consistent logistics support. Contact us to discuss a schedule that fits your operational needs."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Melbourne Freight Services</h1>
                        <p>
                            Operating from Melbourne, QFM serves as a key logistics hub, connecting businesses to every major city and regional area across Australia. Our freight management solutions are designed to ensure efficiency, reliability, and cost-effectiveness, providing seamless transport across road, rail, and air networks. Whether you need time-sensitive deliveries, bulk freight transport, or tailored logistics solutions, our Melbourne-based operations ensure your goods move smoothly across the country with precision and care.
                        </p>
                        {!isMobile && (
                            <p>
                                Whether you need road, rail, or air freight, our extensive network ensures that your goods reach their destination on time. From Melbourne to Sydney, Brisbane, Perth, and beyond, QFM’s expertise in freight management guarantees a smooth and secure transit experience.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-melbourne-freight.jpg"
                            alt="Melbourne Freight Services ensuring reliable transport solutions across Australia"
                            title="Melbourne Freight Services – Reliable and Efficient Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Comprehensive Melbourne Freight Solutions for Every Business</h5>
                        )}
                        <p>
                            QFM delivers efficient freight solutions from Melbourne, connecting businesses across Australia. Whether you need express, bulk, or specialised logistics, our extensive carrier network ensures seamless transport from pickup to destination.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Freight Services by QFM</h2>
                        <ul>
                            <li><b>End-to-End Freight Solutions:</b> Comprehensive transport services covering road, rail, and air freight for all industries.</li>
                            <li><b>Reliable Nationwide Coverage:</b> Seamless logistics from Melbourne to all major Australian cities and regional areas.</li>
                            <li><b>Time-Sensitive & Bulk Transport:</b> Efficient handling of urgent shipments and high-volume freight with optimised delivery schedules.</li>
                            <li><b>Secure & Efficient Handling:</b> Professional freight management ensuring the safe transit of your goods with real-time tracking.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-melbourne-freight-two.jpg"
                            loading="lazy"
                            alt="Expert stillage freight handling for secure transport of framed and caged shipments across Australia"
                            title="Melbourne Freight Handling – Reliable Stillage Freight Services by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/locations-we-service"><h4>View all Locations</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="stillage-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>Do you service all areas from Melbourne?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    Yes, QFM provides freight services from Melbourne to all major cities and regional areas across Australia.
                                    Whether you need transport within Victoria or interstate, we have a network that ensures your goods are delivered efficiently.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>Do you provide local freight services within Melbourne?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                                <p>
                                    Yes, we offer local freight services throughout Melbourne, catering to businesses that require fast and reliable
                                    transport within the metropolitan area. Our solutions include same-day and next-day deliveries.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>Do you deliver to regional areas in Victoria?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                                <p>
                                    Absolutely! We provide freight solutions to all regional areas in Victoria, including Geelong, Bendigo, Ballarat, and beyond.
                                    No matter where your shipment needs to go, we have the network and expertise to get it there.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>What types of freight can you transport?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                <p>
                                    We handle a variety of freight types, including palletised goods, oversized freight, general cargo, and express shipments.
                                    Our Melbourne freight services cater to industries such as manufacturing, retail, and e-commerce.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
                            <h5>How can I request a freight quote?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "300px" : "0px" }}>
                                <p>
                                    You can request a quote by visiting our <Link to="/contact-us">Contact Us</Link> page.
                                    Simply provide your shipment details, including size, weight, and destination, and our team will get back to you with a tailored quote.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div className={`faq-item ${openFAQ === 6 ? "active" : ""}`} onClick={() => toggleFAQ(6)}>
                            <h5>Do you offer tracking for shipments?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 6 ? "300px" : "0px" }}>
                                <p>
                                    Yes, we provide tracking options for all shipments, allowing you to monitor your freight in real-time and receive
                                    delivery updates at key milestones.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 8 */}
                        <div className={`faq-item ${openFAQ === 7 ? "active" : ""}`} onClick={() => toggleFAQ(7)}>
                            <h5>Can I arrange regular scheduled freight pickups?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 7 ? "300px" : "0px" }}>
                                <p>
                                    Yes, we offer recurring freight pickups for businesses that require consistent logistics support. Contact us to
                                    discuss a schedule that fits your operational needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Melbourne;


