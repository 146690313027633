import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/OurServices.css';

function OurServices() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.10 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ourservices-main-content">
      <Helmet>
        <title>Our Services | Logistics Solutions by QFM</title>
        <meta name="description" content="Quan Freight Management offers a full range of logistics services including road, rail, and air freight. Specialising in Melbourne to all major Australian cities, we provide reliable transport solutions for pallets, cartons, and oversized goods. Experience seamless freight delivery with advanced technology and exceptional customer care." />
        <meta name="keywords" content="freight services, road freight, rail freight, air freight, Quan Freight Management, QFM Logistics, Melbourne freight services, Australia-wide logistics, pallet transport, carton freight delivery, oversized goods freight, supply chain solutions, efficient freight management, logistics services, interstate freight delivery, regional freight solutions, reliable logistics provider" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Our Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Explore Quan Freight Management's tailored logistics services. From Melbourne to Brisbane, Sydney, Perth, Adelaide, Darwin, Canberra, and Hobart, we deliver reliable freight solutions using advanced technology for pallets, cartons, and oversized goods." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-our-services.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/our-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/our-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/our-services#webpage",
                "url": "https://qfmlogistics.com.au/our-services",
                "name": "Our Services | Logistics Solutions by QFM",
                "description": "Explore tailored freight solutions by Quan Freight Management. Reliable freight services across Melbourne, Sydney, Brisbane, and more.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/our-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "CreativeWork",
                "@id": "https://qfmlogistics.com.au#license",
                "name": "Terms and Conditions",
                "url": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/our-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Our Services",
                    "item": "https://qfmlogistics.com.au/our-services"
                  }
                ]
              },
              // Service: Road Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#road-freight",
                "name": "Road Freight Logistics",
                "description": "Reliable Road Freight services transporting pallets, cartons, and project freight across Australia, ensuring timely and secure deliveries.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Road Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-road-freight.jpg"
              },
              // Service: Rail Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#rail-freight",
                "name": "Rail Freight Logistics",
                "description": "Efficient Rail Freight services transporting large shipments and specialized cargo across Australia, balancing speed, cost, and sustainability.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Rail Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg"
              },
              // Service: Air Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#air-freight",
                "name": "Air Freight Logistics",
                "description": "Fast and secure Air Freight services transporting urgent and high-value shipments globally, ensuring prompt and reliable deliveries.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Air Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-air-freight.jpg"
              },
              // Service: Local Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#local-freight",
                "name": "Local Freight Logistics",
                "description": "Reliable Local Freight services transporting goods within Melbourne and other major capital cities, ensuring efficient and secure deliveries.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Local Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-local-freight.jpg"
              },
              // Service: General Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#general-freight",
                "name": "General Freight Logistics",
                "description": "Cost-effective General Freight services providing timely transportation solutions across major cities, ensuring reliable and secure deliveries.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "General Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-general-freight.jpg"
              },
              // Service: Express Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#express-freight",
                "name": "Express Freight Logistics",
                "description": "Overnight Express Freight services providing rapid delivery from Melbourne to Sydney and other major cities, ensuring swift and secure shipments.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Express Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-express-freight.jpg"
              },
              // Service: Project Freight
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#project-freight",
                "name": "Project Freight Logistics",
                "description": "Comprehensive Project Freight services managing large-scale and complex logistics projects across Australia, ensuring timely and efficient execution.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Project Freight",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-project-freight.jpg"
              },
              // Service: Dangerous Goods Transport
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/our-services#dangerous-goods",
                "name": "Dangerous Goods Logistics",
                "description": "Safe and compliant Dangerous Goods transportation services, handling hazardous materials with the highest levels of safety and regulatory adherence.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "serviceType": "Dangerous Goods Transport",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": "https://qfmlogistics.com.au/images/qfm-services-dangerous-goods.jpg"
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
        </script>
      </Helmet>
      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && (
          <div className="vertical-line"></div>
        )}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Our Services</h1>
            {!isMobile && (
              <p>
                At Quan Freight Management (QFM), we provide a comprehensive suite of logistics
                and transportation services designed to meet the unique requirements of businesses
                of all sizes.
                From local deliveries to interstate and Australia-wide freight
                solutions, we specialise in offering flexible, efficient, and reliable options to
                ensure your goods reach their destination safely and on schedule.
              </p>
            )}
            <p>
              Our services cover road, rail, and air freight, handling a diverse range of shipment types, including pallets, cartons, oversized goods, and urgent deliveries. Supported by advanced tracking technology and a dedicated logistics team, we ensure full transparency throughout the shipping process, giving you peace of mind at every stage.
            </p>
          </div>
          <div className="ourservices-intro-section-image-container default-image-container-add">
            {!isMobile && (
              <img
                src="images/qfm-our-services.jpg"
                alt="Freight services connecting Sydney to Australia-wide, specialising in pallets, cartons, and project freight logistics"
                title="Sydney Freight Services - Reliable logistics solutions across Australia"
                loading="lazy"
              />
            )}
            <div className="overlay-grid-services">
              <Link className="grid-button-services" to="/road-freight-services">
                <h4>Road Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/rail-freight-services">
                <h4>Rail Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/air-freight-services">
                <h4>Air Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/local-freight-services">
                <h4>Local Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/project-freight-services">
                <h4>Project Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/dangerous-goods-freight-services">
                <h4>Dangerous Goods</h4>
              </Link>
              <Link className="grid-button-services" to="/general-freight-services">
                <h4>General Freight</h4>
              </Link>
              <Link className="grid-button-services" to="/express-freight-services">
                <h4>Express Freight</h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="technology-section-3 appearing-effect">
          <div className="technology-section-3-text-content add-a-little">
            {!isMobile && (
              <p>
                At QFM, we offer a comprehensive range of freight solutions tailored to meet the unique needs of every shipment. Whether it’s road, rail, or air freight, our services are designed to handle everything from small parcels to oversized goods and specialised cargo with precision and care. Explore the freight services below to see how our expertise and dedication ensure seamless transport solutions for your business.
              </p>
            )}
            <ul>
              <li>
                <b>Road Freight:</b> Our <Link to="/road-freight-services">Road Freight Services</Link> provide dependable and adaptable transportation solutions across Melbourne and Australia, catering to small parcels, pallets, and oversized shipments with care and efficiency.
              </li>
              <li>
                <b>Rail Freight:</b> With an extensive network, our <Link to="/rail-freight-services">Rail Freight Services</Link> offer eco-friendly and cost-effective solutions for large shipments, connecting Melbourne to key logistics hubs nationwide.
              </li>
              <li>
                <b>Air Freight:</b> For time-critical deliveries, our <Link to="/air-freight-services">Air Freight Services</Link> ensure fast and secure transport of high-value and urgent shipments across Australia.
              </li>
            </ul>
            {isMobile && (
              <div className="default-section-content-left appearing-effect item-types-image">
                <div className="default-section-content-left-image-container">
                  <img
                    src="images/qfm-services-one.jpg"
                    loading="lazy"
                    alt="Expert freight solutions delivering reliability and efficiency across Australia"
                    title="Tailored Australia-Wide Freight Services – Experience Logistics Excellence with QFM"
                  />
                </div>
              </div>
            )}
            <ul className='appearing-effect'>
              <li>
                <b>Local Freight:</b> Our <Link to="/local-freight-services">Local Freight Services</Link> cater exclusively to Melbourne-based businesses, offering same-day couriers, taxi trucks, and permanent hire options to ensure fast and reliable delivery within the local area.
              </li>
              <li>
                <b>Project Freight:</b> Our <Link to="/project-freight-services">Project Freight Services</Link> provide tailored solutions for complex logistics challenges, including oversized and heavy loads, ensuring safe and efficient delivery for large-scale projects across Australia.
              </li>
              <li>
                <b>Dangerous Goods Freight:</b> We offer secure and compliant <Link to="/dangerous-goods-freight-services">Dangerous Goods Freight Services</Link>, specialising in the safe transport of hazardous materials such as flammable and corrosive goods, adhering to strict Australian regulations.
              </li>
            </ul>
            {isMobile && (
              <div className="default-section-content-left appearing-effect item-types-image">
                <div className="default-section-content-left-image-container" style={{ width: 'calc(100vw + 20px) !important' }}>
                  <img
                    src="images/qfm-services-two.jpg"
                    loading="lazy"
                    alt="Comprehensive freight solutions ensuring seamless transport across Australia"
                    title="Efficient and Reliable Pallet Freight Services – Serving Australia with QFM"
                  />

                </div>
              </div>
            )}
            <ul className='appearing-effect'>
              <li>
                <b>General Freight:</b> Our <Link to="/general-freight-services">General Freight Services</Link> provide dependable and cost-effective solutions for transporting a wide variety of goods, including parcels, pallets, and oversized items, ensuring safe and timely deliveries across Australia.
              </li>
              <li>
                <b>Express Freight:</b> With our <Link to="/express-freight-services">Express Freight Services</Link>, we prioritise speed and reliability, offering rapid transport solutions through both road and air freight for time-sensitive shipments nationwide.
              </li>
            </ul>
            <p>
              Our priority for every freight service is to deliver exceptional reliability and efficiency, meeting your logistics needs with precision and dedicated care.
            </p>
          </div>
        </div>
        {isVisible && !isMobile && (
          <div className="scroll-up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

export default OurServices;