import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import '../css/Header.css';
import PickupInput from "./PickupSearch.js";
import DestinationInput from "./DeliverySearch.js";
import emailjs from 'emailjs-com';
import CustomAlert from './CustomAlert';

function Header({ isScrolledInitially = false }) {
  const [formStep, setFormStep] = useState(1);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScrolledMobile, setIsScrolledMobile] = useState(
    isMobile && isScrolledInitially
  );

  // Alert state hooks
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    if (isMobile && isScrolledInitially) {
      setIsScrolledMobile(true);
    }
  }, [isMobile, isScrolledInitially]);

  useEffect(() => {
    if (isMobile) return;

    const handleDesktopScroll = () => {
      if (window.scrollY > 300 || isScrolledInitially) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleDesktopScroll(); // run on mount
    window.addEventListener('scroll', handleDesktopScroll);
    return () => window.removeEventListener('scroll', handleDesktopScroll);
  }, [isMobile, isScrolledInitially]);

  useEffect(() => {
    if (!isMobile || isScrolledInitially) return;

    const handleMobileScroll = () => {
      setIsScrolledMobile(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleMobileScroll);
    return () => window.removeEventListener('scroll', handleMobileScroll);
  }, [isMobile, isScrolledInitially]);

  const headerClass = isMobile
    ? isScrolledMobile
      ? 'scrolledMobile'
      : ''
    : isScrolled
      ? 'scrolled'
      : '';

  const services = [
    { key: 'audio', path: '/audio-freight-services', label: 'Audio' },
    { key: 'automotive', path: '/automotive-freight-services', label: 'Automotive' },
    { key: 'books', path: '/books-freight-services', label: 'Books' },
    { key: 'bricks', path: '/bricks-freight-services', label: 'Bricks' },
    { key: 'camping', path: '/camping-freight-services', label: 'Camping' },
    { key: 'caravan', path: '/caravan-freight-services', label: 'Caravan' },
    { key: 'cardboard', path: '/cardboard-freight-services', label: 'Cardboard' },
    { key: 'cement', path: '/cement-freight-services', label: 'Cement' },
    { key: 'chemicals', path: '/chemical-freight-services', label: 'Chemicals' },
    { key: 'cleaning', path: '/cleaning-freight-services', label: 'Cleaning' },
    { key: 'construction', path: '/construction-freight-services', label: 'Construction' },
    { key: 'electronics', path: '/electronics-freight-services', label: 'Electronics' },
    { key: 'energy', path: '/energy-freight-services', label: 'Energy' },
    { key: 'firearms', path: '/firearms-freight-services', label: 'Firearms' },
    { key: 'fitness', path: '/fitness-freight-services', label: 'Fitness' },
    { key: 'furniture', path: '/furniture-freight-services', label: 'Furniture' },
    { key: 'garden', path: '/garden-freight-services', label: 'Garden' },
    { key: 'industrial', path: '/industrial-freight-services', label: 'Industrial' },
    { key: 'machinery', path: '/machinery-freight-services', label: 'Machinery' },
    { key: 'medical', path: '/medical-freight-services', label: 'Medical' },
    { key: 'metal', path: '/metal-freight-services', label: 'Metal' },
    { key: 'office', path: '/office-freight-services', label: 'Office' },
    { key: 'packaging', path: '/packaging-freight-services', label: 'Packaging' },
    { key: 'party', path: '/party-freight-services', label: 'Party' },
    { key: 'pet', path: '/pet-freight-services', label: 'Pet' },
    { key: 'retail', path: '/retail-freight-services', label: 'Retail' },
    { key: 'signage', path: '/signage-freight-services', label: 'Signage' },
    { key: 'storage', path: '/storage-freight-services', label: 'Storage' },
    { key: 'textiles', path: '/textiles-freight-services', label: 'Textiles' },
    { key: 'timber', path: '/timber-freight-services', label: 'Timber' },
  ];

  const locations = [
    { key: 'melbourne', path: '/melbourne-freight-services', label: 'Melbourne' },
    { key: 'sydney', path: '/melbourne-to-sydney-freight', label: 'Sydney' },
    { key: 'brisbane', path: '/melbourne-to-brisbane-freight', label: 'Brisbane' },
    { key: 'perth', path: '/melbourne-to-perth-freight', label: 'Perth' },
    { key: 'adelaide', path: '/melbourne-to-adelaide-freight', label: 'Adelaide' },
    { key: 'hobart', path: '/melbourne-to-hobart-freight', label: 'Hobart' },
  ];

  const [items, setItems] = useState([1]);
  const [nextItemId, setNextItemId] = useState(2);
  const addItem = () => {
    if (items.length < 5) {
      setItems([...items, nextItemId]);
      setNextItemId(nextItemId + 1);
    }
  };

  const deleteItem = (id) => {
    if (items.length > 1) {
      setItems(items.filter((itemId) => itemId !== id));
    }
  };

  const [formData, setFormData] = useState({
    pickup: "",
    destination: "",
    pickupResidential: false,
    destResidential: false,
    items: {},
    name: "",
    email: "",
    phone: "",
    company: "",
    comments: ""
  });

  const [itemTypes, setItemTypes] = useState({ 1: "Pallet" });
  const disableDimensionsTypes = ["A-Trailer", "B-Trailer", "Semi-Truck", "B-Double", "B-Double Mez Deck", "20' Container", "40' Container"];

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button immediately
    console.log("Submit button clicked");
    const newErrors = {};
    if (!formData.name.trim()) newErrors["contactquote-name"] = true;
    if (!formData.email.trim()) {
      newErrors["contactquote-email"] = true;
    } else if (!validateEmail(formData.email)) {
      newErrors["contactquote-email"] = true;
    }
    if (!formData.phone.trim()) newErrors["contactquote-phone"] = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false); // Re-enable if validation fails
      return;
    }

    const itemsDetails = Object.entries(formData.items)
      .map(([key, item]) => {
        const type = itemTypes[key] || 'Pallet';
        return `Item ${key}:\nType: ${type}\nQuantity: ${item.quantity || ''}\nDangerous Goods: ${item.dg || 'no'}\nWeight: ${item.weight || ''}kg\nLength: ${item.length || ''}cm\nWidth: ${item.width || ''}cm\nHeight: ${item.height || ''}cm`;
      })
      .join('\n\n');

    const templateParams = {
      pickup: formData.pickup,
      destination: formData.destination,
      pickupResidential: formData.pickupResidential ? 'Yes' : 'No',
      destResidential: formData.destResidential ? 'Yes' : 'No',
      items: itemsDetails,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      comments: formData.comments,
    };

    
    // emailjs.send('service_ck7pml9', 'template_cy4jff2', templateParams, '5aleywHLj9YTnXLCy')
    emailjs.send('service_eqjos09', 'template_1bmxjm7', templateParams, '6hHglwaeH0hQQmjcJ')
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setAlertTitle('Success');
        setAlertMessage('Thank you for submitting your quote request. Your details have been successfully sent, and a member of the QFM team will be in touch with you shortly.');
        setAlertType('success');
        setAlertVisible(true);
        setFormData({
          pickup: "",
          destination: "",
          pickupResidential: false,
          destResidential: false,
          items: {},
          name: "",
          email: "",
          phone: "",
          company: "",
          comments: ""
        });
        setFormStep(1);
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setAlertTitle('Error');
        setAlertMessage('We’re sorry, but we encountered an error while processing your request. Please try again later or contact us at contact@qfmlogistics.com.au for assistance.');
        setAlertType('error');
        setAlertVisible(true);
      })
      .finally(() => {
        // Re-enable the button after 2 seconds
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      });
  };


  const handleGetQuote = () => {
    const newErrors = {};

    // Validate Pickup & Destination
    const pickupValue = document.getElementById("homequote-pickup")?.value;
    const destinationValue = document.getElementById("homequote-destination")?.value;
    if (!pickupValue) newErrors["homequote-pickup"] = true;
    if (!destinationValue) newErrors["homequote-destination"] = true;

    // Validate each item's required fields
    for (let item of items) {
      const quantity = document.getElementById(`homequote-quantity-${item}`)?.value;
      const weight = document.getElementById(`homequote-weight-${item}`)?.value;
      if (!quantity) newErrors[`homequote-quantity-${item}`] = true;
      if (!weight) newErrors[`homequote-weight-${item}`] = true;

      // Only validate dimensions if not disabled
      if (!disableDimensionsTypes.includes(itemTypes[item])) {
        const length = document.getElementById(`homequote-length-${item}`)?.value;
        const width = document.getElementById(`homequote-width-${item}`)?.value;
        const height = document.getElementById(`homequote-height-${item}`)?.value;
        if (!length) newErrors[`homequote-length-${item}`] = true;
        if (!width) newErrors[`homequote-width-${item}`] = true;
        if (!height) newErrors[`homequote-height-${item}`] = true;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
      setFormStep(2);
    }
  };

  return (
    <header className={`App-header ${headerClass}`}>
      {/* Render Custom Alert if visible */}
      {alertVisible && (
        <CustomAlert
          title={alertTitle}
          message={alertMessage}
          type={alertType}
          onClose={() => setAlertVisible(false)}
        />
      )}
      <div className="App-header-reposition">
        <div className="header-section-1">
          <Link to="/" className="logo-link">
            {isScrolled ? (
              <img
                src="/images/qfm-logo.png"
                alt="Quan Freight Management Logo"
                className="qfm-logo-scrolled"
                loading="lazy"
              />
            ) : (
              <img
                src="/images/qfm-logo-home.png"
                alt="Quan Freight Management Logo"
                className="qfm-logo-main"
                loading="lazy"
              />
            )}
          </Link>
        </div>
        <div className="header-section-2">
          <h2>QUAN FREIGHT MANAGEMENT</h2>
          <input
            type="checkbox"
            id="mobile-menu-toggle"
            className="mobile-menu-toggle"
          />
          <label htmlFor="mobile-menu-toggle" className="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </label>
          <nav>
            <ul className="header-nav-links">
              {(isScrolled || isMobile) && (
                <li key="home">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Home
                    <span className="border-top-left"></span>
                    <span className="border-top-right"></span>
                    <span className="border-right"></span>
                    <span className="border-bottom"></span>
                    <span className="border-left"></span>
                    <span className="background-fill"></span>
                  </NavLink>
                </li>
              )}

              <li className="dropdown" key="locations">
                <NavLink
                  to="/locations-we-service"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Locations
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu extend-width-two">
                  {locations.map((service) => (
                    <li key={service.key}>
                      <NavLink to={service.path}>{service.label}</NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="dropdown" key="services">
                <NavLink
                  to="/our-services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Services
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu dropdown-services">
                  <li key="road">
                    <NavLink to="/road-freight-services">Road Freight</NavLink>
                  </li>
                  <li key="rail">
                    <NavLink to="/Rail-freight-services">Rail Freight</NavLink>
                  </li>
                  <li key="air">
                    <NavLink to="/air-freight-services">Air Freight</NavLink>
                  </li>
                  <li key="local">
                    <NavLink to="/local-freight-services">Local Freight</NavLink>
                  </li>
                  <li key="project">
                    <NavLink to="/project-freight-services">Project Freight</NavLink>
                  </li>
                  <li key="dg">
                    <NavLink to="/dangerous-goods-freight-services">DG Freight</NavLink>
                  </li>
                  <li key="general">
                    <NavLink to="/general-freight-services">General Freight</NavLink>
                  </li>
                  <li key="express">
                    <NavLink to="/express-freight-services">Express Freight</NavLink>
                  </li>
                </ul>
              </li>

              <li className="dropdown" key="commodity">
                <NavLink
                  to="/goods-and-products"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Commodity
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu">
                  {services.map((service) => (
                    <li key={service.key}>
                      <NavLink to={service.path}>{service.label}</NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="dropdown" key="itemtypes">
                <NavLink
                  to="/types-of-freight"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Item Types
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu">
                  <li key="pallet">
                    <NavLink to="/pallet-freight-services">Pallet</NavLink>
                  </li>
                  <li key="carton">
                    <NavLink to="/carton-freight-services">Carton</NavLink>
                  </li>
                  <li key="crate">
                    <NavLink to="/crate-freight-services">Crate</NavLink>
                  </li>
                  <li key="satchel">
                    <NavLink to="/satchel-freight-services">Satchel</NavLink>
                  </li>
                  <li key="envelope">
                    <NavLink to="/envelope-freight-services">Envelope</NavLink>
                  </li>
                  <li key="drum">
                    <NavLink to="/drum-freight-services">Drum</NavLink>
                  </li>
                  <li key="ibc">
                    <NavLink to="/ibc-freight-services">IBC</NavLink>
                  </li>
                  <li key="panels">
                    <NavLink to="/panel-freight-services">Panel</NavLink>
                  </li>
                  <li key="stillage">
                    <NavLink to="/stillage-freight-services">Stillage</NavLink>
                  </li>
                  <li key="Semi">
                    <NavLink to="/semi-freight-services">Semi</NavLink>
                  </li>
                  <li key="B-Double">
                    <NavLink to="/bdouble-freight-services">B-Double</NavLink>
                  </li>
                  <li key="container">
                    <NavLink to="/container-freight-services">Container</NavLink>
                  </li>
                </ul>
              </li>

              <li className="dropdown" key="resources">
                <NavLink
                  to="/resources"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Resources
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu extend-width">
                  <li key="technology">
                    <NavLink to="/freight-management-technology">Technology</NavLink>
                  </li>
                  <li key="integration">
                    <NavLink to="/integrated-freight-solutions">Store Integration</NavLink>
                  </li>
                  <li key="cubic">
                    <NavLink to="/calculate-cubic-weight">Cubic Weight</NavLink>
                  </li>
                  <li key="freight-guide">
                    <NavLink to="/freight-guide">Freight Guide</NavLink>
                  </li>
                  <li key="zone-guide">
                    <NavLink to="/zone-guide">Zone Guide</NavLink>
                  </li>
                  <li key="our-story">
                    <NavLink to="/our-story">Our Story</NavLink>
                  </li>
                </ul>
              </li>

              <li key="tracking">
                <NavLink
                  to="/track-shipment"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Tracking
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li key="contact-us">
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact Us
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li
                key="customer-portal"
                style={{
                  ...(isScrolled || window.innerWidth <= 768
                    ? {}
                    : { position: "absolute", right: "0", top: "36px" }
                  ),
                }}
              >
                <a
                  href="https://qfmlogistics.machship.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    ...(isScrolled || window.innerWidth <= 768
                      ? {}
                      : { padding: "5px 10px", backgroundColor: "rgba(255, 255, 255, 0.4)", color: "rgb(0, 0, 128)" }
                    ),
                  }}
                >
                  Customer Portal
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </a>
              </li>
            </ul>
          </nav>
          {isScrolled && (
            <div className="header-social-icons">
              <a
                href="https://www.linkedin.com/company/qfm-logistics"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-icon"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          )}
          {!isMobile && (
            <div className="home-content-wrapper">
              <h6 className="homequote-heading">
                {formStep === 1 ? "Freight Quote" : "Final Details"}
              </h6>
              <div className="home-content-quote">
                <form className="homequote-form" onSubmit={handleSubmit}>
                  {formStep === 1 && (
                    <>
                      <table className="homequote-table">
                        <tbody>
                          <tr>
                            <td>
                              <label htmlFor="homequote-pickup">Pickup</label>
                            </td>
                            <td>
                              <label htmlFor="homequote-destination">Destination</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <PickupInput
                                id="homequote-pickup"
                                value={formData.pickup}
                                onChange={(e) => setFormData({ ...formData, pickup: e.target.value })}
                                style={errors['homequote-pickup'] ? { border: "1px solid red" } : {}}
                                autoComplete="off"
                                onFocus={() => {
                                  if (errors['homequote-pickup']) {
                                    const newErr = { ...errors };
                                    delete newErr['homequote-pickup'];
                                    setErrors(newErr);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <DestinationInput
                                id="homequote-destination"
                                value={formData.destination}
                                onChange={(e) => setFormData({ ...formData, destination: e.target.value })}
                                style={errors['homequote-destination'] ? { border: "1px solid red" } : {}}
                                autoComplete="off"
                                onFocus={() => {
                                  if (errors['homequote-destination']) {
                                    const newErr = { ...errors };
                                    delete newErr['homequote-destination'];
                                    setErrors(newErr);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="homequote-switch">
                                <input
                                  type="checkbox"
                                  className="homequote-checkbox homequote-pickup-residential"
                                  checked={formData.pickupResidential}
                                  onChange={(e) => setFormData({ ...formData, pickupResidential: e.target.checked })}
                                />
                                <span className="homequote-slider"></span>
                                <span className="homequote-switch-label">Residential Pickup</span>
                              </label>
                            </td>
                            <td>
                              <label className="homequote-switch">
                                <input
                                  type="checkbox"
                                  className="homequote-checkbox homequote-dest-residential"
                                  checked={formData.destResidential}
                                  onChange={(e) => setFormData({ ...formData, destResidential: e.target.checked })}
                                />
                                <span className="homequote-slider"></span>
                                <span className="homequote-switch-label">Residential Delivery</span>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>


                      {/* Render each item form */}
                      {items.map((item, index) => (
                        <div key={item}>
                          <hr className="homequote-divider"></hr>
                          <table className="homequote-table homequote-item-table">
                            <tbody>
                              {/* Row 1: Labels */}
                              <tr>
                                <td colSpan={2}>
                                  <label htmlFor={`homequote-item-type-${item}`}>Item {index + 1}</label>
                                </td>
                                <td>
                                  <label htmlFor={`homequote-quantity-${item}`}>Quantity</label>
                                </td>
                                <td>
                                  <label htmlFor={`homequote-dg-${item}`}>DG</label>
                                </td>
                              </tr>
                              {/* Row 2: Inputs */}
                              <tr>
                                <td colSpan={2}>
                                  <select
                                    id={`homequote-item-type-${item}`}
                                    name={`homequote-item-type-${item}`}
                                    className="homequote-input"
                                    value={itemTypes[item] || "Pallet"}
                                    onChange={(e) => {
                                      const newItemType = e.target.value;
                                      setItemTypes({ ...itemTypes, [item]: newItemType });
                                      // Clear errors for this item when a new option is selected
                                      const newErrors = { ...errors };
                                      delete newErrors[`homequote-quantity-${item}`];
                                      delete newErrors[`homequote-weight-${item}`];
                                      delete newErrors[`homequote-length-${item}`];
                                      delete newErrors[`homequote-width-${item}`];
                                      delete newErrors[`homequote-height-${item}`];
                                      setErrors(newErrors);
                                      // If the selected type disables dimensions, clear those inputs
                                      if (disableDimensionsTypes.includes(newItemType)) {
                                        const lengthInput = document.getElementById(`homequote-length-${item}`);
                                        const widthInput = document.getElementById(`homequote-width-${item}`);
                                        const heightInput = document.getElementById(`homequote-height-${item}`);
                                        if (lengthInput) lengthInput.value = "";
                                        if (widthInput) widthInput.value = "";
                                        if (heightInput) heightInput.value = "";
                                      }
                                    }}
                                  >
                                    <option value="Carton">Carton</option>
                                    <option value="Pallet">Pallet</option>
                                    <option disabled>────────────</option>
                                    <option value="Envelope">Envelope</option>
                                    <option value="Satchel">Satchel</option>
                                    <option value="Bag">Bag</option>
                                    <option value="Tube">Tube</option>
                                    <option value="Bundle">Bundle</option>
                                    <option value="Drum">Drum</option>
                                    <option value="Skid">Skid</option>
                                    <option value="Panel">Panel</option>
                                    <option value="Crate">Crate</option>
                                    <option value="Ibc">IBC</option>
                                    <option value="Stillage">Stillage</option>
                                    <option value="A-Trailer">A-Trailer</option>
                                    <option value="Semi-Truck">Semi-Truck</option>
                                    <option value="B-Double">B-Double</option>
                                    <option value="B-Double Mez Deck">B-Double Mez Deck</option>
                                    <option value="20' Container">20' Container</option>
                                    <option value="40' Container">40' Container</option>
                                    <option value="other">Other/Misc</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id={`homequote-quantity-${item}`}
                                    name={`homequote-quantity-${item}`}
                                    className="homequote-input"
                                    autoComplete="off"
                                    placeholder="Eg: 1"
                                    value={(formData.items[item] && formData.items[item].quantity) || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            quantity: e.target.value
                                          }
                                        }
                                      })
                                    }
                                    style={errors[`homequote-quantity-${item}`] ? { border: "1px solid red" } : {}}
                                    onFocus={() => {
                                      if (errors[`homequote-quantity-${item}`]) {
                                        const newErr = { ...errors };
                                        delete newErr[`homequote-quantity-${item}`];
                                        setErrors(newErr);
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <select
                                    id={`homequote-dg-${item}`}
                                    name={`homequote-dg-${item}`}
                                    className="homequote-input"
                                    autoComplete="off"
                                    value={(formData.items[item] && formData.items[item].dg) || "no"}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            dg: e.target.value
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </td>
                              </tr>
                              {/* Row 3: Dimensions */}
                              {/* Row 3: Dimensions */}
                              <tr>                                
                                <td>
                                  <label htmlFor={`homequote-length-${item}`}>Length (cm)</label>
                                </td>
                                <td>
                                  <label htmlFor={`homequote-width-${item}`}>Width (cm)</label>
                                </td>
                                <td>
                                  <label htmlFor={`homequote-height-${item}`}>Height (cm)</label>
                                </td>
                                <td>
                                  <label htmlFor={`homequote-weight-${item}`}>Weight (kg)</label>
                                </td>
                              </tr>
                              <tr>
                                
                                <td>
                                  <input
                                    type="number"
                                    id={`homequote-length-${item}`}
                                    name={`homequote-length-${item}`}
                                    className="homequote-input"
                                    autoComplete="off"
                                    placeholder="In cm"
                                    value={(formData.items[item] && formData.items[item].length) || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            length: e.target.value
                                          }
                                        }
                                      })
                                    }
                                    disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                    required={!disableDimensionsTypes.includes(itemTypes[item])}
                                    style={errors[`homequote-length-${item}`] ? { border: "1px solid red" } : {}}
                                    onFocus={() => {
                                      if (errors[`homequote-length-${item}`]) {
                                        const newErr = { ...errors };
                                        delete newErr[`homequote-length-${item}`];
                                        setErrors(newErr);
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id={`homequote-width-${item}`}
                                    name={`homequote-width-${item}`}
                                    className="homequote-input"
                                    placeholder="In cm"
                                    value={(formData.items[item] && formData.items[item].width) || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            width: e.target.value,
                                          },
                                        },
                                      })
                                    }
                                    disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                    required={!disableDimensionsTypes.includes(itemTypes[item])}
                                    style={errors[`homequote-width-${item}`] ? { border: "1px solid red" } : {}}
                                    onFocus={() => {
                                      if (errors[`homequote-width-${item}`]) {
                                        const newErr = { ...errors };
                                        delete newErr[`homequote-width-${item}`];
                                        setErrors(newErr);
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id={`homequote-height-${item}`}
                                    name={`homequote-height-${item}`}
                                    className="homequote-input"
                                    placeholder="In cm"
                                    value={(formData.items[item] && formData.items[item].height) || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            height: e.target.value,
                                          },
                                        },
                                      })
                                    }
                                    disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                    required={!disableDimensionsTypes.includes(itemTypes[item])}
                                    style={errors[`homequote-height-${item}`] ? { border: "1px solid red" } : {}}
                                    onFocus={() => {
                                      if (errors[`homequote-height-${item}`]) {
                                        const newErr = { ...errors };
                                        delete newErr[`homequote-height-${item}`];
                                        setErrors(newErr);
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id={`homequote-weight-${item}`}
                                    name={`homequote-weight-${item}`}
                                    className="homequote-input"
                                    autoComplete="off"
                                    placeholder="In kg"
                                    value={(formData.items[item] && formData.items[item].weight) || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        items: {
                                          ...formData.items,
                                          [item]: {
                                            ...formData.items[item],
                                            weight: e.target.value
                                          }
                                        }
                                      })
                                    }
                                    required
                                    style={errors[`homequote-weight-${item}`] ? { border: "1px solid red" } : {}}
                                    onFocus={() => {
                                      if (errors[`homequote-weight-${item}`]) {
                                        const newErr = { ...errors };
                                        delete newErr[`homequote-weight-${item}`];
                                        setErrors(newErr);
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* Delete button appears if more than one item */}
                          {items.length > 1 && (
                            <div>
                              <button type="button" onClick={() => deleteItem(item)} className="delete-button">
                                <span className="delete-icon"><i className="fas fa-trash"></i></span>
                                <span className="delete-text">Delete Item</span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <hr className="homequote-divider" />
                      <table className="homequote-table homequote-button-table">
                        <tbody>
                          <tr>
                            <td>
                              {items.length < 5 && (
                                <button
                                  type="button"
                                  className="homequote-btn homequote-btn-add"
                                  onClick={addItem}
                                >
                                  + Add Item
                                </button>
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <button
                                type="button"
                                className="homequote-btn homequote-btn-quote"
                                onClick={handleGetQuote}
                              >
                                Get Quote
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}

                  {formStep === 2 && (
                    <>
                      <table className="homequote-table">
                        <tbody>
                          <tr>
                            <td>
                              <label htmlFor="homequote-name">
                                Name <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                              </label>
                            </td>
                            <td>
                              <label htmlFor="homequote-email">
                                Email <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                id="homequote-name"
                                name="homequote-name"
                                className="homequote-input"
                                placeholder="Your name"
                                value={formData.name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData({ ...formData, name: value });
                                  if (value.trim() !== "" && errors["homequote-name"]) {
                                    const newErr = { ...errors };
                                    delete newErr["homequote-name"];
                                    setErrors(newErr);
                                  }
                                }}
                                style={errors["homequote-name"] ? { border: "1px solid red" } : {}}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="homequote-email"
                                name="homequote-email"
                                className="homequote-input"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData({ ...formData, email: value });
                                  if (value.trim() !== "" && errors["homequote-email"]) {
                                    const newErr = { ...errors };
                                    delete newErr["homequote-email"];
                                    setErrors(newErr);
                                  }
                                }}
                                style={errors["homequote-email"] ? { border: "1px solid red" } : {}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="homequote-phone">
                                Phone <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                              </label>
                            </td>
                            <td>
                              <label htmlFor="homequote-company">Company</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                id="homequote-phone"
                                name="homequote-phone"
                                className="homequote-input"
                                placeholder="Your Phone"
                                value={formData.phone}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData({ ...formData, phone: value });
                                  if (value.trim() !== "" && errors["homequote-phone"]) {
                                    const newErr = { ...errors };
                                    delete newErr["homequote-phone"];
                                    setErrors(newErr);
                                  }
                                }}
                                style={errors["homequote-phone"] ? { border: "1px solid red" } : {}}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="homequote-company"
                                name="homequote-company"
                                className="homequote-input"
                                placeholder="Your Company"
                                value={formData.company}
                                onChange={(e) =>
                                  setFormData({ ...formData, company: e.target.value })
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <label htmlFor="homequote-comments">Comments</label>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <textarea
                                id="homequote-comments"
                                name="homequote-comments"
                                className="homequote-input"
                                placeholder="Your Comments"
                                rows="4"
                                maxLength={280}
                                value={formData.comments}
                                onChange={(e) =>
                                  setFormData({ ...formData, comments: e.target.value })
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="homequote-table homequote-button-table">
                        <tbody>
                          <tr>
                            <td>
                              <button
                                type="button"
                                className="homequote-btn go-back-button"
                                onClick={() => setFormStep(1)}
                              >
                                Go Back
                              </button>
                            </td>
                            <td style={{ textAlign: "right" }}>
                            <button
                                                                type="submit"
                                                                className="homequote-btn homequote-btn-quote submit-btn-quote"
                                                                disabled={isSubmitting}
                                                                style={isSubmitting ? { backgroundColor: 'green', cursor: 'not-allowed' } : {}}
                                                            >
                                                                Submit Quote
                                                            </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;