import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Perth() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/locations-we-service"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Melbourne to Adelaide Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides secure and efficient freight services from Melbourne to Adelaide, offering road, rail, and air transport solutions for all industries." />
                <meta name="keywords" content="Melbourne to Adelaide freight, interstate freight transport, QFM Logistics, road freight, rail freight, air freight, Adelaide logistics, express freight, bulk transport, freight carriers" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-SA" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Melbourne to Adelaide Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="QFM provides expert freight solutions from Melbourne to Adelaide, ensuring secure and efficient transport for businesses with flexible delivery options." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/melbourne-to-adelaide-freight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/melbourne-to-adelaide-freight" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                           {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#webpage",
                                "url": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight",
                                "name": "Melbourne to Adelaide Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides premium freight services from Melbourne to Adelaide, ensuring secure and timely deliveries via road, rail, and air transport.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Locations",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Melbourne to Adelaide Freight Services",
                                        "item": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight"
                                    }
                                ]
                            },
                           {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#service",
                                "name": "Melbourne to Adelaide Freight Services",
                                "description": "Reliable freight solutions from Melbourne to Adelaide, servicing businesses with secure and efficient transport via road, rail, and air freight.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide.jpg",
                                "description": "Reliable Melbourne to Adelaide freight services by QFM, ensuring secure and timely interstate deliveries.",
                                "name": "Melbourne to Adelaide Freight - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-adelaide-two.jpg",
                                "description": "Melbourne to Adelaide freight solutions tailored for businesses across various industries, offering reliable transport options.",
                                "name": "Melbourne to Adelaide Freight Solutions - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#faq",
                                "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight",
                                    "url": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight",
                                    "name": "Melbourne to Adelaide Freight Services | Logistics Solutions by QFM"
                                },
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What freight services does QFM offer from Melbourne to Adelaide?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM provides tailored freight transport solutions between Melbourne and Adelaide, including road, rail, and air freight options. Whether you need express delivery, bulk transport, or cost-effective shipping, we ensure seamless logistics for businesses of all sizes."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How long does freight take from Melbourne to Adelaide?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Standard road freight transit times from Melbourne to Adelaide typically range from 1 to 2 business days, depending on shipment size and urgency. For urgent deliveries, we offer express freight options, including next-day and same-day services where available."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide freight services to regional South Australia?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, QFM extends its Melbourne to Adelaide freight services to regional South Australia. We deliver to locations such as Mount Gambier, Whyalla, Port Augusta, and more, ensuring reliable transport solutions for businesses in both metro and remote areas."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of freight can QFM transport to Adelaide?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We transport a wide range of goods, including palletised freight, oversized loads, retail stock, and industrial equipment. Our logistics network ensures safe and secure deliveries tailored to industries such as construction, agriculture, and e-commerce."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a freight quote for Melbourne to Adelaide transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "You can request a freight quote by visiting our Contact Us page at https://qfmlogistics.com.au/contact-us. Provide details such as shipment size, weight, and delivery requirements, and our team will provide a tailored and competitive quote for your Melbourne to Adelaide freight needs."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Melbourne to Adelaide Freight Services</h1>
                        {!isMobile && (
                            <p>
                                QFM provides fast and cost-effective freight transport solutions from Melbourne to Adelaide, ensuring timely deliveries across South Australia.
                                Whether your business requires road, rail, or air freight, we offer a range of flexible and secure transport options.
                            </p>
                        )}
                        <p>
                            Our network covers key Adelaide metro areas and extends to regional South Australia, ensuring seamless freight movement for businesses in manufacturing, retail, and logistics.
                            With real-time tracking and tailored logistics solutions, we simplify the freight process for businesses of all sizes.
                        </p>

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-melbourne-to-adelaide.jpg"
                            alt="Reliable Melbourne to Adelaide Freight Services by QFM"
                            title="Melbourne to Adelaide Freight – Secure & Efficient Transport by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>


                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Reliable Freight Transport to Adelaide</h5>
                        )}
                        <p>
                            Whether you require express freight, bulk transportation, or custom logistics solutions, our Melbourne to Perth freight services provide timely and secure deliveries.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect adelaide-add">
                        <h2>Melbourne to Adelaide Freight by QFM</h2>
                        <ul>
                            <li><b>Fast & Reliable Freight Transport:</b> Seamless interstate freight services between Melbourne and Adelaide with flexible delivery schedules.</li>
                            <li><b>Specialised Freight Handling:</b> Secure transport for palletised goods, oversized freight, construction materials, and retail stock.</li>
                            <li><b>Comprehensive Coverage Across Adelaide:</b> Deliveries to metro Adelaide, regional South Australia, and industrial zones.</li>
                            <li><b>Real-Time Tracking & Dedicated Support:</b> Stay updated on your shipment status with advanced tracking and 24/7 customer service.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-melbourne-to-adelaide-two.jpg"
                            loading="lazy"
                            alt="Melbourne to Adelaide freight transport solutions by QFM"
                            title="Efficient and Secure Melbourne to Adelaide Freight by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/locations-we-service"><h4>View all Locations</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="general-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>What freight services does QFM offer from Melbourne to Adelaide?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    QFM provides tailored freight transport solutions between Melbourne and Adelaide, including road, rail, and air freight options.
                                    Whether you need express delivery, bulk transport, or cost-effective shipping, we ensure seamless logistics for businesses of all sizes.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>How long does freight take from Melbourne to Adelaide?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                                <p>
                                    Standard road freight transit times from Melbourne to Adelaide typically range from 1 to 2 business days, depending on shipment size and urgency.
                                    For urgent deliveries, we offer express freight options, including next-day and same-day services where available.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>Do you provide freight services to regional South Australia?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                                <p>
                                    Yes, QFM extends its Melbourne to Adelaide freight services to regional South Australia.
                                    We deliver to locations such as Mount Gambier, Whyalla, Port Augusta, and more, ensuring reliable transport solutions for businesses in both metro and remote areas.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>What types of freight can QFM transport to Adelaide?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                <p>
                                    We transport a wide range of goods, including palletised freight, oversized loads, retail stock, and industrial equipment.
                                    Our logistics network ensures safe and secure deliveries tailored to industries such as construction, agriculture, and e-commerce.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                            <h5>How can I request a freight quote for Melbourne to Adelaide transport?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                                <p>
                                    You can request a freight quote by visiting our <Link to="/contact-us">Contact Us</Link> page.
                                    Provide details such as shipment size, weight, and delivery requirements, and our team will provide a tailored and competitive quote for your Melbourne to Adelaide freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Perth;
