import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Brisbane() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/locations-we-service"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Melbourne to Brisbane Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides efficient and secure freight services from Melbourne to Brisbane, offering road, rail, and air transport solutions to meet your business needs." />
                <meta name="keywords" content="Melbourne to Brisbane freight, interstate freight transport, QFM Logistics, road freight, rail freight, air freight, Melbourne Brisbane logistics, express freight, bulk transport, freight carriers" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Melbourne to Brisbane Freight Services | Reliable Interstate Transport by QFM" />
                <meta property="og:description" content="QFM specializes in transporting freight from Melbourne to Brisbane with secure and efficient delivery options, including express and bulk transport." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/melbourne-to-brisbane-freight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/melbourne-to-brisbane-freight" />
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight#webpage",
                                "url": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight",
                                "name": "Melbourne to Brisbane Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides premium freight services from Melbourne to Brisbane, ensuring secure and timely deliveries via road, rail, and air transport.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane.jpg",
                                "description": "Reliable Melbourne to Brisbane freight services by QFM, ensuring secure and timely interstate deliveries.",
                                "name": "Melbourne to Brisbane Freight - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane-two.jpg",
                                "description": "Melbourne to Brisbane freight solutions tailored for businesses across various industries, offering reliable transport options.",
                                "name": "Melbourne to Brisbane Freight Solutions - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Locations",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Melbourne to Brisbane Freight Services",
                                        "item": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight#service",
                                "name": "Melbourne to Brisbane Freight Services",
                                "description": "Reliable freight solutions from Melbourne to Brisbane, servicing businesses with secure and efficient transport via road, rail, and air freight.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-melbourne-to-brisbane.jpg"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type": "FAQPage",
                                "isPartOf": {
                                  "@type": "WebPage",
                                  "@id": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight",
                                  "url": "https://qfmlogistics.com.au/melbourne-to-brisbane-freight",
                                  "name": "Melbourne to Brisbane Freight Services | Logistics Solutions by QFM"
                                },
                                "mainEntity": [
                                  {
                                    "@type": "Question",
                                    "name": "What freight options are available from Melbourne to Brisbane?",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "QFM provides a range of freight options from Melbourne to Brisbane, including road freight, rail freight, and express air transport. Whether you need full truckload (FTL), less-than-truckload (LTL), or palletised freight solutions, we ensure timely and cost-effective deliveries."
                                    }
                                  },
                                  {
                                    "@type": "Question",
                                    "name": "How long does freight take from Melbourne to Brisbane?",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "Standard road freight transit times from Melbourne to Brisbane typically range from 2 to 3 business days, depending on the shipment size and urgency. For faster delivery, we offer express freight and priority air transport services that can ensure next-day arrivals."
                                    }
                                  },
                                  {
                                    "@type": "Question",
                                    "name": "Do you provide freight services to regional Queensland from Brisbane?",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "Yes, QFM extends its Melbourne to Brisbane freight services to regional Queensland. We handle freight distribution to locations such as the Gold Coast, Sunshine Coast, Toowoomba, and beyond, ensuring businesses receive reliable interstate shipping solutions."
                                    }
                                  },
                                  {
                                    "@type": "Question",
                                    "name": "What types of freight can QFM transport to Brisbane?",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "We specialise in transporting a variety of freight types, including palletised goods, oversized cargo, retail stock, construction materials, and manufacturing equipment. Our flexible transport solutions ensure safe and secure deliveries from Melbourne to Brisbane, regardless of your industry."
                                    }
                                  },
                                  {
                                    "@type": "Question",
                                    "name": "How can I get a quote for Melbourne to Brisbane freight?",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "You can request a freight quote by visiting our Contact Us page at https://qfmlogistics.com.au/contact-us. Provide details such as shipment size, weight, and delivery requirements, and our team will offer a tailored, competitive quote for your Melbourne to Brisbane freight needs."
                                    }
                                  }
                                ]
                              }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}

                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Melbourne to Brisbane Freight Services</h1>
                        {!isMobile && (
                        <p>
                            QFM provides reliable freight transport solutions from Melbourne to Brisbane, ensuring secure, efficient, and cost-effective deliveries.
                            Our network spans road, rail, and air freight, offering tailored solutions to suit your cargo needs.
                        </p>
                        )}
                            <p>
                                Whether you require express freight, bulk transportation, or specialised logistics, QFM guarantees seamless delivery between Melbourne and Brisbane.
                                With real-time tracking and an extensive carrier network, your freight remains in safe hands throughout the journey.
                            </p>
                        
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-melbourne-to-brisbane.jpg"
                            alt="Reliable Melbourne to Brisbane Freight Services by QFM"
                            title="Melbourne to Brisbane Freight – Fast & Secure Interstate Transport by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>

                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Comprehensive Freight Solutions to Brisbane</h5>
                        )}
                        <p>
                            With advanced tracking, optimised routes, and dedicated carrier partnerships, we ensure timely deliveries with competitive pricing.
                            Our Melbourne to Brisbane freight services help businesses streamline logistics and enhance supply chain performance.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect brisbane-add">
                        <h2>Melbourne to Brisbane Freight by QFM</h2>
                        <ul>
                            <li><b>Seamless Interstate Freight Solutions:</b> Comprehensive transport services from Melbourne to Brisbane, covering diverse industries and cargo types.</li>
                            <li><b>Dedicated Express Freight Services:</b> Fast, priority shipping for urgent consignments, ensuring next-day and time-sensitive deliveries.</li>
                            <li><b>Specialised Handling for Oversized Freight:</b> Expert logistics solutions for bulky, oversized, and high-value goods requiring careful transport.</li>
                            <li><b>Cost-Effective Freight Strategies:</b> Competitive pricing and optimised routing to reduce transport costs while maintaining efficiency.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-melbourne-to-brisbane-two.jpg"
                            loading="lazy"
                            alt="Melbourne to Brisbane freight transport solutions by QFM"
                            title="Efficient and Secure Melbourne to Brisbane Freight by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/locations-we-service"><h4>View all Locations</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="general-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>What freight options are available from Melbourne to Brisbane?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    QFM provides a range of freight options from Melbourne to Brisbane, including road freight, rail freight, and express air transport.
                                    Whether you need full truckload (FTL), less-than-truckload (LTL), or palletised freight solutions, we ensure timely and cost-effective deliveries.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>How long does freight take from Melbourne to Brisbane?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                                <p>
                                    Standard road freight transit times from Melbourne to Brisbane typically range from 2 to 3 business days, depending on the shipment size and urgency.
                                    For faster delivery, we offer express freight and priority air transport services that can ensure next-day arrivals.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>Do you provide freight services to regional Queensland from Brisbane?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                                <p>
                                    Yes, QFM extends its Melbourne to Brisbane freight services to regional Queensland. We handle freight distribution to locations such as the Gold Coast, Sunshine Coast, Toowoomba, and beyond, ensuring businesses receive reliable interstate shipping solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>What types of freight can QFM transport to Brisbane?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                <p>
                                    We specialise in transporting a variety of freight types, including palletised goods, oversized cargo, retail stock, construction materials, and manufacturing equipment.
                                    Our flexible transport solutions ensure safe and secure deliveries from Melbourne to Brisbane, regardless of your industry.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                            <h5>How can I get a quote for Melbourne to Brisbane freight?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                                <p>
                                    You can request a freight quote by visiting our <Link to="/contact-us">Contact Us</Link> page.
                                    Provide details such as shipment size, weight, and delivery requirements, and our team will offer a tailored, competitive quote for your Melbourne to Brisbane freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Brisbane;
