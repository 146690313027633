import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../css/Home.css';
import Header from './Header';
import Footer from './Footer';
import PickupInput from "./PickupSearch.js";
import DestinationInput from "./DeliverySearch.js";
import emailjs from 'emailjs-com';
import CustomAlert from './CustomAlert';

function Home() {
    const [isVisible, setIsVisible] = useState(false);
    const isAutoScrollingRef = useRef(false);
    const hasScrolledToBottomRef = useRef(false);
    const [formStep, setFormStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // (1) Determine if mobile (width <= 768px), so we skip certain logic
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Alert state hooks
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(''); // 'success' or 'error'

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const carouselContainerRef = useRef(null);
    const handleArrowClick = () => {
        if (isMobile) return;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;
        window.scrollTo({ top: imageBottomPosition, behavior: 'smooth' });
    };

    const handleScrollDown = useCallback(() => {
        if (isMobile) return;
        if (isAutoScrollingRef.current) return;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 62;
        if (window.scrollY > 1 && !hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({ top: imageBottomPosition, behavior: 'smooth' });
            hasScrolledToBottomRef.current = true;

            document.body.style.overflow = 'hidden';
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1200);
        }
    }, [isMobile]);

    const handleScrollUp = useCallback(() => {
        if (isMobile) return;
        if (isAutoScrollingRef.current) return;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;
        if (window.scrollY < imageBottomPosition && hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            hasScrolledToBottomRef.current = false;

            document.body.style.overflow = 'auto';
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1000);
        }
    }, [isMobile]);

    // Attach scroll event for desktop only
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const handleScroll = () => {
            handleScrollDown();
            handleScrollUp();
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile, handleScrollDown, handleScrollUp]);

    // Hide scrollbar logic
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Show/hide scroll-up button for desktop
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    // Intersection Observer for fade-in
    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    // Always scroll top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const [items, setItems] = useState([1]);
    const [nextItemId, setNextItemId] = useState(2);
    const addItem = () => {
        if (items.length < 5) {
            setItems([...items, nextItemId]);
            setNextItemId(nextItemId + 1);
        }
    };

    const deleteItem = (id) => {
        if (items.length > 1) {
            setItems(items.filter((itemId) => itemId !== id));
        }
    };

    const [formData, setFormData] = useState({
        pickup: "",
        destination: "",
        pickupResidential: false,
        destResidential: false,
        items: {},
        name: "",
        email: "",
        phone: "",
        company: "",
        comments: ""
    });

    const [itemTypes, setItemTypes] = useState({ 1: "Pallet" });
    const disableDimensionsTypes = ["A-Trailer", "B-Trailer", "Semi-Truck", "B-Double", "B-Double Mez Deck", "20' Container", "40' Container"];

    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable button immediately
        console.log("Submit button clicked");
        const newErrors = {};
        if (!formData.name.trim()) newErrors["contactquote-name"] = true;
        if (!formData.email.trim()) {
            newErrors["contactquote-email"] = true;
        } else if (!validateEmail(formData.email)) {
            newErrors["contactquote-email"] = true;
        }
        if (!formData.phone.trim()) newErrors["contactquote-phone"] = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setIsSubmitting(false); // Re-enable if validation fails
            return;
        }

        const itemsDetails = Object.entries(formData.items)
            .map(([key, item]) => {
                const type = itemTypes[key] || 'Pallet';
                return `Item ${key}:\nType: ${type}\nQuantity: ${item.quantity || ''}\nDangerous Goods: ${item.dg || 'no'}\nWeight: ${item.weight || ''}kg\nLength: ${item.length || ''}cm\nWidth: ${item.width || ''}cm\nHeight: ${item.height || ''}cm`;
            })
            .join('\n\n');

        const templateParams = {
            pickup: formData.pickup,
            destination: formData.destination,
            pickupResidential: formData.pickupResidential ? 'Yes' : 'No',
            destResidential: formData.destResidential ? 'Yes' : 'No',
            items: itemsDetails,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            company: formData.company,
            comments: formData.comments,
        };


        // emailjs.send('service_ck7pml9', 'template_cy4jff2', templateParams, '5aleywHLj9YTnXLCy')
        emailjs.send('service_eqjos09', 'template_1bmxjm7', templateParams, '6hHglwaeH0hQQmjcJ')
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
                setAlertTitle('Success');
                setAlertMessage('Thank you for submitting your quote request. Your details have been successfully sent, and a member of the QFM team will be in touch with you shortly.');
                setAlertType('success');
                setAlertVisible(true);
                setFormData({
                    pickup: "",
                    destination: "",
                    pickupResidential: false,
                    destResidential: false,
                    items: {},
                    name: "",
                    email: "",
                    phone: "",
                    company: "",
                    comments: ""
                });
                setFormStep(1);
            })
            .catch((error) => {
                console.error("FAILED...", error);
                setAlertTitle('Error');
                setAlertMessage('We’re sorry, but we encountered an error while processing your request. Please try again later or contact us at contact@qfmlogistics.com.au for assistance.');
                setAlertType('error');
                setAlertVisible(true);
            })
            .finally(() => {
                // Re-enable the button after 2 seconds
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 2000);
            });
    };


    const handleGetQuote = () => {
        const newErrors = {};

        // Validate Pickup & Destination
        const pickupValue = document.getElementById("homequote-pickup")?.value;
        const destinationValue = document.getElementById("homequote-destination")?.value;
        if (!pickupValue) newErrors["homequote-pickup"] = true;
        if (!destinationValue) newErrors["homequote-destination"] = true;

        // Validate each item's required fields
        for (let item of items) {
            const quantity = document.getElementById(`homequote-quantity-${item}`)?.value;
            const weight = document.getElementById(`homequote-weight-${item}`)?.value;
            if (!quantity) newErrors[`homequote-quantity-${item}`] = true;
            if (!weight) newErrors[`homequote-weight-${item}`] = true;

            // Only validate dimensions if not disabled
            if (!disableDimensionsTypes.includes(itemTypes[item])) {
                const length = document.getElementById(`homequote-length-${item}`)?.value;
                const width = document.getElementById(`homequote-width-${item}`)?.value;
                const height = document.getElementById(`homequote-height-${item}`)?.value;
                if (!length) newErrors[`homequote-length-${item}`] = true;
                if (!width) newErrors[`homequote-width-${item}`] = true;
                if (!height) newErrors[`homequote-height-${item}`] = true;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        } else {
            setErrors({});
            setFormStep(2);
        }
    };

    return (
        <div className="home-content">
            <Helmet>
                <title>Quan Freight Management | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management (QFM Logistics) provides professional road, rail, and air freight solutions connecting Melbourne, Sydney, Brisbane, Perth, Adelaide, and Tasmania. Delivering timely, cost-effective, and secure logistics tailored to your business needs across Australia." />
                <meta name="keywords" content="Quan Freight Management, QFM Logistics, freight services Australia, Melbourne logistics, Sydney freight, road freight solutions, rail freight logistics, air freight services, interstate deliveries, national freight management, secure freight transport, cost-effective logistics solutions." />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (QFM Logistics)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Quan Freight Management | Logistics Solutions by QFM" />
                <meta property="og:description" content="Quan Freight Management offers reliable road, rail, and air freight services connecting Melbourne to all major Australian cities. Trusted logistics solutions designed for businesses nationwide." />
                <meta property="og:url" content="https://qfmlogistics.com.au/" />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-home-main-2.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebSite",
                                "@id": "https://qfmlogistics.com.au#website",
                                "url": "https://qfmlogistics.com.au/",
                                "name": "Quan Freight Management | Logistics Solutions by QFM",
                                "description": "Quan Freight Management (QFM Logistics) provides efficient freight and logistics solutions across Australia, offering road, rail, and air freight services tailored to business needs.",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "potentialAction": {
                                    "@type": "SearchAction",
                                    "target": "https://qfmlogistics.com.au/search?q={search_term_string}",
                                    "query-input": "required name=search_term_string"
                                },
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    }
                                ]
                            },
                            // Image Objects
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-main-2.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-main-2.jpg",
                                "description": "Quan Freight Management - Reliable logistics and freight solutions across Australia, connecting Melbourne, Sydney, Brisbane, and more.",
                                "name": "Quan Freight Management | Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-intro.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-intro.jpg",
                                "description": "Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions.",
                                "name": "Quan Freight Management - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-interstate-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-interstate-freight.jpg",
                                "description": "Interstate freight services from Melbourne to major Australian cities, specialising in pallet delivery and tailored logistics solutions.",
                                "name": "Australia-Wide Road and Rail Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-air-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-air-freight.jpg",
                                "description": "Air freight services Australia-wide, providing fast delivery and efficient logistics solutions for pallets and time-sensitive shipments.",
                                "name": "Australia-Wide Air Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-local-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-local-freight.jpg",
                                "description": "Local freight services in Australia's main cities, offering reliable pallet delivery and tailored logistics solutions.",
                                "name": "Local Freight Services in Major Australian Cities - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-technology.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-technology.jpg",
                                "description": "Advanced freight technology solutions enabling seamless logistics across Australia, including pallet delivery and real-time tracking.",
                                "name": "Cutting-Edge Freight Technology Solutions - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-australia-wide-logistics.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-australia-wide-logistics.jpg",
                                "description": "Comprehensive freight services across Australia, including pallets, cartons, and project logistics solutions in major metro areas.",
                                "name": "Australia-Wide Freight Logistics - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className='home-content-container-parent'>
                {alertVisible && (
                    <CustomAlert
                        title={alertTitle}
                        message={alertMessage}
                        type={alertType}
                        onClose={() => setAlertVisible(false)}
                    />
                )}
                <Header />
                <div className="maincontent-one">
                    <div className="carousel-container" ref={carouselContainerRef}>
                        <div className="carousel-arrow-down" onClick={handleArrowClick}>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                        <img
                            src="/images/qfm-home-main-2.jpg"
                            alt="Quan Freight Management - Reliable logistics and freight solutions across Australia, connecting Melbourne, Sydney, Brisbane, and more."
                            className="carousel-image"
                            loading="lazy"
                        />

                    </div>
                </div>
                <div className="home-content-container">
                    {isVisible && !isMobile && (
                        <div className="vertical-line"></div>
                    )}
                    <div className="default-intro-section">
                        {isMobile && (
                            <img
                                src="/images/qfm-home-mobile-main.png"
                                alt="Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions"
                                title="Quan Freight Managament - Logistics Solutions by QFM"
                                loading="lazy"
                            />
                        )}

                        {isMobile && (
                            <div className="home-content-spacer-container">
                                {isMobile && (
                                    <h1><span><b>QUAN</b></span><span>FREIGHT</span><span>MANAGEMENT</span></h1>

                                )}
                            </div>
                        )}







                        {isVisible && !isMobile && (
                            <div className="default-intro-section-text-content">
                                <h1>Quan Freight Management</h1>
                                <p>Quan Freight Management (QFM) is a premier, Australian-owned and operated logistics company specialising in domestic freight across Australia, whether by road, rail, or air. From pickup to final delivery, we manage the entire logistics process — including transport, distribution, and tailored service solutions.</p>
                                <p>With a focus on reliability and efficiency, QFM leverages advanced technology and a skilled team to ensure seamless operations. From small parcels to oversized goods, our tailored services meet the diverse needs of businesses, ensuring secure and timely deliveries nationwide.</p>
                            </div>
                        )}




                        {isVisible && !isMobile && (
                            <div className="default-intro-section-image-container">
                                <img
                                    src="/images/qfm-home-intro.jpg"
                                    alt="Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions"
                                    title="Quan Freight Managament - Logistics Solutions by QFM"
                                    loading="lazy"
                                />
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className="australia-section-text-content" style={{ backgroundColor: 'rgba(0, 0, 128, 0.1)' }}>
                            <p><b>Quan Freight Management (QFM)</b> is a premier, Australian-owned and operated logistics company specializing in domestic freight across Australia, whether by road, rail, or air. We provide comprehensive, integrated door-to-door logistics solutions, including land transport, distribution, and value-added services.</p>
                        </div>
                    )}
                    {isMobile && (
                        <div className="home-content-wrapper">
                            <h5 className="homequote-heading">
                                {formStep === 1 ? "Freight Quote" : "Final Details"}
                            </h5>
                            <div className="home-content-quote">
                                <form className="homequote-form" onSubmit={handleSubmit}>
                                    {formStep === 1 && (
                                        <>
                                            <table className="homequote-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <label htmlFor="homequote-pickup">Pickup</label>
                                                        </td>
                                                        <td>
                                                            <label htmlFor="homequote-destination">Destination</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <PickupInput
                                                                id="homequote-pickup"
                                                                value={formData.pickup}
                                                                onChange={(e) => setFormData({ ...formData, pickup: e.target.value })}
                                                                style={errors['homequote-pickup'] ? { border: "1px solid red" } : {}}
                                                                autoComplete="off"
                                                                onFocus={() => {
                                                                    if (errors['homequote-pickup']) {
                                                                        const newErr = { ...errors };
                                                                        delete newErr['homequote-pickup'];
                                                                        setErrors(newErr);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <DestinationInput
                                                                id="homequote-destination"
                                                                value={formData.destination}
                                                                onChange={(e) => setFormData({ ...formData, destination: e.target.value })}
                                                                style={errors['homequote-destination'] ? { border: "1px solid red" } : {}}
                                                                autoComplete="off"
                                                                onFocus={() => {
                                                                    if (errors['homequote-destination']) {
                                                                        const newErr = { ...errors };
                                                                        delete newErr['homequote-destination'];
                                                                        setErrors(newErr);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="homequote-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="homequote-checkbox homequote-pickup-residential"
                                                                    checked={formData.pickupResidential}
                                                                    onChange={(e) => setFormData({ ...formData, pickupResidential: e.target.checked })}
                                                                />
                                                                <span className="homequote-slider"></span>
                                                                <span className="homequote-switch-label">Residential Pickup</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="homequote-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="homequote-checkbox homequote-dest-residential"
                                                                    checked={formData.destResidential}
                                                                    onChange={(e) => setFormData({ ...formData, destResidential: e.target.checked })}
                                                                />
                                                                <span className="homequote-slider"></span>
                                                                <span className="homequote-switch-label">Residential Delivery</span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            {/* Render each item form */}
                                            {items.map((item, index) => (
                                                <div key={item}>
                                                    <hr className="homequote-divider"></hr>
                                                    <table className="homequote-table homequote-item-table">
                                                        <tbody>
                                                            {/* Row 1: Labels */}
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <label htmlFor={`homequote-item-type-${item}`}>Item {index + 1}</label>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`homequote-quantity-${item}`}>Quantity</label>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`homequote-dg-${item}`}>DG</label>
                                                                </td>
                                                            </tr>
                                                            {/* Row 2: Inputs */}
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <select
                                                                        id={`homequote-item-type-${item}`}
                                                                        name={`homequote-item-type-${item}`}
                                                                        className="homequote-input"
                                                                        value={itemTypes[item] || "Pallet"}
                                                                        onChange={(e) => {
                                                                            const newItemType = e.target.value;
                                                                            setItemTypes({ ...itemTypes, [item]: newItemType });
                                                                            // Clear errors for this item when a new option is selected
                                                                            const newErrors = { ...errors };
                                                                            delete newErrors[`homequote-quantity-${item}`];
                                                                            delete newErrors[`homequote-weight-${item}`];
                                                                            delete newErrors[`homequote-length-${item}`];
                                                                            delete newErrors[`homequote-width-${item}`];
                                                                            delete newErrors[`homequote-height-${item}`];
                                                                            setErrors(newErrors);
                                                                            // If the selected type disables dimensions, clear those inputs
                                                                            if (disableDimensionsTypes.includes(newItemType)) {
                                                                                const lengthInput = document.getElementById(`homequote-length-${item}`);
                                                                                const widthInput = document.getElementById(`homequote-width-${item}`);
                                                                                const heightInput = document.getElementById(`homequote-height-${item}`);
                                                                                if (lengthInput) lengthInput.value = "";
                                                                                if (widthInput) widthInput.value = "";
                                                                                if (heightInput) heightInput.value = "";
                                                                            }
                                                                        }}
                                                                    >
                                                                        <option value="Carton">Carton</option>
                                                                        <option value="Pallet">Pallet</option>
                                                                        <option disabled>────────────</option>
                                                                        <option value="Envelope">Envelope</option>
                                                                        <option value="Satchel">Satchel</option>
                                                                        <option value="Bag">Bag</option>
                                                                        <option value="Tube">Tube</option>
                                                                        <option value="Bundle">Bundle</option>
                                                                        <option value="Drum">Drum</option>
                                                                        <option value="Skid">Skid</option>
                                                                        <option value="Panel">Panel</option>
                                                                        <option value="Crate">Crate</option>
                                                                        <option value="Ibc">IBC</option>
                                                                        <option value="Stillage">Stillage</option>
                                                                        <option value="A-Trailer">A-Trailer</option>
                                                                        <option value="Semi-Truck">Semi-Truck</option>
                                                                        <option value="B-Double">B-Double</option>
                                                                        <option value="B-Double Mez Deck">B-Double Mez Deck</option>
                                                                        <option value="20' Container">20' Container</option>
                                                                        <option value="40' Container">40' Container</option>
                                                                        <option value="other">Other/Misc</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        id={`homequote-quantity-${item}`}
                                                                        name={`homequote-quantity-${item}`}
                                                                        className="homequote-input"
                                                                        autoComplete="off"
                                                                        placeholder="Eg: 1"
                                                                        value={(formData.items[item] && formData.items[item].quantity) || ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        quantity: e.target.value
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        style={errors[`homequote-quantity-${item}`] ? { border: "1px solid red" } : {}}
                                                                        onFocus={() => {
                                                                            if (errors[`homequote-quantity-${item}`]) {
                                                                                const newErr = { ...errors };
                                                                                delete newErr[`homequote-quantity-${item}`];
                                                                                setErrors(newErr);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        id={`homequote-dg-${item}`}
                                                                        name={`homequote-dg-${item}`}
                                                                        className="homequote-input"
                                                                        autoComplete="off"
                                                                        value={(formData.items[item] && formData.items[item].dg) || "no"}
                                                                        onChange={(e) => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        dg: e.target.value
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value="yes">Yes</option>
                                                                        <option value="no">No</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                            {/* Row 3: Dimensions */}
                                                            {/* Row 3: Dimensions */}
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={`homequote-length-${item}`}>Length (cm)</label>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`homequote-width-${item}`}>Width (cm)</label>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`homequote-height-${item}`}>Height (cm)</label>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`homequote-weight-${item}`}>Weight (kg)</label>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        id={`homequote-length-${item}`}
                                                                        name={`homequote-length-${item}`}
                                                                        className="homequote-input"
                                                                        autoComplete="off"
                                                                        placeholder="In cm"
                                                                        value={(formData.items[item] && formData.items[item].length) || ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        length: e.target.value
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                                                        required={!disableDimensionsTypes.includes(itemTypes[item])}
                                                                        style={errors[`homequote-length-${item}`] ? { border: "1px solid red" } : {}}
                                                                        onFocus={() => {
                                                                            if (errors[`homequote-length-${item}`]) {
                                                                                const newErr = { ...errors };
                                                                                delete newErr[`homequote-length-${item}`];
                                                                                setErrors(newErr);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        id={`homequote-width-${item}`}
                                                                        name={`homequote-width-${item}`}
                                                                        className="homequote-input"
                                                                        placeholder="In cm"
                                                                        value={(formData.items[item] && formData.items[item].width) || ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        width: e.target.value,
                                                                                    },
                                                                                },
                                                                            })
                                                                        }
                                                                        disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                                                        required={!disableDimensionsTypes.includes(itemTypes[item])}
                                                                        style={errors[`homequote-width-${item}`] ? { border: "1px solid red" } : {}}
                                                                        onFocus={() => {
                                                                            if (errors[`homequote-width-${item}`]) {
                                                                                const newErr = { ...errors };
                                                                                delete newErr[`homequote-width-${item}`];
                                                                                setErrors(newErr);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        id={`homequote-height-${item}`}
                                                                        name={`homequote-height-${item}`}
                                                                        className="homequote-input"
                                                                        placeholder="In cm"
                                                                        value={(formData.items[item] && formData.items[item].height) || ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        height: e.target.value,
                                                                                    },
                                                                                },
                                                                            })
                                                                        }
                                                                        disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                                                        required={!disableDimensionsTypes.includes(itemTypes[item])}
                                                                        style={errors[`homequote-height-${item}`] ? { border: "1px solid red" } : {}}
                                                                        onFocus={() => {
                                                                            if (errors[`homequote-height-${item}`]) {
                                                                                const newErr = { ...errors };
                                                                                delete newErr[`homequote-height-${item}`];
                                                                                setErrors(newErr);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        id={`homequote-weight-${item}`}
                                                                        name={`homequote-weight-${item}`}
                                                                        className="homequote-input"
                                                                        autoComplete="off"
                                                                        placeholder="In kg"
                                                                        value={(formData.items[item] && formData.items[item].weight) || ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                items: {
                                                                                    ...formData.items,
                                                                                    [item]: {
                                                                                        ...formData.items[item],
                                                                                        weight: e.target.value
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        required
                                                                        style={errors[`homequote-weight-${item}`] ? { border: "1px solid red" } : {}}
                                                                        onFocus={() => {
                                                                            if (errors[`homequote-weight-${item}`]) {
                                                                                const newErr = { ...errors };
                                                                                delete newErr[`homequote-weight-${item}`];
                                                                                setErrors(newErr);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {/* Delete button appears if more than one item */}
                                                    {items.length > 1 && (
                                                        <div>
                                                            <button type="button" onClick={() => deleteItem(item)} className="delete-button">
                                                                <span className="delete-icon"><i className="fas fa-trash"></i></span>
                                                                <span className="delete-text">Delete Item</span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <hr className="homequote-divider" />
                                            <table className="homequote-table homequote-button-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {items.length < 5 && (
                                                                <button
                                                                    type="button"
                                                                    className="homequote-btn homequote-btn-add"
                                                                    onClick={addItem}
                                                                >
                                                                    + Add Item
                                                                </button>
                                                            )}
                                                        </td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <button
                                                                type="button"
                                                                className="homequote-btn homequote-btn-quote"
                                                                onClick={handleGetQuote}
                                                            >
                                                                Get Quote
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )}

                                    {formStep === 2 && (
                                        <>
                                            <table className="homequote-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <label htmlFor="homequote-name">
                                                                Name <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label htmlFor="homequote-email">
                                                                Email <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                id="homequote-name"
                                                                name="homequote-name"
                                                                className="homequote-input"
                                                                placeholder="Your name"
                                                                value={formData.name}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setFormData({ ...formData, name: value });
                                                                    if (value.trim() !== "" && errors["homequote-name"]) {
                                                                        const newErr = { ...errors };
                                                                        delete newErr["homequote-name"];
                                                                        setErrors(newErr);
                                                                    }
                                                                }}
                                                                style={errors["homequote-name"] ? { border: "1px solid red" } : {}}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                id="homequote-email"
                                                                name="homequote-email"
                                                                className="homequote-input"
                                                                placeholder="Your Email"
                                                                value={formData.email}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setFormData({ ...formData, email: value });
                                                                    if (value.trim() !== "" && errors["homequote-email"]) {
                                                                        const newErr = { ...errors };
                                                                        delete newErr["homequote-email"];
                                                                        setErrors(newErr);
                                                                    }
                                                                }}
                                                                style={errors["homequote-email"] ? { border: "1px solid red" } : {}}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label htmlFor="homequote-phone">
                                                                Phone <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label htmlFor="homequote-company">Company</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                id="homequote-phone"
                                                                name="homequote-phone"
                                                                className="homequote-input"
                                                                placeholder="Your Phone"
                                                                value={formData.phone}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setFormData({ ...formData, phone: value });
                                                                    if (value.trim() !== "" && errors["homequote-phone"]) {
                                                                        const newErr = { ...errors };
                                                                        delete newErr["homequote-phone"];
                                                                        setErrors(newErr);
                                                                    }
                                                                }}
                                                                style={errors["homequote-phone"] ? { border: "1px solid red" } : {}}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                id="homequote-company"
                                                                name="homequote-company"
                                                                className="homequote-input"
                                                                placeholder="Your Company"
                                                                value={formData.company}
                                                                onChange={(e) =>
                                                                    setFormData({ ...formData, company: e.target.value })
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <label htmlFor="homequote-comments">Comments</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <textarea
                                                                id="homequote-comments"
                                                                name="homequote-comments"
                                                                className="homequote-input"
                                                                placeholder="Your Comments"
                                                                rows="4"
                                                                maxLength={280}
                                                                value={formData.comments}
                                                                onChange={(e) =>
                                                                    setFormData({ ...formData, comments: e.target.value })
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="homequote-table homequote-button-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="homequote-btn go-back-button"
                                                                onClick={() => setFormStep(1)}
                                                            >
                                                                Go Back
                                                            </button>
                                                        </td>
                                                        <td style={{ textAlign: "right" }}>

                                                            <button
                                                                type="submit"
                                                                className="homequote-btn homequote-btn-quote submit-btn-quote"
                                                                disabled={isSubmitting}
                                                                style={isSubmitting ? { backgroundColor: 'green', cursor: 'not-allowed' } : {}}
                                                            >
                                                                Submit Quote
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    )}
                    <div className="home-section-2">
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/road-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-interstate-freight.jpg"
                                        alt="Interstate freight services from Melbourne to major Australian cities, specialising in pallet delivery and tailored logistics solutions"
                                        title="Australia-Wide Road and Rail Freight Services - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Road Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>QFM provides tailored interstate freight solutions through a vast carrier network.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/air-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-air-freight.jpg"
                                        alt="Air freight services Australia-wide, providing fast delivery and efficient logistics solutions for pallets and time-sensitive shipments"
                                        title="Australia-Wide Air Freight Services - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Air Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>We offer overnight air freight for satchels, cartons, and small pallets Australia-wide.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/local-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-local-freight.jpg"
                                        alt="Local freight services in Australia's main cities, offering reliable pallet delivery and tailored logistics solutions"
                                        title="Local Freight Services in Major Australian Cities - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Local Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>Reliable local transport with trusted couriers for ad-hoc and permanent needs.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/integrated-freight-solutions">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-technology.jpg"
                                        alt="Advanced freight technology solutions enabling seamless logistics across Australia, including pallet delivery and real-time tracking"
                                        title="Cutting-Edge Freight Technology Solutions - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Online Integration</h4>
                                        {isVisible && !isMobile && (
                                            <p>Offer multiple freight options at checkout on your website store.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="australia-section appearing-effect">
                        <div className="australia-section-image-container">
                            <img
                                src="/images/qfm-australia-wide-logistics.jpg"
                                alt="Comprehensive freight services across Australia, including pallets, cartons, and project logistics solutions in major metro areas"
                                title="Australia-Wide Freight Logistics - Logistics Solutions by QFM"
                                loading="lazy"
                            />
                        </div>
                        <div className="australia-section-text-content">
                            <h2>Australia-wide Freight</h2>
                            <ul>
                                <li><b>Comprehensive Freight Solutions:</b> Offering road, rail, air, and sea freight services tailored to your business needs.</li>
                                <li><b>Nationwide Coverage:</b> Seamlessly connecting Melbourne to major cities and regional areas across Australia.</li>
                                <li><b>Specialised Freight Services:</b> Expertise in handling dangerous goods, oversized items, and time-critical deliveries.</li>
                                <li><b>Reliable Local Freight:</b> Same-day courier services, taxi trucks, and permanent hire available within Melbourne.</li>
                                <li><b>Advanced Logistics Technology:</b> Real-time tracking and updates for complete shipment visibility.</li>
                                <li><b>Flexible Service Levels:</b> Catering to urgent, same-day, and scheduled delivery requirements.</li>
                                <li><b>Customised Solutions:</b> Freight options designed to accommodate pallets, cartons, crates, and industrial equipment.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="default-quote appearing-effect">

                        <div className="default-quote-quote">
                            <Link to="/contact-us">
                                <h4>Get In Touch</h4>
                            </Link>
                        </div>

                        <div className="default-quote-text-content">
                            <h5>QFM seamlessly connects Melbourne to destinations across Australia</h5>
                            <p>
                                Leveraging advanced technology and a highly skilled team, we provide reliable and efficient freight services to major cities and regional hubs across Australia. Whether it’s road, rail, or air freight, our tailored logistics solutions ensure your goods are delivered safely and on time.
                            </p>
                        </div>
                    </div>
                    <div className="home-section-5 appearing-effect">
                        <div className="home-section-5-text-content">
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-mission.png"
                                    alt="Australia-wide freight services, handling pallets, cartons, and project logistics with a focus on reliability and efficiency"
                                    title="Australia-Wide Freight Logistics - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Our Mission</h3>
                                    <p>Our mission is to deliver peace of mind with reliable, high-quality solutions at competitive prices. Our flexibility allows us to adapt swiftly to customer needs, ensuring efficient operations that lower costs for our clients.</p>
                                </div>
                            </div>
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-knowledge.png"
                                    alt="Expert freight services across Australia, specialising in pallets, cartons, and project logistics with trusted expertise"
                                    title="Trusted Expertise in Freight Solutions - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Expert Knowledge</h3>
                                    <p>With years of experience, our expertise has earned the trust and recognition of our customers. We are committed to delivering high-quality service, exceeding expectations and building strong, lasting relationships.</p>
                                </div>
                            </div>
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-vision.png"
                                    alt="Innovative freight solutions across Australia with a vision for excellence in logistics and customer satisfaction"
                                    title="Vision for Excellence - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Our Vision</h3>
                                    <p>Our vision is not to become the biggest, but to become the best. We focus on innovation while prioritizing our environment and community. We treat our customers, partners, and suppliers with the respect we’d expect ourselves. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div>
    );
}

export default Home;