import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const SuburbSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [suburbs, setSuburbs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [suburbsPerPage, setSuburbsPerPage] = useState(isMobile ? 10 : 30);
    const [selectedState, setSelectedState] = useState("Australia"); // Default: Show all states

    useEffect(() => {
        fetch("/documents/QFM-Suburbs-Melbourne.csv") // Load from public folder
            .then((response) => response.text())
            .then((csvData) => {
                Papa.parse(csvData, {
                    header: true,
                    dynamicTyping: true,
                    complete: (result) => {
                        const cleanedData = result.data
                            .filter((item) => item.SUBURB && item.POSTCODE && item.STATE) // Ensure required fields exist
                            .sort((a, b) => a.SUBURB.localeCompare(b.SUBURB)); // Sort by suburb name

                        setSuburbs(cleanedData);
                    },
                });
            })
            .catch((error) => console.error("Error loading CSV:", error));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setSuburbsPerPage(window.innerWidth <= 768 ? 10 : suburbsPerPage); // Reset to 10 if mobile
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [suburbsPerPage]);

    // Filter suburbs based on search term and selected state
    const filteredSuburbs = suburbs.filter((suburb) => {
        const matchesSearch =
            suburb.SUBURB?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            suburb.POSTCODE?.toString().includes(searchTerm);

        const matchesState =
            selectedState === "Australia" || suburb.STATE === selectedState;

        return matchesSearch && matchesState;
    });

    // Pagination logic
    const totalPages = Math.ceil(filteredSuburbs.length / suburbsPerPage);
    // const maxVisiblePages = 10;
    const startIndex = (currentPage - 1) * suburbsPerPage;
    const paginatedSuburbs = filteredSuburbs.slice(startIndex, startIndex + suburbsPerPage);

   // Determine max visible pages based on device type
const maxVisiblePages = isMobile ? 5 : 10;

let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
let endPage = startPage + maxVisiblePages - 1;

if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
}

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Split into columns based on mobile or desktop
    const adjustedColumns = (data, numCols) => {
        const columns = Array.from({ length: numCols }, () => []);
        data.forEach((item, index) => {
            columns[index % numCols].push(item);
        });
        return columns;
    };

    const suburbColumns = isMobile
        ? [paginatedSuburbs] // Single column for mobile
        : adjustedColumns(paginatedSuburbs, 3); // Three columns for desktop

    return (
        <div>
            <h3>Search for a Suburb or Postcode</h3>

            {/* Search Input */}
            <input
                type="text"
                className="search-input"
                placeholder="Enter suburb or postcode..."
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1); // Reset to first page on new search
                }}
            />

            {/* State Selection Dropdown */}
            <select
                className="select-dropdown"
                onChange={(e) => {
                    setSelectedState(e.target.value);
                    setCurrentPage(1); // Reset to first page when state changes
                }}
                value={selectedState}
            >
                <option value="Australia">Australia</option>
                <option value="VIC">Victoria</option>
                <option value="NSW">New South Wales</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="WA">Western Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="NT">Northern Territory</option>
                <option value="ACT">Australian Capital Territory</option>
            </select>

            {/* Dropdown for selecting suburbs per page */}
            {!isMobile && (
            <select
                className="select-dropdown-two"
                onChange={(e) => {
                    setSuburbsPerPage(Number(e.target.value));
                    setCurrentPage(1); // Reset to first page when changing number per page
                }}
                value={suburbsPerPage}
            >
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="30">30 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
            </select>
            )}
            {/* Displaying suburbs in columns */}
            <div className="suburb-container">
                {suburbColumns.map((column, colIndex) => (
                    <ul key={colIndex} className={`suburb-column ${isMobile ? "single-column" : ""}`}>
                        {column.map((suburb, index) => (
                            <li key={index}>
                                {suburb.SUBURB} - {suburb.POSTCODE} ({suburb.STATE || "N/A"})
                            </li>
                        ))}
                    </ul>
                ))}
            </div>

           {/* Pagination Controls */}
{totalPages > 1 && (
    <div className="pagination">
        {/* Show "First" button if not on the first page */}
        {startPage > 1 && <button onClick={() => handlePageChange(1)}>1</button>}
        {startPage > 2 && <span>...</span>}

        {[...Array(endPage - startPage + 1)].map((_, index) => {
            const page = startPage + index;
            return (
                <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={currentPage === page ? "active" : ""}
                >
                    {page}
                </button>
            );
        })}

        {/* Show ellipsis if there are more pages ahead */}
        {endPage < totalPages - 1 && <span>...</span>}
        {endPage < totalPages && <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>}
    </div>
)}
        </div>
    );
};

export default SuburbSearch;