import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Metal() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Metal Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised metal freight services for transporting raw metal materials, fabricated components, and heavy metal products. Our secure logistics ensure timely and reliable delivery across Australia."
        />
        <meta
          name="keywords"
          content="Metal Freight Services, Metal Transport, Raw Metal Shipping, Fabricated Metal Logistics, Heavy Metal Freight, Australia Metal Transport, Secure Metal Logistics, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Metal Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and specialised freight solutions for metal products. Trust QFM to deliver your raw metal materials, fabricated components, and heavy metal shipments safely across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-metal.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/metal-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/metal-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/metal-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/metal-freight-services",
                "name": "Metal Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised metal freight services for transporting raw metal materials, fabricated components, and heavy metal products. Our secure logistics ensure your shipments are delivered reliably across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/metal-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/metal-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Metal Freight Services",
                    "item": "https://qfmlogistics.com.au/metal-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/metal-freight-services#service",
                "name": "Metal Freight Services",
                "description": "Our metal freight services provide secure transport for raw metal materials, fabricated components, and heavy metal products. With robust packaging and expert handling, we ensure that every shipment is delivered reliably and on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-metal.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-metal-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your metal freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-metal.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-metal.jpg",
                "description": "Secure transport for raw metal and fabricated components, ensuring robust protection during transit.",
                "name": "Metal Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/metal-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-metal-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-metal-two.jpg",
                "description": "Specialised handling for heavy metal shipments, ensuring each load is secured and tracked throughout its journey.",
                "name": "Metal Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/metal-freight-services#webpage" }
              },
              {                
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What types of metal products do you transport?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We transport a wide range of metal products, including raw metal sheets, coils, structural steel, aluminium extrusions, copper wiring, and precision-machined parts. Our logistics solutions cater to industries such as construction, engineering, and manufacturing, ensuring secure and efficient delivery across Australia."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do you secure heavy metal shipments during transit?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We use reinforced strapping, load-bearing pallets, and industrial tie-downs to stabilise metal freight during transport. Our fleet is equipped with secure fastening systems to prevent movement, reducing the risk of damage. Each shipment undergoes careful weight distribution and loading assessments to maintain balance and integrity throughout the journey."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do you offer door-to-door service for metal freight?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, our comprehensive door-to-door service ensures that metal shipments are collected directly from your facility and delivered to the specified destination without unnecessary handling or delays. This service streamlines logistics for manufacturers, suppliers, and distributors, providing greater efficiency and reliability."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What industries benefit from your metal freight services?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We support a variety of industries, including construction, mining, fabrication, and infrastructure development. Whether you're supplying raw materials for production lines, transporting prefabricated metal structures, or moving high-grade industrial components, our freight solutions are designed to meet industry-specific requirements."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How can I request a quote for metal freight services?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To request a quote, visit our Contact Us page or call our support team. Provide details such as shipment dimensions, weight, pickup and delivery locations, and any specific handling needs. Our team will assess your requirements and offer a tailored freight solution at a competitive rate."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do you provide tracking for metal shipments?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we offer real-time tracking for all metal shipments, allowing you to monitor the status of your freight from dispatch to delivery. Our system provides live updates, estimated arrival times, and proof of delivery to ensure full visibility and peace of mind."
                        }
                    }
                ]
            }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Metal Freight Services</h1>
            {!isMobile && (
              <p>
                Moving metal requires specialised logistics to handle the weight, size, and durability of various materials. QFM provides tailored transport solutions for raw metal, processed sheets, fabricated components, and industrial-grade structures, ensuring secure and efficient delivery across Australia.
              </p>
            )}
            <p>
              Whether transporting steel beams, aluminium panels, copper wiring, or heavy coils, we utilise advanced load-securing methods to prevent shifting and damage in transit. Our freight network connects major cities and regional areas, offering reliable delivery for manufacturers, construction firms, and engineering projects. With expert handling and optimised routing, we support seamless supply chain operations for metal industries nationwide.
            </p>

          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-metal.jpg"
              alt="Secure transport for raw and fabricated metal products across Australia"
              title="Metal Freight Services - Reliable and Secure Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Secure & Efficient Metal Freight Solutions</h5>
            )}
            <p>
              Our freight solutions provide secure and efficient transport for metal products, meeting the demands of various industries.
              <Link to="/goods-and-products"> Explore our full range of freight solutions</Link> for reliable and streamlined logistics.
            </p>

          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Metal Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure & Stable Transport:</b> Heavy-duty tie-downs and reinforced loading techniques ensure the safe delivery of metal products.
              </li>
              <li>
                <b>Tailored Freight Solutions:</b> Expert handling for raw materials, fabricated components, and large-scale industrial metal shipments.
              </li>
              <li>
                <b>Extensive Australian Coverage:</b> Reliable transport services connecting metropolitan hubs and remote industrial sites.
              </li>
              <li>
                <b>Advanced Shipment Tracking:</b> Real-time visibility for complete peace of mind from pickup to delivery.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-metal-two.jpg"
              loading="lazy"
              alt="Professional freight for metal products ensuring secure and timely delivery"
              title="Metal Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of metal products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle the transport of a wide range of metal products, including raw metal sheets, coils, fabricated structures, industrial components, and precision-machined parts. Whether you're moving heavy steel beams, aluminium sheets, copper wiring, or specialty alloys, our logistics solutions are tailored to accommodate different load sizes and handling requirements.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How do you secure heavy metal shipments during transit?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  We use reinforced strapping, industrial tie-downs, and load-bearing pallets to ensure stability during transport. Additionally, our fleet is equipped with heavy-duty securing systems to prevent shifting, minimising the risk of damage. Every shipment undergoes weight distribution analysis and secure loading practices to maintain balance and structural integrity throughout the journey.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you offer door-to-door service for metal freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, our door-to-door service ensures that your metal shipments are collected directly from your facility and transported safely to the designated location. This minimises handling risks and improves efficiency, allowing manufacturers, suppliers, and distributors to streamline their supply chain without unnecessary delays or transfer points.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>What industries benefit from your metal freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Our metal freight services cater to industries such as construction, engineering, manufacturing, mining, and infrastructure development. Whether supplying raw materials for fabrication plants, delivering precision-cut components to assembly lines, or transporting construction-grade metals to project sites, we ensure reliable and efficient freight solutions.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for metal freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To request a quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our support team. Please provide details such as shipment dimensions, weight, pickup and delivery locations, and any specific handling requirements. Our team will assess your needs and provide a tailored freight solution at a competitive rate.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>Do you provide tracking for metal shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '320px' : '0px' }}>
                <p>
                  Yes, all shipments include real-time tracking, allowing you to monitor your freight’s journey from pickup to final delivery. Our tracking system provides status updates, estimated arrival times, and proof of delivery, giving you complete visibility and peace of mind.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Metal;