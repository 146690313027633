import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';
import SuburbSearch from "./SuburbSearch.js";

function Zone() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const servicesLink = document.querySelector(
            'a[href="/resources"]'
        );
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Zone Guide | Logistics Solutions by QFM</title>
                <meta name="description" content="Explore QFM's Zone Guide to understand freight coverage across Australia. Get insights on delivery zones, transit times, and cost-effective shipping solutions from Melbourne to all major cities and regional areas." />
                <meta name="keywords" content="Freight Zone Guide, Australian Freight Coverage, QFM Logistics, Quan Freight Management, Melbourne Freight Services, Interstate Freight Zones, Delivery Transit Times, Freight Shipping Australia, Cost-Effective Logistics, Reliable Freight Services, Regional Freight Coverage, Freight Zones Victoria, Freight Transit Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Zone Guide | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's Zone Guide to navigate freight delivery zones across Australia. Whether shipping pallets, cartons, or bulk freight, our guide helps you plan efficient and cost-effective logistics from Melbourne to nationwide destinations." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-zone-guide.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/zone-guide" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/zone-guide" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/zone-guide#webpage",
                                "url": "https://qfmlogistics.com.au/zone-guide",
                                "name": "Zone Guide | Freight Zones & Suburb Search by QFM",
                                "description": "Explore QFM's Zone Guide to search for Australian suburbs and freight zones. Plan efficient deliveries with optimised freight routes, transit times, and cost-effective shipping solutions.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/zone-guide#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/resources#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Resources",
                                        "item": "https://qfmlogistics.com.au/resources"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Australian Zone Guide",
                                        "item": "https://qfmlogistics.com.au/zone-guide"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/zone-guide#service",
                                "name": "Zone Guide - Freight Zones & Suburb Search",
                                "description": "QFM's Zone Guide helps businesses and individuals search for Australian suburbs and freight zones, providing insights into delivery routes, transit times, and cost-effective shipping solutions.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "serviceType": "Freight Zone Mapping & Logistics Planning",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-zone-guide.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-zone-guide.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-zone-guide.jpg",
                                "description": "QFM Zone Guide - Search Australian suburbs and freight zones to plan efficient delivery routes and shipping logistics.",
                                "name": "QFM Zone Guide - Freight Zones & Suburb Search for Australia-Wide Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": { "@id": "https://qfmlogistics.com.au/zone-guide#webpage" }
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="ourstory-intro-section-text-content">
                        <h1>Australian Zone Guide</h1>

                        <p>
                            Navigate freight delivery zones effortlessly with QFM’s Australian Freight Zone Guide. Whether you're shipping within Melbourne, across Victoria, or interstate to Sydney, Brisbane, Adelaide, or Perth, our optimised freight network ensures reliable, cost-effective, and efficient transport solutions.
                        </p>

                        {!isMobile && (
                            <p>
                                Use our Zone Guide below to search for any suburb Australia-wide and find the best freight routes based on postcode zones and delivery regions. Our freight zone mapping system enables businesses to plan seamless shipping with accurate transit times and optimised logistics.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-zone-guide.jpg"
                            alt="QFM Zone Guide - Search Australian suburbs for efficient freight routes and delivery zones"
                            title="QFM Zone Guide | Freight Zones & Suburb Search for Australia-Wide Logistics"
                            loading="lazy"
                        />  
                    </div>
                </div>
                <div className="default-quote appearing-effect" style={isMobile ? { marginBottom: "10px" } : {}}>

                    <div className="default-quote-quote">
                        <Link
                            to="/contact-us"
                        >
                            <h4>Request a Quote</h4>
                        </Link>
                    </div>
                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Optimised Freight Solutions Across Australia</h5>
                            <p>
                                Using advanced technology and smart logistics, we ensure fast, efficient, and reliable freight movement nationwide.
                            </p>
                        </div>
                    )}
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content">
                        <SuburbSearch />


                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Zone;