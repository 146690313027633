import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';

function Technology() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const IdForX = "MzMzNTE="; 
    const IdForC = atob("NTE3MzQ="); 
    // const IdForV = "NTY4MjA="; 
    
    const [selectedId, setSelectedId] = useState(null);
    
    useEffect(() => {
        if (!selectedId) return;
        const script = document.createElement("script");
        script.src = "https://portal.transvirtual.co/Resource/WidgetScript/track";
        script.async = true;
        document.body.appendChild(script);
        const trackElement = document.getElementById("transvirtual-track");
        if (trackElement) {
            trackElement.setAttribute("rel", selectedId);
        }
        return () => {
            document.body.removeChild(script);
        };
    }, [selectedId]);
    
    function handleSelect(e) {
        const value = e.target.value;
        if (value === "option1") {
            setSelectedId(atob(IdForX));
        } else if (value === "option2") {
            setSelectedId(IdForC);
        } else {
            setSelectedId(null);
        }
    }

    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Track Shipment | Logistics Solutions by QFM</title>
                <meta name="description" content="Track your shipment with Quan Freight Management. Our real-time tracking system ensures visibility of your freight's location, status, and estimated delivery time across Australia." />
                <meta name="keywords" content="Track Shipment, Freight Tracking, Real-Time Tracking, Quan Freight Management, QFM, QFM Logistics, Freight Visibility, Track My Freight, Logistics Tracking System, Freight Status, Freight Management Australia, Real-Time Freight Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Track Shipment | Logistics Solutions by QFM" />
                <meta property="og:description" content="Easily track your shipment with Quan Freight Management's real-time freight tracking system. Stay informed on your freight's location and status with our advanced logistics solutions." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-track-shipment.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/track-shipment" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/track-shipment" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/track-shipment#webpage",
                                "url": "https://qfmlogistics.com.au/track-shipment",
                                "name": "Track Shipment | Real-Time Freight Tracking by QFM",
                                "description": "Track your shipment with Quan Freight Management. Our real-time tracking system ensures visibility of your freight's location, status, and estimated delivery time across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/track-shipment#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/track-shipment#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Track Shipment",
                                        "item": "https://qfmlogistics.com.au/track-shipment"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/track-shipment#tracking-service",
                                "name": "Track Shipment",
                                "description": "Real-time freight tracking system providing visibility of freight location, status, and estimated delivery time across Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "serviceType": "Freight Tracking Services",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                }
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="trackshipment-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="trackshipment-intro-section appearing-effect">
                    <div className="trackshipment-intro-section-text-content">
                        <h1>Track Shipment</h1>
                        <p>
                            Select relevant tracking number ID below. If your search doesn't work, please{" "}
                            <Link to="/contact-us" className="contact-us">get in touch</Link>.
                        </p>
                        <select className='trackshipment-dropdown' onChange={handleSelect} defaultValue="">
                            <option value="">Select an ID</option>
                            <option value="option1">QFM/Pxxxxx</option>
                            <option value="option2">QUANxxxxxx</option>
                            {/* <option value="option3">VTFExxxxxx</option> */}
                        </select>
                    </div>
                </div>
                <div className="trackshipment-section-3 appearing-effect">
                    <div className="trackshipment-section-3-text-content">
                        <div id="transvirtual-track"></div>
                        <div>
                        </div>
                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Technology;