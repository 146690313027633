import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Signage() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Signage Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised signage freight services for transporting advertising, promotional, and retail signage with secure handling and efficient logistics. Our customised solutions ensure timely delivery across Australia."
        />
        <meta
          name="keywords"
          content="Signage Freight Services, Advertising Sign Transport, Promotional Sign Logistics, Retail Sign Shipping, Secure Signage Freight, Australia Freight, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Signage Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and customised signage freight services for transporting advertising, promotional, and retail signage. Trust QFM to deliver your signage shipments safely and on schedule across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-signage.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/signage-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/signage-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/signage-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/signage-freight-services",
                "name": "Signage Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised signage freight services for transporting advertising, promotional, and retail signage with secure handling and efficient logistics. Our customised solutions ensure timely delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/signage-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/signage-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Signage Freight Services",
                    "item": "https://qfmlogistics.com.au/signage-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/signage-freight-services#service",
                "name": "Signage Freight Services",
                "description": "Our signage freight services are tailored for the secure transport of advertising, promotional, and retail signage. We specialise in managing shipments for billboards, LED displays, banners, and other signage elements with precision and care.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-signage.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your signage freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-signage.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-signage.jpg",
                "description": "Secure transport for advertising and retail signage, ensuring careful handling and prompt delivery.",
                "name": "Signage Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/signage-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-signage-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-signage-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-signage-two.jpg",
                "description": "Specialised freight solutions for transporting signage safely and on schedule across Australia",
                "name": "Reliable Signage Freight Services - Secure Transport by Quan Freight Management",
                "author": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "license": {
                  "@id": "https://qfmlogistics.com.au#license"
                },
                "creator": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/signage-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of signage can you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of signage, including large outdoor billboards, LED displays, retail store signage, corporate branding materials, and trade show displays. Our specialised freight solutions ensure secure transport for both rigid and flexible signage."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you deliver signage directly to events or retail locations?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we coordinate deliveries to event venues, shopping centres, commercial buildings, and trade shows. Our team ensures your signage arrives on time and in perfect condition for installation."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express signage freight for urgent campaigns?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We offer express freight options for urgent marketing campaigns, store openings, and last-minute event signage, ensuring rapid and reliable delivery across Australia."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What regions do you cover for signage freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide coverage, delivering signage freight to major cities like Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for signage freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a tailored quote, visit our <a href='https://qfmlogistics.com.au/contact-us'>Contact Us</a> page and provide details such as signage dimensions, weight, delivery location, and any specific handling requirements."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/signage-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Signage Freight Services</h1>
            
              <p>
                Whether it’s large outdoor billboards, in-store displays, or event signage, we ensure secure handling and reliable delivery that aligns with your business timelines and marketing objectives.

                With a nationwide logistics network, we streamline time-sensitive rollouts and retail installations, ensuring your signage reaches the right location on schedule.
              </p>
            {!isMobile && (
            <p>
              Our dedicated signage freight services accommodate a wide range of materials, including rigid boards, vinyl banners, acrylic displays, and LED signage. From bulk shipments for national advertising campaigns to custom store installations, we offer flexible logistics solutions designed for safe, efficient, and damage-free transport.
            </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-signage-two.jpg"
              alt="Secure transport for advertising and promotional signage across Australia"
              title="Signage Freight Services - Reliable & Secure Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Specialised Freight Solutions for Secure Signage Transport</h5>
            )}
            <p>
              We provide transport for all types of signage, from large outdoor billboards to delicate indoor displays, ensuring secure handling and timely delivery.
              <Link to="/goods-and-products"> Discover our full range of freight solutions</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">

            <h2>Melbourne Signage Freight by QFM</h2>
            <ul>
              <li>
                <b>Expert Transport for All Signage Types:</b> We handle billboards, retail displays, event banners, and digital signage with precision, ensuring secure and damage-free delivery.
              </li>
              <li>
                <b>Seamless Logistics for Marketing Campaigns:</b> Supporting national rollouts, promotional events, and retail store installations with scheduled and time-critical deliveries.
              </li>
              <li>
                <b>Flexible Freight Solutions for Various Sizes & Materials:</b> From lightweight vinyl banners to large acrylic and metal signage, we accommodate diverse signage needs with custom logistics options.
              </li>
              <li>
                <b>Reliable Nationwide Coverage:</b> Delivering signage across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with real-time tracking for complete visibility.
              </li>
            </ul>

          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-signage-two.jpg"
              loading="lazy"
              alt="Specialised freight solutions for transporting signage safely and on schedule across Australia"
              title="Reliable Signage Freight Services - Secure Transport by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of signage can you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  We transport a wide range of signage, including large outdoor billboards, LED displays, retail store signage, corporate branding materials, and trade show displays. Our specialised freight solutions ensure secure transport for both rigid and flexible signage.
                </p>
              </div>
            </div>

     

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you deliver signage directly to events or retail locations?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "280px" : "0px" }}>
                <p>
                  Yes, we coordinate deliveries to event venues, shopping centres, commercial buildings, and trade shows. Our team ensures your signage arrives on time and in perfect condition for installation.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you offer express signage freight for urgent campaigns?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "280px" : "0px" }}>
                <p>
                  Absolutely. We offer express freight options for urgent marketing campaigns, store openings, and last-minute event signage, ensuring rapid and reliable delivery across Australia.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>What regions do you cover for signage freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "260px" : "0px" }}>
                <p>
                  We provide nationwide coverage, delivering signage freight to major cities like Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for signage freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "240px" : "0px" }}>
                <p>
                  To receive a tailored quote, visit our <Link to="/contact-us">Contact Us</Link> page and provide details such as signage dimensions, weight, delivery location, and any specific handling requirements.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div >
  );
}

export default Signage;