import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Cement() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top (desktop only)
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the 'goods and products' nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Cement Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised freight solutions for cement and concrete shipments. Our secure and efficient logistics ensure safe, timely delivery of building materials across Australia."
        />
        <meta
          name="keywords"
          content="Cement Freight Services, Concrete Shipping, Cement Logistics, Building Materials Transport, Australia Cement Freight, Secure Cement Transport, Melbourne Concrete Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Cement Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Specialised freight solutions for cement and concrete shipments. QFM ensures secure, efficient, and timely transport of building materials across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-cement.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/cement-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/cement-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/cement-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/cement-freight-services",
                "name": "Cement Freight Services | Logistics Solutions by QFM",
                "description": "Specialised freight solutions for cement and concrete shipments. Our secure logistics ensure that building materials are delivered safely and on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/cement-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/cement-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Cement Freight Services",
                    "item": "https://qfmlogistics.com.au/cement-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/cement-freight-services#service",
                "name": "Cement Freight Services",
                "description": "Our cement freight services offer secure transport for cement and concrete shipments. We ensure that your building materials are handled with care and delivered efficiently, meeting the rigorous demands of the construction industry across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-cement.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-cement-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your cement freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cement.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cement.jpg",
                "description": "Secure transport solutions for cement shipments ensuring safe delivery of construction materials.",
                "name": "Cement Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cement-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cement-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cement-two.jpg",
                "description": "Efficient and secure transport for concrete materials across Australia.",
                "name": "Cement Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cement-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/cement-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of cement products do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of cement-related products, including bagged cement, precast concrete, and ready-made construction materials shipments. Our freight solutions ensure secure and timely deliveries to construction sites, warehouses, and distributors."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer bulk and project-based freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we handle both small-scale shipments and large bulk consignments. Whether you need individual bags of cement or large-scale deliveries for infrastructure projects, we offer customised logistics solutions to meet your requirements."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for cement freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide cement transport services across Australia, including Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive logistics network ensures efficient deliveries to construction sites and supply chain hubs nationwide."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How should I prepare my cement shipment for transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Ensure cement bags are stacked securely on pallets and shrink-wrapped if needed to prevent shifting. Bulk cement should be loaded in appropriate transport vehicles with secure containment. If special handling is required, consult our team to arrange the best transport solution."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I track my cement shipment?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide real-time tracking options for all cement shipments, allowing you to monitor progress, estimated arrival times, and delivery confirmations. Our system ensures complete visibility from dispatch to arrival."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I request a quote for cement freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Visit our Contact Us page or call our team with details such as pickup and delivery locations, shipment size, weight, and any special handling requirements. We will provide a tailored freight solution and a competitive quote based on your needs."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cement-freight-services#webpage" }
              }              
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Cement Freight Services</h1>

            <p>
              Transporting precast concrete, cement blocks, and ready-made construction materials requires precision handling. QFM specialises in secure freight solutions tailored for factory-produced cement products, ensuring safe loading, transit stability, and on-time delivery to construction sites, warehouses, and retailers.
            </p>
            {!isMobile && (
              <p>
                With a nationwide freight network, we deliver to major cities and remote construction sites across Australia, maintaining strict handling standards for heavy and sensitive materials. Whether supplying infrastructure projects, commercial developments, or retail distributors, our tailored transport solutions provide timely, secure, and efficient delivery to keep your operations running smoothly.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-cement.jpg"
              alt="Secure transport for cement and concrete shipments across Australia"
              title="Cement Freight Services - Efficient and Reliable Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Freight Solutions for Cement Products</h5>
            )}
            <p>
              We provide secure and efficient transport for precast concrete, cement blocks, and ready-made construction materials, ensuring safe handling and timely delivery.
              <Link to="/goods-and-products"> Browse our full range of freight services</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Cement Freight by QFM</h2>
            <ul>
              <li>
                <b>Transport for Cement Products:</b> Our fleet is built to handle precast concrete, bagged cement, and ready-made construction materials with reinforced loading and secure transit methods.
              </li>
              <li>
                <b>Scalable Freight Solutions:</b> Whether delivering individual pallets or coordinating large-scale cement supply for major projects, we ensure timely and efficient transport.
              </li>
              <li>
                <b>Consistent Nationwide Coverage:</b> We deliver across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas, keeping construction sites and distributors fully stocked.
              </li>
              <li>
                <b>Advanced Tracking for Complete Visibility:</b> Monitor your cement shipments in real time, ensuring precise scheduling and supply chain efficiency.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-cement-two.jpg"
              loading="lazy"
              alt="Efficient transport for cement shipments ensuring safe delivery of building materials"
              title="Cement Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="pallet-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of cement products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle various cement freight, including ready-made construction materials, bagged cement, and precast concrete. Whether for commercial or infrastructure projects, we ensure reliable and efficient transport to meet your construction deadlines.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer bulk and project-based freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Yes, we provide scalable transport solutions for precast concrete and large-scale construction projects. Whether you need full truckloads, semi-trailers, or B-double transport, we can accommodate high-volume shipments with precision logistics.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Which regions do you service for cement freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '280px' : '0px' }}>
                <p>
                  Our network spans Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, ensuring reliable supply to construction sites, manufacturers, and distributors. 
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>How do I track my cement shipment?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '250px' : '0px' }}>
                <p>
                  Our freight services include advanced real-time tracking, giving you full visibility over your cement shipments from dispatch to final delivery. You’ll receive updates on estimated arrival times, route progress, and delivery confirmation.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How should I prepare my cement shipment for transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To ensure a smooth and compliant transport process, bagged cement should be securely stacked on pallets and shrink-wrapped to prevent shifting. Construction materials shipments require proper loading onto suitable transport vehicles, such as flatbeds with securing rails or enclosed trailers. Our fleet adheres to strict OH&S guidelines, ensuring safe handling and compliance with industry regulations. If you have specific requirements, our team can guide you on the best preparation methods.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How do I request a quote for cement freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To get a personalised quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our team. Provide details such as pickup and delivery locations, shipment size, weight, and any special requirements, and we will tailor a freight solution that meets your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Cement;