import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function RoadFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Road Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management provides reliable road freight services across Melbourne, Brisbane, and Australia-wide. Our cost-effective and flexible logistics solutions ensure the efficient and timely transport of goods with advanced tracking and expert handling." />
                <meta name="keywords" content="Road Freight Services, Quan Freight Management, QFM Logistics, Road Transport Australia, Melbourne Road Freight, Brisbane Road Logistics, Freight Solutions, Reliable Road Logistics, Long-Haul Freight, Interstate Freight, Local Freight, Safe Freight Handling, Advanced Road Freight Solutions, Cost-Effective Logistics Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Road Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's road freight services. Serving Melbourne, Brisbane, and Australia-wide, we ensure efficient and secure delivery with advanced logistics solutions and professional handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-road-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/road-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/road-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/road-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/road-freight-services",
                                "name": "Road Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides comprehensive and reliable road freight services connecting Melbourne with Sydney, Brisbane, Perth, Adelaide, and beyond. Tailored solutions ensure safe and efficient transport for all shipment types, backed by real-time tracking and expert logistics.",
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/road-freight-services#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/road-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Road Freight Services",
                                        "item": "https://qfmlogistics.com.au/road-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/road-freight-services#service",
                                "name": "Road Freight Services",
                                "description": "Comprehensive road freight services connecting Melbourne with Sydney, Brisbane, Perth, Adelaide, and regional areas, ensuring safe and efficient transport tailored to your needs.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-services-road-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-road-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-road-freight.jpg",
                                "description": "Efficient road freight services ensuring reliable transport for goods across Melbourne, Sydney, Brisbane, Perth, Adelaide, and beyond.",
                                "name": "Road Freight Services – Reliable Logistics by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-road-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-road-freight-two.jpg",
                                "description": "Reliable and efficient road freight solutions connecting Melbourne to major Australian cities and regional areas.",
                                "name": "Road Freight Logistics – Comprehensive Solutions by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/road-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported via road freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We handle a variety of goods, including general cargo, oversized loads, palletised goods, and even fragile or hazardous materials. Contact us to confirm your shipment details."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/road-freight-services",
                                            "name": "Road Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Which areas does QFM’s road freight network cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our network connects Melbourne to key hubs like Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/road-freight-services",
                                            "name": "Road Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are express delivery options available for road freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer express services for time-sensitive shipments, ensuring expedited delivery without compromising safety and efficiency."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/road-freight-services",
                                            "name": "Road Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide tracking for road freight shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, all shipments come with real-time tracking, giving you updates from dispatch to delivery."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/road-freight-services",
                                            "name": "Road Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a road freight quote?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page, provide shipment details, and receive a tailored quote promptly."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/road-freight-services",
                                            "name": "Road Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Road Freight Services</h1>

                        <p>
                            At QFM, our Road Freight services connect Melbourne to major hubs like Sydney, Brisbane, Perth, and Adelaide, as well as regional locations across Australia. We use advanced logistics technology to provide real-time updates, optimise routes, and reduce transit times. Whether it’s one-off deliveries or regular freight needs, our tailored solutions ensure your business runs smoothly.
                        </p>
                        {!isMobile && (
                            <p>
                                Whether you're transporting small pallets or oversized loads, our trusted network of carriers ensures secure and timely delivery. We tailor our services to meet each client’s unique requirements, providing a seamless and cost-effective solution for all your road transportation needs.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-road-freight.jpg"
                            alt="Dependable road freight services connecting Melbourne to major cities across Australia"
                            title="Road Freight Services - Reliable logistics solutions by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Reliable and efficient road freight solutions designed to meet your transport needs</h5>
                        )}
                        <p>
                            Our road freight solutions are designed to adapt to the unique challenges of Australia’s vast landscape, ensuring every shipment is handled with precision and care. <Link to="/our-services">Discover the full range of services we offer</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Road Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Extensive Carrier Network:</b> Benefit from access to a diverse range of carriers, ensuring timely and efficient delivery across all regions.
                            </li>
                            <li>
                                <b>Customizable Solutions:</b> Receive tailored transport plans that align with the unique requirements of your business, whether handling small parcels or large freight.
                            </li>
                            <li>
                                <b>Real-Time Tracking:</b> Monitor your shipments with our advanced tracking systems, providing complete visibility and peace of mind throughout the transportation process.
                            </li>
                            <li>
                                <b>Dedicated Support Team:</b> Our experienced logistics professionals are available around the clock to assist you.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-road-freight-two.jpg"
                            loading="lazy"
                            alt="Efficient road freight services connecting Melbourne to major cities and regional areas across Australia"
                            title="Road Freight Services – Reliable Logistics Solutions by QFM"
                        />

                    </div>
                    {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                </div>

                <div className="road-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of goods can be transported via road freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our road freight services are equipped to handle a wide variety of goods, including general cargo, oversized loads, palletised goods, cartons, and even fragile or hazardous materials.
                                    <br />
                                    Contact our team to confirm if your shipment is suitable for road freight transport.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Which areas does QFM’s road freight network cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our road freight services operate nationwide, connecting Melbourne with key hubs such as Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations across Australia.
                                    <br />
                                    We ensure consistent and reliable deliveries wherever your goods need to go.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Are express delivery options available for road freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer express road freight services for time-sensitive shipments. Our dedicated team ensures expedited deliveries while maintaining the highest standards of safety and efficiency.
                                    <br />
                                    Speak to us about your deadlines, and we’ll create a solution that works for you.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>What is the maximum weight or size for road freight shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    We can handle shipments of various sizes and weights, including oversized and heavy loads. Specific limits may vary depending on transport regulations and routes.
                                    <br />
                                    Contact us with your shipment details, and we’ll provide tailored guidance.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you provide tracking for road freight shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, all road freight shipments include real-time tracking. You’ll receive updates at every stage of the journey, from dispatch to delivery.
                                    <br />
                                    Our goal is to keep you informed and give you peace of mind.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I request a road freight quote?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/contact-us">Contact Us</Link> page. Provide key details such as the type of goods, weight, dimensions, pickup and delivery locations, and any special handling requirements.
                                    <br />
                                    Our team will review your information and get back to you with a tailored solution.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>What industries commonly use your road freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our road freight services are widely used by industries such as retail, manufacturing, agriculture, construction, and healthcare. Whether you’re transporting raw materials, finished goods, or specialised equipment, we can meet your specific requirements.
                                    <br />
                                    Let us know your needs, and we’ll develop a plan to support your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default RoadFreight;