import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Retail() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Retail Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised retail freight services for transporting store inventory, consumer goods, and promotional materials securely across Australia. Our customised logistics ensure timely deliveries and efficient supply chain management."
        />
        <meta
          name="keywords"
          content="Retail Freight Services, Retail Inventory Transport, Consumer Goods Shipping, Store Supplies Logistics, Secure Retail Freight, Australia Freight, Reliable Retail Transport, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Retail Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure retail freight services for transporting inventory, consumer goods, and promotional materials. Trust QFM for customised logistics that deliver your retail products on schedule across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-retail.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/retail-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/retail-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/retail-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/retail-freight-services",
                "name": "Retail Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised retail freight services for transporting store inventory, consumer goods, and promotional materials securely across Australia. Our customised logistics ensure timely delivery and efficient supply chain management.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/retail-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/retail-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Retail Freight Services",
                    "item": "https://qfmlogistics.com.au/retail-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/retail-freight-services#service",
                "name": "Retail Freight Services",
                "description": "Our retail freight services are designed to transport store inventory, consumer goods, and promotional materials with precision and care. We offer customised logistics solutions that ensure your retail shipments are delivered securely and on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-retail.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your retail freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-retail.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-retail.jpg",
                "description": "Secure transport for retail inventory and consumer goods, ensuring reliable delivery to stores and distribution centres.",
                "name": "Retail Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/retail-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-retail-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-retail-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-retail-two.jpg",
                "description": "Reliable freight solutions for retail inventory, ensuring seamless supply chain operations across Australia",
                "name": "Retail Freight Services - Secure Transport for Store Inventory and Consumer Goods by QFM",
                "author": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "license": {
                  "@id": "https://qfmlogistics.com.au#license"
                },
                "creator": {
                  "@id": "https://qfmlogistics.com.au#organization"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/retail-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "How can retail businesses benefit from your freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our retail freight solutions streamline inventory movement, ensuring timely restocking and smooth supply chain operations. We offer flexible delivery schedules, helping businesses manage seasonal demand, product launches, and store replenishments efficiently."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide dedicated freight solutions for high-demand retail periods?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer customised freight strategies to accommodate peak seasons, such as holiday sales, promotional events, and product launches. Our scalable logistics ensure retailers receive stock on time without disruption."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you transport fragile or high-value retail goods?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We handle delicate and high-value retail items, including electronics, fashion, and luxury goods, with expert care. Our secure transport methods ensure product integrity from warehouse to storefront."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer multi-location retail distribution?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we facilitate nationwide distribution, ensuring stock reaches multiple store locations across Australia. Whether you're supplying a single outlet or a franchise network, our logistics solutions are designed for efficiency and cost-effectiveness."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you handle last-minute or urgent retail deliveries?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We offer express and same-day delivery options for urgent retail shipments. Whether it’s last-minute inventory replenishment or a critical product launch, our team ensures your goods arrive promptly."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you integrate with retail inventory management systems?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we work with retailers to streamline logistics with their existing inventory management systems. This integration helps track stock movements, improve order accuracy, and maintain optimal inventory levels across multiple locations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for retail freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Requesting a quote is simple. Visit our <a href='https://qfmlogistics.com.au/contact-us'>Contact Us</a> page and provide details such as shipment size, pickup and delivery locations, and any special requirements. Our team will respond promptly with a tailored freight solution."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/retail-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Retail Freight Services</h1>
            {!isMobile && (
            <p>
              Ensuring seamless movement of retail inventory, consumer goods, and promotional materials, our freight services are built to support the fast-paced retail industry. We provide tailored logistics solutions that maintain efficiency, ensuring your products arrive safely and on time.
            </p>
            )}
            <p>
              With a nationwide logistics network, we streamline supply chains by offering secure and flexible transport solutions for retailers of all sizes. From individual shipments to bulk stock movements, we help businesses stay stocked, reduce delays, and meet customer demand. Our expertise in retail freight ensures smooth distribution to stores, warehouses, and fulfillment centers across Australia, keeping your business running without disruption.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-retail.jpg"
              alt="Secure transport for retail inventory and consumer goods across Australia"
              title="Retail Freight Services - Reliable Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Customised Retail Freight Solutions for Your Supply Chain</h5>
            )}
            <p>
              Our tailored logistics ensure that your retail shipments are handled securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Retail Freight by QFM</h2>
            <ul>
              <li>
                <b>Seamless Inventory Transport:</b> We ensure the efficient movement of stock from warehouses to retail stores, minimising delays and optimising supply chain flow.
              </li>
              <li>
                <b>Flexible Freight Solutions:</b> Whether you require scheduled deliveries, urgent replenishments, or seasonal restocks, our tailored logistics keep your business running smoothly.
              </li>
              <li>
                <b>Secure Handling & Packaging:</b> From fragile consumer goods to high-volume shipments, we implement best practices to safeguard your products during transit.
              </li>
              <li>
                <b>Nationwide Retail Distribution:</b> Serving Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations, we connect retail businesses with reliable transport solutions Australia-wide.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-retail-two.jpg"
              loading="lazy"
              alt="Reliable freight solutions for retail inventory, ensuring seamless supply chain operations across Australia"
              title="Retail Freight Services - Secure Transport for Store Inventory and Consumer Goods by QFM"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="satchel-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>How can retail businesses benefit from your freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  Our retail freight solutions streamline inventory movement, ensuring timely restocking and smooth supply chain operations. We offer flexible delivery schedules, helping businesses manage seasonal demand, product launches, and store replenishments efficiently.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you provide dedicated freight solutions for high-demand retail periods?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                <p>
                  Yes, we offer customised freight strategies to accommodate peak seasons, such as holiday sales, promotional events, and product launches. Our scalable logistics ensure retailers receive stock on time without disruption.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
              <h5>Can you transport fragile or high-value retail goods?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                <p>
                  Absolutely. We handle delicate and high-value retail items, including electronics, fashion, and luxury goods, with expert care. Our secure transport methods ensure product integrity from warehouse to storefront.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you offer multi-location retail distribution?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                <p>
                  Yes, we facilitate nationwide distribution, ensuring stock reaches multiple store locations across Australia. Whether you're supplying a single outlet or a franchise network, our logistics solutions are designed for efficiency and cost-effectiveness.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>How do you handle last-minute or urgent retail deliveries?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                <p>
                  We offer express and same-day delivery options for urgent retail shipments. Whether it’s last-minute inventory replenishment or a critical product launch, our team ensures your goods arrive promptly.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>Can you integrate with retail inventory management systems?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "300px" : "0px" }}>
                <p>
                  Yes, we work with retailers to streamline logistics with their existing inventory management systems. This integration helps track stock movements, improve order accuracy, and maintain optimal inventory levels across multiple locations.
                </p>
              </div>
            </div>
            {/* FAQ 7 - Quotes */}
            <div className={`faq-item ${openFAQ === 6 ? "active" : ""}`} onClick={() => toggleFAQ(6)}>
              <h5>How can I request a quote for retail freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 6 ? "300px" : "0px" }}>
                <p>
                  Requesting a quote is simple. Visit our <Link to="/contact-us">Contact Us</Link> page and provide details such as shipment size, pickup and delivery locations, and any special requirements. Our team will respond promptly with a tailored freight solution.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Retail;