import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Cleaning() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener to show/hide scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the 'goods and products' nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Cleaning Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers secure and efficient cleaning freight services for industrial cleaning equipment, chemicals, and supplies. Our eco-friendly and reliable logistics ensure timely delivery across Australia."
        />
        <meta
          name="keywords"
          content="Cleaning Freight Services, Cleaning Supplies Transport, Industrial Cleaning Equipment, Chemical Shipping, Eco-friendly Freight, Australia Cleaning Logistics, Secure Cleaning Freight, Melbourne Cleaning Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Cleaning Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Expert cleaning freight services for industrial cleaning equipment, chemicals, and supplies. Enjoy secure, eco-friendly transport with nationwide coverage."
        />
        <meta
          property="og:image"
          content="https://qfmlogistics.com.au/images/qfm-commodity-cleaning.jpg"
        />
        <meta
          property="og:url"
          content="https://qfmlogistics.com.au/cleaning-freight-services"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/cleaning-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/cleaning-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/cleaning-freight-services",
                "name": "Cleaning Freight Services | Logistics Solutions by QFM",
                "description": "Secure freight solutions for transporting industrial cleaning equipment, chemicals, and supplies with eco-friendly, reliable logistics across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/cleaning-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/cleaning-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Cleaning Freight Services",
                    "item": "https://qfmlogistics.com.au/cleaning-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/cleaning-freight-services#service",
                "name": "Cleaning Freight Services",
                "description": "We specialise in the secure transport of industrial cleaning equipment, chemicals, and supplies. Our eco-friendly logistics and advanced tracking ensure that your cleaning freight is delivered safely and on time across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-cleaning.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-cleaning-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your cleaning freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cleaning.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cleaning.jpg",
                "description": "Secure transport solutions for industrial cleaning equipment and chemicals across Australia.",
                "name": "Cleaning Freight Services - Reliable Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cleaning-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-cleaning-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-cleaning-two.jpg",
                "description": "Specialised logistics for transporting industrial cleaning supplies and equipment, ensuring efficient delivery across Australia.",
                "name": "Cleaning Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/cleaning-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/cleaning-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of cleaning equipment and chemicals do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport industrial scrubbers, high-powered vacuum systems, pressure washers, janitorial equipment, and a variety of cleaning chemicals. Whether you're shipping bulk sanitation products or specialised cleaning machinery, our logistics solutions ensure timely and secure delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you transport hazardous cleaning chemicals?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we transport hazardous and non-hazardous cleaning chemicals, including industrial solvents, disinfectants, and degreasers. We comply with all required safety and transport regulations, ensuring secure and legal freight solutions for chemical shipments."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for cleaning freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide cleaning freight services across Australia, covering major cities like Melbourne, Sydney, Brisbane, Perth, and Adelaide. Our network also extends to regional and remote areas, ensuring reliable distribution for manufacturers, suppliers, and businesses nationwide."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer express delivery for urgent cleaning supply shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide express freight solutions for urgent shipments of cleaning supplies and equipment. Whether you need fast delivery for restocking essential cleaning chemicals or transporting large machinery, our time-sensitive freight services ensure your cargo arrives on schedule."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for cleaning freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a custom quote, visit our <a href='https://qfmlogistics.com.au/contact-us'>Contact Us</a> page or call our team. Provide shipment details, including pickup and delivery locations, cargo type, weight, and any special handling needs, and we will offer a competitive and tailored pricing plan."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/cleaning-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Cleaning Freight Services</h1>
            {!isMobile && (
              <p>
                QFM offers efficient freight solutions for transporting industrial cleaning equipment, chemicals, and hygiene supplies across Australia. With a focus on secure transport and timely delivery, we help businesses maintain uninterrupted operations by ensuring their essential cleaning products reach their destinations safely and efficiently.
              </p>
            )}
            <p>
              Servicing both metropolitan and remote areas, we provide tailored freight solutions for cleaning businesses, suppliers, and industrial facilities. Our seamless logistics, real-time tracking, and flexible scheduling ensure smooth transport for bulk chemical shipments, machinery, and essential cleaning products.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-cleaning.jpg"
              alt="Secure transport for industrial cleaning equipment and chemicals across Australia"
              title="Cleaning Freight Services - Efficient and Eco-Friendly Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Innovative Cleaning Freight Solutions for a Sustainable Future</h5>
            )}
            <p>
              We provide secure and efficient transport for cleaning chemicals, industrial machinery, and hygiene products, ensuring safe handling and timely delivery.
              <Link to="/goods-and-products"> Discover our full logistics capabilities</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Cleaning Freight by QFM</h2>
            <ul>
              <li>
                <b>Reliable Transport for Cleaning Supplies:</b> We handle the secure delivery of industrial detergents, sanitation products, and cleaning machinery, ensuring safe and efficient freight solutions.
              </li>
              <li>
                <b>Tailored Logistics for Every Shipment:</b> Whether transporting bulk cleaning chemicals or specialised janitorial equipment, we customise freight solutions to meet your volume and schedule needs.
              </li>
              <li>
                <b>Comprehensive Australia-Wide Service:</b> Delivering across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas, supporting cleaning businesses, manufacturers, and distributors.
              </li>
              <li>
                <b>End-to-End Shipment Visibility:</b> Our real-time tracking system keeps you updated from pickup to delivery, ensuring reliable, on-time arrivals with complete transparency.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-cleaning-two.jpg"
              loading="lazy"
              alt="Efficient and secure transport for industrial cleaning equipment across Australia"
              title="Cleaning Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
  <div className="faq-text-content">
    <h2>FAQ</h2>

    {/* FAQ 1 */}
    <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
      <h5>What types of cleaning equipment and chemicals do you transport?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
        <p>
          We handle freight for a wide variety of cleaning products, including industrial scrubbers, high-powered vacuum systems, pressure washers, and sanitation equipment. We also transport bulk and packaged cleaning chemicals for commercial and industrial use, ensuring your supplies reach their destination efficiently.
        </p>
      </div>
    </div>

    {/* FAQ 2 */}
    <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
      <h5>Do you transport hazardous cleaning chemicals?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
        <p>
          Yes, we manage the transport of hazardous and non-hazardous cleaning chemicals, including disinfectants, solvents, and industrial degreasers. We comply with all necessary transport regulations, ensuring safe and legal freight solutions for your chemical shipments.
        </p>
      </div>
    </div>

    {/* FAQ 3 */}
    <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
      <h5>Which regions do you service for cleaning freight?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '270px' : '0px' }}>
        <p>
          We provide cleaning freight services across Australia, including major cities like Melbourne, Sydney, Brisbane, Perth, and Adelaide. Our network also extends to regional and remote areas, ensuring reliable distribution for manufacturers, suppliers, and cleaning businesses nationwide.
        </p>
      </div>
    </div>

    {/* FAQ 4 */}
    <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
      <h5>Do you offer express delivery for urgent cleaning supply shipments?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
        <p>
          Yes, we offer express freight solutions for urgent cleaning supply deliveries. Whether you're restocking essential cleaning products or require rapid transport for large equipment, we ensure time-sensitive shipments are prioritised and delivered on schedule.
        </p>
      </div>
    </div>

    {/* FAQ 5 */}
    <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
      <h5>How can I request a quote for cleaning freight services?</h5>
      <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
        <p>
          To get a tailored quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our support team. Provide shipment details, including pickup and delivery locations, cargo volume, and any handling requirements, and we’ll offer a competitive pricing plan suited to your needs.
        </p>
      </div>
    </div>
  </div>
</div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Cleaning;