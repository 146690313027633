import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/OurServices.css';
import { Link } from 'react-router-dom';


function Locations() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.05 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const locations = [
        { key: 'melbourne', path: '/melbourne-freight-services', label: 'Melbourne' },
        { key: 'sydney', path: '/melbourne-to-sydney-freight', label: 'Sydney' },
        { key: 'brisbane', path: '/melbourne-to-brisbane-freight', label: 'Brisbane' },
        { key: 'perth', path: '/melbourne-to-perth-freight', label: 'Perth' },
        { key: 'adelaide', path: '/melbourne-to-adelaide-freight', label: 'Adelaide' },
        { key: 'hobart', path: '/melbourne-to-hobart-freight', label: 'Hobart' },
        // { key: 'canberra', path: '/canberra-freight-services', label: 'Canberra' },
        // { key: 'darwin', path: '/darwin-freight-services', label: 'Darwin' },
        // { key: 'newcastle', path: '/newcastle-freight-services', label: 'Newcastle' },
        // { key: 'wollongong', path: '/wollongong-freight-services', label: 'Wollongong' },
        // { key: 'central-coast', path: '/central-coast-freight-services', label: 'Central Coast' },
        // { key: 'geelong', path: '/geelong-freight-services', label: 'Geelong' },
        // { key: 'ballarat', path: '/ballarat-freight-services', label: 'Ballarat' },
        // { key: 'bendigo', path: '/bendigo-freight-services', label: 'Bendigo' },
        // { key: 'gold-coast', path: '/gold-coast-freight-services', label: 'Gold Coast' },
        // { key: 'sunshine-coast', path: '/sunshine-coast-freight-services', label: 'Sunshine Coast' },
        // { key: 'townsville', path: '/townsville-freight-services', label: 'Townsville' },
        // { key: 'cairns', path: '/cairns-freight-services', label: 'Cairns' },
        // { key: 'toowoomba', path: '/toowoomba-freight-services', label: 'Toowoomba' },
        // { key: 'mackay', path: '/mackay-freight-services', label: 'Mackay' },
        // { key: 'rockhampton', path: '/rockhampton-freight-services', label: 'Rockhampton' },
        // { key: 'mandurah', path: '/mandurah-freight-services', label: 'Mandurah' },
        // { key: 'bunbury', path: '/bunbury-freight-services', label: 'Bunbury' },
        // { key: 'mount-gambier', path: '/mount-gambier-freight-services', label: 'Mount Gambier' },
        // { key: 'launceston', path: '/launceston-freight-services', label: 'Launceston' },
        // { key: 'devonport', path: '/devonport-freight-services', label: 'Devonport' },
        // { key: 'alice-springs', path: '/alice-springs-freight-services', label: 'Alice Springs' },
        // { key: 'coffs-harbour', path: '/coffs-harbour-freight-services', label: 'Coffs Harbour' },
        // { key: 'albury', path: '/albury-freight-services', label: 'Albury' },
        // { key: 'shepparton', path: '/shepparton-freight-services', label: 'Shepparton' },
    ];

    return (
        <div className="ourservices-main-content">
            <Helmet>
                <title>Locations we Service | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides freight transport services across Australia, including Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional hubs like Geelong, Newcastle, and Hobart. Reliable, efficient, and tailored logistics solutions for every industry." />
                <meta name="keywords" content="freight locations, Australia-wide transport, Melbourne freight, Sydney freight, Brisbane transport, Perth logistics, Adelaide deliveries, regional freight, express shipping, interstate transport, QFM Logistics" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU" />
                <meta name="geo.placename" content="Australia" />
                <meta name="geo.position" content="-25.2744;133.7751" />
                <meta property="og:title" content="Freight Locations We Service | Australia-Wide Transport by QFM" />
                <meta property="og:description" content="QFM provides expert freight transport solutions across Australia, covering major cities and regional hubs. Secure and timely logistics tailored to business needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-freight-locations.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/locations-we-service" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/locations-we-service" />
                {/* UDPATE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/locations-we-service#webpage",
                                "url": "https://qfmlogistics.com.au/locations-we-service",
                                "name": "Freight Locations We Service | Australia-Wide Transport by QFM",
                                "description": "QFM provides comprehensive freight solutions across Australia, connecting Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas like Geelong, Newcastle, and Hobart. We offer seamless logistics for industries requiring express, bulk, and interstate transport.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/locations-we-service#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/locations-we-service#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Locations We Service",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    }
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-freight-locations.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-freight-locations.jpg",
                                "description": "Freight services across Australia, covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and key regional hubs.",
                                "name": "QFM - Reliable Freight Solutions Across Australia",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/locations-we-service#service",
                                "name": "Australia-Wide Freight Services",
                                "description": "QFM offers tailored freight solutions for businesses across Australia, covering metro, regional, and remote locations. Our logistics services include road, rail, sea, and air freight options.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-freight-locations.jpg"
                            },
                        ]

                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Locations We Service</h1>
                        {!isMobile && (
                            <p>
                                QFM provides reliable freight solutions across Australia, covering major cities like Melbourne, Sydney, Brisbane, Perth, and Adelaide, as well as key regional hubs such as Newcastle, Geelong, Gold Coast, and Cairns. Our expertise spans a diverse range of industries, ensuring efficient transport of raw materials, finished products, and everything in between.
                            </p>
                        )}
                        <p>
                            Whether you're shipping heavy machinery in Darwin, construction materials in Toowoomba, or retail goods in Hobart, our logistics network ensures seamless transportation to and from all serviced locations. From industrial hubs like Rockhampton and Albury to fast-paced metro areas, QFM optimises freight movement with real-time tracking, strategic routing, and dedicated support.
                        </p>
                    </div>
                    <div className="ourservices-intro-section-image-container default-image-container-add">
                        {!isMobile && (
                            <img
                                src="images/qfm-freight-locations.jpg"
                                alt="Freight services across Australia, covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and key regional hubs."
                                title="QFM - Reliable Freight Solutions Across Australia"
                                loading="lazy"
                            />
                        )}
                        <div className="overlay-grid-locations">
                            {locations.map((location) => (
                                <Link key={location.key} className="grid-button-commodity-two" to={location.path}>
                                    <h4>{location.label}</h4>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className="default-quote appearing-effect">

                        <div className="default-quote-quote">
                            <Link to="/contact-us">
                                <h4>Get In Touch</h4>
                            </Link>
                        </div>

                        {!isMobile && (
                            <div className="default-quote-text-content">
                                <h5>Nationwide Freight Solutions for most Industries</h5>
                                <p>
                                    From Melbourne to Perth, Sydney to Darwin, and regional destinations like Geelong and Townsville, QFM delivers efficient freight solutions across Australia.
                                </p>
                            </div>
                        )}
                    </div>
                )}

                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content add-a-little">
                        {!isMobile && (
                            <p>
                                QFM provides freight services across major cities and regional hubs in Australia, ensuring efficient and tailored transport solutions to meet business demands. Whether you need local, interstate, or specialised transport, we have the logistics expertise to handle it all.
                            </p>
                        )}
                        <ul className='appearing-effect'>
                            <li>
                                <b>Melbourne:</b> As a key logistics hub, Melbourne freight services cater to retail, manufacturing, and industrial needs. We handle e-commerce fulfillment, palletised freight, and time-sensitive deliveries across Victoria. <Link to="/melbourne-freight-services">Melbourne Freight Services</Link>
                            </li>
                            <li>
                                <b>Sydney:</b> With one of the busiest ports in the country, Sydney's freight services support businesses in fast-moving consumer goods, construction, and e-commerce. Our network ensures seamless last-mile delivery and interstate shipping. <Link to="/melbourne-to-sydney-freight">Sydney Freight Services</Link>
                            </li>
                            <li>
                                <b>Brisbane:</b> As Queensland's logistics gateway, Brisbane freight services provide reliable supply chain solutions for agricultural, mining, and retail sectors. We specialise in bulk commodity shipments and time-critical deliveries. <Link to="/melbourne-to-brisbane-freight">Brisbane Freight Services</Link>
                            </li>
                            <li>
                                <b>Perth:</b> Supporting industries such as mining, oil, and gas, Perth freight services ensure the seamless movement of heavy machinery, hazardous goods, and oversized freight. We provide robust logistics solutions for Western Australia’s remote regions. <Link to="/melbourne-to-perth-freight">Perth Freight Services</Link>
                            </li>
                            <li>
                                <b>Adelaide:</b> Specialised freight solutions for South Australia’s wine, automotive, and industrial sectors. Our transport services include express logistics and dedicated freight solutions for high-value cargo. <Link to="/melbourne-to-adelaide-freight">Adelaide Freight Services</Link>
                            </li>
                            <li>
                                <b>Hobart:</b> Freight services for Tasmania's unique industries, including timber, seafood, and manufacturing. Our regional transport network ensures timely deliveries, supporting local businesses and supply chains. <Link to="/melbourne-to-hobart-freight">Hobart Freight Services</Link>
                            </li>
                        </ul>
                        {/* {isMobile && (
            <div className="default-section-content-left item-types-image appearing-effect">
                <div className="default-section-content-left-image-container">
                    <img src="images/qfm-freight-locations-one.jpg" loading="lazy" alt="Freight solutions in Melbourne and Sydney" title="Melbourne and Sydney Freight Services by QFM" />
                </div>
            </div>
        )}
         <ul className='appearing-effect'>
           
            <li>
                <b>Canberra:</b> Reliable transport solutions for government agencies, technology businesses, and retail supply chains. We specialise in secure document transport and high-value cargo handling. <Link to="/canberra-freight-services">Canberra Freight Services</Link>
            </li>
            <li>
                <b>Darwin:</b> Freight solutions for remote locations, defence supply chains, and industrial sectors in the Northern Territory. Our logistics expertise supports mining, construction, and indigenous community supplies. <Link to="/darwin-freight-services">Darwin Freight Services</Link>
            </li>
            <li>
                <b>Newcastle:</b> Essential freight services for the port city, supporting coal exports, steel production, and industrial manufacturing. Our transport network connects businesses to key interstate markets. <Link to="/newcastle-freight-services">Newcastle Freight Services</Link>
            </li>
            <li>
                <b>Wollongong:</b> Logistics support for the manufacturing, steel production, and construction industries in the Illawarra region. Our services include bulk freight, project logistics, and express delivery options. <Link to="/wollongong-freight-services">Wollongong Freight Services</Link>
            </li>        
        </ul>
        {isMobile && (
            <div className="default-section-content-left appearing-effect item-types-image">
                <div className="default-section-content-left-image-container">
                    <img src="images/qfm-freight-locations-two.jpg" loading="lazy" alt="Hobart and Canberra freight solutions" title="Hobart and Canberra Freight Services by QFM" />
                </div>
            </div>
        )}
        <ul className='appearing-effect'>
            <li>
                <b>Central Coast:</b> Freight services supporting retail, construction, and tourism industries in New South Wales. Our logistics solutions connect businesses to Sydney and regional markets. <Link to="/central-coast-freight-services">Central Coast Freight Services</Link>
            </li>
            <li>
                <b>Geelong:</b> Freight and logistics support for Victoria’s growing industrial, agricultural, and retail markets. We offer warehouse distribution, express delivery, and bulk transport solutions. <Link to="/geelong-freight-services">Geelong Freight Services</Link>
            </li>
            <li>
                <b>Ballarat:</b> Reliable transport services for Ballarat’s manufacturing, agricultural, and commercial sectors. We support regional supply chains with dedicated logistics solutions. <Link to="/ballarat-freight-services">Ballarat Freight Services</Link>
            </li>
            <li>
                <b>Bendigo:</b> Freight solutions tailored for Bendigo’s mining, retail, and industrial markets. Our transport network ensures seamless connectivity across Victoria. <Link to="/bendigo-freight-services">Bendigo Freight Services</Link>
            </li>
            <li>
                <b>Gold Coast:</b> Fast and efficient logistics for retail, tourism, and event industry supply chains. Our services include white glove delivery, fragile freight handling, and express shipping. <Link to="/gold-coast-freight-services">Gold Coast Freight Services</Link>
            </li>
        </ul> */}
                        {/* {isMobile && (
                            <div className="default-section-content-left appearing-effect item-types-image">
                                <div className="default-section-content-left-image-container">
                                    <img src="images/qfm-freight-locations-three.jpg" loading="lazy" alt="Freight services in Geelong and Gold Coast" title="Geelong and Gold Coast Freight Services by QFM" />
                                </div>
                            </div>
                        )}
                        <p>With QFM, every location benefits from secure, reliable, and efficient freight transport solutions tailored to meet your business needs. No matter where you are, we ensure your cargo moves smoothly and on time.</p> */}
                    </div>
                </div>

                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div >
    );
}

export default Locations;
