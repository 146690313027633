import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function GeneralFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>General Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management provides reliable general road and rail freight services across Melbourne and Australia. Our cost-effective solutions offer efficient and secure delivery for your logistics needs." />
                <meta name="keywords" content="General Freight Services, Road Freight Services, Rail Freight Logistics, Cost-Effective Freight Solutions, Longer Transit Freight, QFM Logistics, Quan Freight Management, Melbourne Freight Solutions, Reliable General Freight, Logistics Services Australia, Professional Freight Handling, Affordable Freight Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="General Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's general freight services. Based in Melbourne, we provide reliable and cost-effective road and rail freight solutions, tailored to your logistics needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-general-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/general-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/general-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/general-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/general-freight-services",
                                "name": "General Freight Services | Logistics Solutions by QFM",
                                "description": "Quan Freight Management provides reliable and efficient general freight services across Melbourne, Brisbane, Sydney, Perth, and Australia-wide. From small parcels to large consignments, our tailored logistics solutions ensure secure and timely delivery via road and rail.",
                                "publisher": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "inLanguage": "en-AU",
                                "breadcrumb": {
                                    "@id": "https://qfmlogistics.com.au/general-freight-services#breadcrumb"
                                }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/general-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "General Freight Services",
                                        "item": "https://qfmlogistics.com.au/general-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/general-freight-services#service",
                                "name": "General Freight Services",
                                "description": "Our general freight services offer reliable transportation solutions for goods of all sizes across Australia. With tailored road and rail options, we ensure timely and secure delivery to major cities and regional areas.",
                                "provider": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": [
                                    "https://qfmlogistics.com.au/images/qfm-services-general-freight.jpg",
                                    "https://qfmlogistics.com.au/images/qfm-services-general-freight-two.jpg"
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-general-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-general-freight.jpg",
                                "description": "Reliable and efficient general freight solutions connecting Melbourne to major cities and regional areas across Australia.",
                                "name": "General Freight Services – Reliable Logistics Solutions by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-general-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-general-freight-two.jpg",
                                "description": "Efficient and secure general freight solutions tailored to your logistics needs across Australia.",
                                "name": "General Freight Services – Trusted Logistics Solutions by QFM",
                                "author": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "license": {
                                    "@id": "https://qfmlogistics.com.au#license"
                                },
                                "creator": {
                                    "@id": "https://qfmlogistics.com.au#organization"
                                },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/general-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What goods can you transport with general freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our general freight services handle a wide variety of goods, including retail products, industrial materials, and packaged items. Contact us to confirm if your specific goods are suitable."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are general freight services available across Australia?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer nationwide general freight services, connecting Melbourne to major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as regional areas."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What are the advantages of rail freight for general shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Rail freight is a cost-effective and eco-friendly option for large-volume shipments. It provides reliable and efficient transportation over long distances."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I track my general freight shipment?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our advanced tracking systems provide real-time updates, ensuring you have full visibility of your shipment’s progress from pickup to delivery."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do I request a quote for general freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide details such as the type of goods, pickup and delivery locations, and any special requirements. Our team will respond with a tailored quote promptly."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">                        
                        <h1>General Freight Services</h1>
<p>
    QFM provides reliable and cost-effective general freight solutions for businesses of all sizes. Whether you're shipping small parcels, palletised goods, or oversized items, we ensure secure and timely deliveries across Australia. Our flexible freight options cater to various industries, offering a seamless transport experience.
</p>
{!isMobile && (
    <p>
        Backed by an experienced logistics team and advanced freight management systems, QFM ensures precise tracking, efficient handling, and optimised routing. With a strong focus on reliability and affordability, we help businesses streamline their supply chain while maintaining high service standards.  
    </p>
)}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-general-freight.jpg"
                            loading="lazy"
                            alt="Reliable general freight solutions for businesses of all sizes, ensuring secure and timely deliveries"
                            title="General Freight Services – Efficient and Cost-Effective Logistics by QFM"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Reliable and efficient general freight solutions designed to streamline your business logistics</h5>
                        )}
                        <p>
                            QFM's general freight services deliver tailored, cost-effective transport solutions, ensuring your goods are handled with care and precision. <Link to="/our-services">Learn more about our diverse logistics offerings</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne General Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Cost-Effective Solutions:</b> Achieve more with competitive pricing and freight services designed to maximise value for your business.
                            </li>
                            <li>
                                <b>Optimised Scheduling:</b> Depend on our carefully planned schedules to meet your deadlines efficiently and reliably.
                            </li>
                            <li>
                                <b>Versatile Fleet:</b> Choose from a diverse range of vehicles equipped to handle anything from small packages to large-scale freight.
                            </li>
                            <li>
                                <b>Advanced Tracking:</b> Stay informed with real-time tracking that provides complete transparency at every stage of delivery.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-general-freight-two.jpg"
                            loading="lazy"
                            alt="Reliable and cost-effective general freight solutions tailored to your business needs across Melbourne and Australia"
                            title="General Freight Services – Efficient and Dependable Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="general-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of goods can be transported with General Freight Services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our General Freight Services support a diverse range of goods, including retail products, industrial materials, non-perishable commodities, and packaged items. If you have specific requirements, our team is available to assess your shipment and ensure compatibility with our services.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Are your general freight services available Australia-wide?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we provide general freight services across Australia, connecting Melbourne to major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote areas. Both road and rail freight options are available to suit your delivery requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What are the benefits of using rail freight for general shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "270px" : "0px" }}
                            >
                                <p>
                                    Rail freight offers cost-effective solutions for transporting large volumes of goods over long distances. It’s also environmentally friendly, with lower emissions compared to other modes of transport. Our rail freight services provide dependable and efficient options for bulk shipments across key Australian cities.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How can I track my general freight shipment?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    All shipments are equipped with advanced tracking systems that provide real-time updates. Whether you're using road or rail freight, you’ll have complete visibility of your shipment’s progress, ensuring peace of mind and transparency throughout the delivery process.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>How do I request a quote for general freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "250px" : "0px" }}
                            >
                                <p>
                                    To get a quote, visit our <Link to="/contact-us">Contact Us</Link> page. Share details such as the type of goods, pickup and delivery locations, shipment weight, and any special handling needs. Our team will respond promptly with a tailored and competitive quote for your freight requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default GeneralFreight;