import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Container() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Container Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides specialised container freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective logistics solutions ensure the safe and efficient transport of containerised shipments with advanced tracking and expert handling." />
                <meta name="keywords" content="Container Freight Services, Quan Freight Management, QFM Logistics, Container Transport Australia, Melbourne Container Freight, Brisbane Container Logistics, Freight Solutions, Reliable Logistics, Road Freight, Sea Freight, Large Shipment Transport, Safe Freight Handling, Cost-Effective Freight Solutions, Advanced Freight Technology, Intermodal Freight" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Container Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's container freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport for containerised goods with advanced logistics solutions and expert care." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-container.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/container-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/container-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/container-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/container-freight-services",
                                "name": "Container Freight Services | Comprehensive Logistics Solutions by QFM",
                                "description": "QFM offers reliable container freight services for 20’ and 40’ containers from Melbourne to major Australian hubs, including Brisbane, Sydney, and Perth. Our solutions ensure secure and timely transport for your container shipments.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/container-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/container-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Container Freight Services",
                                        "item": "https://qfmlogistics.com.au/container-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/container-freight-services#service",
                                "name": "Container Freight Services",
                                "description": "Our container freight services are tailored to handle 20’ and 40’ containers. Based in Melbourne, we connect with Brisbane, Sydney, Perth, and other key locations to ensure secure and efficient transport.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-container.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-container.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-container.jpg",
                                "description": "Efficiently managing high-capacity container shipments, including 20’ and 40’ containers, across Australia",
                                "name": "Container Freight Services - Comprehensive Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-container-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-container-two.jpg",
                                "description": "Expert handling and secure transport of containerised shipments with advanced logistics solutions",
                                "name": "Container Freight Solutions - Secure and Efficient Transport by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/container-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which container sizes are available for transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We offer freight services for 20’ and 40’ containers, ensuring secure and efficient transport solutions tailored to your logistics needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/container-freight-services",
                                            "name": "Container Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What are the main transport lanes for container freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our main transport lanes include Melbourne to Brisbane, Sydney, and Perth. We ensure reliable services to these and other key locations across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/container-freight-services",
                                            "name": "Container Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you handle hazardous materials in containers?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we are equipped to manage hazardous materials in containers, adhering to strict safety and compliance regulations."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/container-freight-services",
                                            "name": "Container Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do I request a quote for container freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page, provide details like container size, weight, and pickup/delivery locations, and our team will quickly provide a tailored quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/container-freight-services",
                                            "name": "Container Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I track my container shipment?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer real-time tracking for all container shipments, ensuring visibility throughout the transport process."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/container-freight-services",
                                            "name": "Container Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Container Freight Services</h1>
                        {!isMobile && (
                            <p>
                                Managing containerised freight requires precision, reliability, and attention to detail. At QFM, we specialise in transporting both 20’ and 40’ shipping containers, offering customised solutions that prioritise secure handling, efficient loading, and timely delivery. Your cargo is our priority, and we ensure every shipment is treated with care and professionalism.
                            </p>
                        )}
                        <p>
                            Operating from Melbourne, our container freight network seamlessly connects to Australia’s major logistics hubs—including Sydney, Brisbane, Perth, and Adelaide—as well as regional destinations. Leveraging cutting-edge tracking systems and a team of logistics experts, we deliver end-to-end solutions that adapt to the unique demands of your container freight.
                        </p>
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-container.jpg"
                            alt="Reliable container freight services ensuring safe transport of both 20’ and 40’ containers across Australia"
                            title="Container Freight Services – Expert, Secure Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised container freight solutions built for efficiency and security</h5>
                        )}
                        <p>
                            Our container services from Melbourne offer seamless connectivity to major hubs, ensuring that both 20’ and 40’ container shipments are delivered on schedule. Discover how our precision-driven solutions can streamline your global supply chain. <Link to="/types-of-freight">See all the different freight types we offer</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Container Freight by QFM</h2>
                        <ul>
                            <li><b>Versatile Options:</b> Offering both 20’ and 40’ container solutions for varied shipment sizes and requirements.</li>
                            <li><b>Enhanced Security:</b> Advanced loading and secure handling processes to ensure your containerised cargo is protected throughout transit.</li>
                            <li><b>Optimised Efficiency:</b> Streamlined logistics that reduce transit times and lower costs while maximising load efficiency.</li>
                            <li><b>Nationwide Network:</b> Comprehensive coverage from Melbourne to major cities and regional areas across Australia.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-container-two.jpg"
                            loading="lazy"
                            alt="Efficiently managing high-capacity container shipments, including 20’ and 40’ containers, across Australia"
                            title="Melbourne Freight Handling – Comprehensive Container Solutions by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="container-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What container sizes do you handle?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    We specialise in handling standard 20-foot and 40-foot containers. Whether you're shipping a single container or managing multiple shipments, we ensure secure and efficient transport tailored to your needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Which destinations do you serve for container freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our container freight services operate from Melbourne to major logistics hubs, including Sydney, Brisbane, and Perth. We also provide reliable transport to regional and remote locations across Australia.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods can I transport in containers?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Our container freight services can accommodate a wide range of goods, including general cargo, industrial equipment, bulk materials, and palletised freight. We ensure that each container is handled with care and delivered securely.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How can I get a quote for container freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Getting a quote is quick and straightforward. Head over to our <Link to="/contact-us">Contact Us</Link> page and share key details like the container dimensions, weight, pickup and destination points, and any specific needs for handling or transit. Our team will carefully assess your requirements and provide a tailored solution to match.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Can you handle time-sensitive container shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer time-sensitive freight solutions to meet your deadlines. With streamlined operations and reliable scheduling, we ensure that your container shipments arrive on time, every time.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>Are there weight or capacity limits for containers?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    While standard 20-foot and 40-foot containers have weight and capacity guidelines, we can accommodate heavy or high-volume shipments with tailored logistics solutions. Reach out to discuss your specific requirements, and we’ll provide expert guidance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Container;