import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Perth() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/locations-we-service"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Melbourne to Hobart Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM offers secure and cost-effective freight services from Melbourne to Hobart, including road, sea, and air transport solutions for various industries." />
                <meta name="keywords" content="Melbourne to Hobart freight, interstate freight transport, QFM Logistics, road freight, sea freight, air freight, Hobart logistics, express freight, bulk transport, Tasmania freight carriers" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-TAS" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Melbourne to Hobart Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="QFM provides expert freight solutions from Melbourne to Hobart, ensuring secure and efficient transport for businesses with flexible delivery options." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/melbourne-to-hobart-freight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/melbourne-to-hobart-freight" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                           {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-hobart-freight#webpage",
                                "url": "https://qfmlogistics.com.au/melbourne-to-hobart-freight",
                                "name": "Melbourne to Hobart Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides premium freight services from Melbourne to Hobart, ensuring secure and timely deliveries via road, sea, and air transport.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/melbourne-to-hobart-freight#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-adelaide-freight#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Locations",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Melbourne to Hobart Freight Services",
                                        "item": "https://qfmlogistics.com.au/melbourne-to-hobart-freight"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-hobart-freight#service",
                                "name": "Melbourne to Hobart Freight Services",
                                "description": "Reliable freight solutions from Melbourne to Hobart, servicing businesses with secure and efficient transport via road, sea, and air freight.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart.jpg",
                                "description": "Reliable Melbourne to Hobart freight services by QFM, ensuring secure and timely interstate deliveries.",
                                "name": "Melbourne to Hobart Freight - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-hobart-two.jpg",
                                "description": "Melbourne to Hobart freight solutions tailored for businesses across various industries, offering reliable transport options.",
                                "name": "Melbourne to Hobart Freight Solutions - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-hobart-freight#faq",
                                "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/melbourne-to-hobart-freight",
                                    "url": "https://qfmlogistics.com.au/melbourne-to-hobart-freight",
                                    "name": "Melbourne to Hobart Freight Services | Logistics Solutions by QFM"
                                },
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What freight services does QFM offer from Melbourne to Hobart?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM provides road, sea, and air freight solutions from Melbourne to Hobart, ensuring businesses have flexible and cost-effective shipping options for all types of cargo."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How long does freight take from Melbourne to Hobart?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Standard road and sea freight transit times from Melbourne to Hobart typically range from 2 to 5 business days. Express freight options, including air transport, can ensure next-day or two-day delivery."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide freight services to regional Tasmania?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, QFM extends its Melbourne to Hobart freight services to regional Tasmania, including Launceston, Devonport, and Burnie, ensuring businesses across Tasmania receive reliable logistics support."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of freight can QFM transport to Hobart?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We handle palletised freight, perishable goods, retail stock, construction materials, and industrial equipment, providing secure and efficient transport tailored to various industries."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a freight quote for Melbourne to Hobart transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "You can request a freight quote by visiting our Contact Us page at https://qfmlogistics.com.au/contact-us. Provide details such as shipment size, weight, and urgency, and our team will provide a competitive and tailored quote."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Melbourne to Hobart Freight Services</h1>
                        {!isMobile && (
                            <p>
                                QFM provides seamless freight transport from Melbourne to Hobart, ensuring reliable, efficient, and cost-effective logistics solutions.
                                Whether your shipment requires road, sea, or air freight, we offer tailored options to suit your business needs.
                            </p>
                        )}
                        <p>
                            Our extensive network covers key freight routes into Hobart and extends to regional Tasmania, including Launceston, Devonport, and Burnie.
                            With real-time tracking, scheduled deliveries, and flexible transport solutions, we help businesses streamline their interstate freight operations with confidence.
                        </p>

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-melbourne-to-hobart.jpg"
                            alt="Reliable Melbourne to Hobart Freight Services by QFM"
                            title="Melbourne to Hobart Freight – Secure & Efficient Transport by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>


                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Reliable Freight Transport to Hobart</h5>
                        )}
                        <p>
                            Whether you need express freight, bulk transport, or tailored logistics solutions, our Melbourne to Hobart freight services ensure timely, secure, and cost-effective deliveries across Tasmania. With road, sea, and air freight options.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect adelaide-add">
                        <h2>Melbourne to Hobart Freight Services by QFM</h2>
                        <ul>
                            <li><b>Comprehensive Melbourne to Hobart Freight Solutions:</b> Reliable and cost-effective shipping for businesses across Tasmania, including retail, manufacturing, and e-commerce.</li>
                            <li><b>Flexible Road, Sea & Air Freight Options:</b> Secure transport with road freight, sea freight crossings, and express air freight for urgent deliveries.</li>
                            <li><b>Door-to-Door Delivery Across Tasmania:</b> Serving Hobart, Launceston, Devonport, Burnie, and other regional locations.</li>                           
                            <li><b>Advanced Freight Tracking & 24/7 Support:</b> Stay updated on your shipments with real-time tracking and dedicated customer assistance.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-melbourne-to-adelaide-two.jpg"
                            loading="lazy"
                            alt="Melbourne to Adelaide freight transport solutions by QFM"
                            title="Efficient and Secure Melbourne to Adelaide Freight by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/locations-we-service"><h4>View all Locations</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                
                  <div className="general-faq-section appearing-effect">
                      <div className="faq-text-content">
                          <h2>FAQ</h2>
      
                          {/* FAQ 1 */}
                          <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                              <h5>What freight options does QFM offer from Melbourne to Hobart?</h5>
                              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                  <p>
                                      QFM offers road, sea, and air freight services from Melbourne to Hobart, ensuring businesses have flexible and cost-effective transport solutions. 
                                      Whether you need express delivery, bulk shipping, or specialised logistics, we provide tailored freight services to meet your needs.
                                  </p>
                              </div>
                          </div>
      
                          {/* FAQ 2 */}
                          <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                              <h5>How long does freight take from Melbourne to Hobart?</h5>
                              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                                  <p>
                                      Transit times depend on the freight method chosen. Road and sea freight typically take 2-5 business days, while express air freight can 
                                      ensure next-day or two-day delivery for urgent shipments.
                                  </p>
                              </div>
                          </div>
      
                          {/* FAQ 3 */}
                          <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                              <h5>Do you provide freight services to regional Tasmania?</h5>
                              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                                  <p>
                                      Yes, we extend our freight services beyond Hobart to regional Tasmania, including Launceston, Devonport, Burnie, and surrounding areas. 
                                      Our extensive logistics network ensures efficient deliveries across the state.
                                  </p>
                              </div>
                          </div>
      
                          {/* FAQ 4 */}
                          <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                              <h5>What industries benefit from Melbourne to Hobart freight services?</h5>
                              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                  <p>
                                      We cater to a wide range of industries, including retail, e-commerce, construction, manufacturing, and agriculture. 
                                      Our secure and reliable freight options ensure timely deliveries tailored to each industry's unique requirements.
                                  </p>
                              </div>
                          </div>
      
                          {/* FAQ 5 */}
                          <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                              <h5>How can I request a freight quote for Melbourne to Hobart transport?</h5>
                              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                                  <p>
                                      You can request a freight quote by visiting our <Link to="/contact-us">Contact Us</Link> page. 
                                      Simply provide details such as shipment size, weight, and destination, and our team will offer a competitive and tailored quote.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Perth;
