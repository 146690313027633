import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function PetFreight() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button (desktop only)
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Pet Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised pet freight services for transporting pet products such as premium pet food, accessories, grooming supplies, and toys. Our secure and customised logistics ensure your shipments are delivered safely and on schedule across Australia."
        />
        <meta
          name="keywords"
          content="Pet Freight Services, Pet Products Transport, Pet Supplies Logistics, Secure Pet Freight, Australia Freight, Reliable Pet Shipping, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Pet Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure and customised pet freight services for transporting pet products including food, accessories, grooming supplies, and toys. Trust QFM to deliver your shipments safely and on schedule across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-pet.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/pet-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/pet-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/pet-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/pet-freight-services",
                "name": "Pet Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised pet freight services for transporting pet products such as premium pet food, accessories, grooming supplies, and toys. Our secure logistics ensure your shipments are delivered safely and on schedule across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/pet-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/pet-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Pet Freight Services",
                    "item": "https://qfmlogistics.com.au/pet-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/pet-freight-services#service",
                "name": "Pet Freight Services",
                "description": "Our pet freight services are specifically tailored for the secure transport of pet products. We handle premium pet food, accessories, grooming supplies, and toys with advanced packaging and careful logistics to ensure timely delivery.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-pet.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your pet freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-pet.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-pet.jpg",
                "description": "Secure transport for a diverse range of pet products, ensuring safe delivery from origin to destination.",
                "name": "Pet Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/pet-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg",
                "description": "Efficient freight solutions for pet supplies, including food, accessories, and grooming products, ensuring secure and timely transport across Australia.",
                "name": "Pet Freight Services - Reliable Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/pet-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-pet-two.jpg",
                "name": "Pet Freight Services - Professional Handling by Quan Freight Management",
                "description": "Reliable freight solutions for pet products, ensuring safe and timely delivery across Australia.",
                "author": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "license": {
                  "@type": "CreativeWork",
                  "url": "https://qfmlogistics.com.au/terms-and-conditions"
                },
                "creator": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": {
                  "@type": "WebPage",
                  "@id": "https://qfmlogistics.com.au/pet-freight-services"
                }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/pet-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of pet products do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of pet products, including premium pet food, grooming supplies, toys, bedding, and accessories. Our freight solutions cater to pet retailers, wholesalers, and distributors, ensuring efficient and timely deliveries across Australia."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you handle bulk shipments for pet stores and distributors?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we offer bulk freight solutions tailored to the needs of pet stores, wholesalers, and large-scale distributors. Whether you require full pallet shipments or scheduled deliveries, our logistics network ensures smooth and reliable transport of high-volume pet supplies."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which regions do you service for pet freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide nationwide coverage, delivering pet products across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive network ensures reliable and efficient transport solutions for businesses in both metropolitan and remote locations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you manage time-sensitive deliveries for pet products?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Absolutely. We understand the importance of timely deliveries, especially for perishable pet food and seasonal products. Our expedited freight services ensure prompt shipments, helping businesses maintain stock levels and meet customer demand without delays."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer tracking for pet product shipments?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, all shipments come with real-time tracking, providing complete visibility from pickup to final delivery. Our system allows businesses to monitor freight progress, estimated arrival times, and delivery confirmations for peace of mind."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for pet freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To get a quote, visit our Contact Us page or call our support team. Provide details such as the type of pet products, shipment volume, pickup and delivery locations, and any special requirements, and we’ll offer a competitive, tailored freight solution."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/pet-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Pet Freight Services</h1>

            <p>
              QFM delivers reliable freight solutions for pet products across Australia, ensuring timely and secure transport for retailers, distributors, and manufacturers. We handle shipments of all sizes, including pet food, accessories, grooming supplies, and toys, with streamlined logistics to support high-volume deliveries.
            </p>
            {!isMobile && (
              <p>
                Our nationwide transport network connects metro and regional areas, providing efficient freight solutions tailored to the pet industry. Whether supplying pet stores, veterinary clinics, or wholesalers, we optimise logistics to enhance supply chain efficiency and keep your business moving.
              </p>
            )}

          </div>
          <div className="ourstory-intro-section-image-container">
          <img
  src="images/qfm-commodity-pet-two.jpg"
  loading="lazy"
  alt="Efficient pet product freight services ensuring timely deliveries for retailers, distributors, and manufacturers across Australia"
  title="Pet Freight Services - Reliable Transport for Pet Supplies and Accessories by QFM"
/>
          </div>
        </div>



        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Dedicated Pet Freight Services for Retailers and Distributors</h5>
            )}
            <p>
              Our specialised logistics services ensure the secure and efficient transport of pet products, helping retailers and distributors meet demand with confidence. <Link to="/goods-and-products">Discover our full range of freight solutions</Link> designed for diverse industry needs.
            </p>
          </div>
        </div>


           {/* Main Content with Second Image */}
           <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
          <h2>Melbourne Pet Freight by QFM</h2>
<ul>
    <li>
        <b>Reliable Transport for Pet Products:</b> We ensure the safe and efficient delivery of pet food, accessories, grooming supplies, and bedding to retailers, distributors, and manufacturers.
    </li>
    <li>
        <b>Bulk & Retail Shipments:</b> Whether you need full pallet loads for wholesale distribution or smaller shipments for pet stores, our freight solutions are tailored to your needs.
    </li>
    <li>
        <b>Nationwide Network:</b> Covering Melbourne and major cities across Australia, we provide seamless logistics for pet supply businesses, ensuring timely restocking and distribution.
    </li>
    <li>
        <b>Secure & Efficient Handling:</b> With expert logistics coordination, we prevent shipment delays and damage, ensuring pet products arrive in perfect condition.
    </li>
</ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-pet-two.jpg"
              loading="lazy"
              alt="Reliable freight solutions for retail inventory, ensuring seamless supply chain operations across Australia"
              title="Retail Freight Services - Secure Transport for Store Inventory and Consumer Goods by QFM"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>


            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of pet products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of pet products, including premium pet food, grooming supplies, toys, bedding, and accessories. Our freight solutions cater to pet retailers, wholesalers, and distributors, ensuring efficient and timely deliveries across Australia.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you handle bulk shipments for pet stores and distributors?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Yes, we offer bulk freight solutions tailored to the needs of pet stores, wholesalers, and large-scale distributors. Whether you require full pallet shipments or scheduled deliveries, our logistics network ensures smooth and reliable transport of high-volume pet supplies.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Which regions do you service for pet freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  We provide nationwide coverage, delivering pet products across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas. Our extensive network ensures reliable and efficient transport solutions for businesses in both metropolitan and remote locations.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Can you manage time-sensitive deliveries for pet products?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Absolutely. We understand the importance of timely deliveries, especially for perishable pet food and seasonal products. Our expedited freight services ensure prompt shipments, helping businesses maintain stock levels and meet customer demand without delays.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>Do you offer tracking for pet product shipments?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  Yes, all shipments come with real-time tracking, providing complete visibility from pickup to final delivery. Our system allows businesses to monitor freight progress, estimated arrival times, and delivery confirmations for peace of mind.
                </p>
              </div>
            </div>


            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for pet freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To get a quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our support team. Provide details such as the type of pet products, shipment volume, pickup and delivery locations, and any special requirements, and we’ll offer a competitive, tailored freight solution.
                </p>
              </div>
            </div>

          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default PetFreight;