import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Furniture() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at the top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Furniture Freight Services | Logistics Solutions by QFM</title>
        <meta name="description" content="QFM offers specialised furniture freight services for the secure transport of flat-packed, backpacked, and boxed furniture. Our customised logistics ensure your products are handled with care and delivered on time across Australia." />
        <meta name="keywords" content="Furniture Freight Services, Flat-Packed Furniture Transport, Boxed Furniture Shipping, Office Furniture Logistics, Secure Furniture Freight, Australia Freight, QFM" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Furniture Freight Services | Logistics Solutions by QFM" />
        <meta property="og:description" content="Secure furniture freight services for flat-packed, backpacked, and boxed furniture. Trust QFM to deliver your shipments with customised logistics and care across Australia." />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-furniture.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/furniture-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/furniture-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/furniture-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/furniture-freight-services",
                "name": "Furniture Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised furniture freight services for the secure transport of flat-packed, backpacked, and boxed furniture. Our customised logistics ensure your products are handled with care and delivered on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/furniture-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/furniture-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Furniture Freight Services",
                    "item": "https://qfmlogistics.com.au/furniture-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/furniture-freight-services#service",
                "name": "Furniture Freight Services",
                "description": "Our furniture freight services focus on the transport of flat-packed, backpacked, and boxed furniture. We ensure secure handling and efficient delivery, tailored for manufacturers, distributors, and retailers.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-furniture.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-furniture-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your furniture freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-furniture.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-furniture.jpg",
                "description": "Secure transport for flat-packed and boxed furniture, ensuring optimal protection during transit.",
                "name": "Furniture Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/furniture-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-furniture-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-furniture-two.jpg",
                "description": "Professional freight services for pre-packaged furniture, ensuring secure and timely delivery.",
                "name": "Furniture Freight Services - Professional Handling by Quan Freight Management",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/furniture-freight-services#webpage" }
              },
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/furniture-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of furniture do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We handle the freight of flat-packed, boxed, and commercial furniture for retailers, suppliers, and distribution centers. Our logistics network ensures secure and efficient transport for various furniture types, including office desks, chairs, shelving units, and home furnishings."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How is your furniture freight service different from removals?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Unlike removalists who manage personal house moves, our service is dedicated to commercial furniture transport. We specialise in moving pre-packaged, bulk, and palletised furniture directly from manufacturers to retailers, ensuring efficiency, cost-effectiveness, and minimal handling."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safety of furniture during transit?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We implement industry-leading transport methods, including protective padding, secure tie-downs, and load stabilisation techniques to minimise movement. Our experienced team ensures that furniture shipments are handled with precision to prevent damage in transit, loading, and unloading."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer door-to-door service for furniture freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our door-to-door delivery service ensures that furniture is transported directly from the supplier or manufacturer to the retailer, warehouse, or business location. This reduces handling time, minimises risks, and ensures efficient delivery schedules."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for furniture freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To get a personalised quote, visit our Contact Us page at https://qfmlogistics.com.au/contact-us or call our team. Provide details such as shipment volume, pickup and delivery locations, furniture type, and any handling requirements. We will tailor a logistics solution that meets your needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/furniture-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Furniture Freight Services</h1>

            <p>
              Our furniture freight services are designed to transport flat-packed, and boxed furniture with precision and care. We specialise in handling pre-packaged office and residential furniture shipments, ensuring they remain secure throughout transit.
              With a comprehensive nationwide network, we provide tailored logistics solutions that guarantee safe handling and on-time delivery, supporting retailers, manufacturers, and distribution centres.
            </p>
            {!isMobile && (
              <p>
                We specialise in the secure transport of pre-packaged furniture, catering to businesses of all sizes.
                Our logistics network spans Australia, ensuring reliable delivery to **retailers, warehouses, and project sites**, maintaining supply chain efficiency and minimising delays.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-furniture.jpg"
              alt="Secure transport for flat-packed, backpacked, and boxed furniture across Australia"
              title="Furniture Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Customised Furniture Freight Solutions for Pre-Packaged Shipments</h5>
            )}
            <p>
              Our tailored logistics ensure that your furniture shipments are handled securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Furniture Freight by QFM</h2>
            <ul>
              <li>
                <b>Professional Furniture Transport:</b> From flat-packed items to assembled pieces, we ensure secure handling to minimise movement and prevent damage during transit.
              </li>
              <li>
                <b>Custom Freight Solutions:</b> Whether delivering to retail stores, office fit-outs, or commercial projects, we provide flexible transport tailored to your logistics requirements.
              </li>
              <li>
                <b>Australia-Wide Distribution:</b> Connecting Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with seamless and efficient furniture freight services.
              </li>
              <li>
                <b>End-to-End Tracking & Support:</b> Gain real-time shipment updates with full visibility from dispatch to delivery, ensuring a smooth and hassle-free experience.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-furniture-two.jpg"
              loading="lazy"
              alt="Professional freight services for pre-packaged furniture ensuring secure and timely delivery"
              title="Furniture Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of furniture do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We handle the freight of flat-packed, boxed, and commercial furniture for retailers, suppliers, and distribution centers. Our logistics network is designed to ensure secure and efficient transport for a variety of furniture types, including office desks, chairs, shelving units, and home furnishings.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How is your furniture freight service different from removals?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Unlike removalists who handle personal house moves, our service is focused on commercial furniture transport. We specialise in moving pre-packaged, bulk, and palletised furniture directly from manufacturers to retailers, ensuring efficiency, cost-effectiveness, and minimal handling.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>How do you ensure the safety of furniture during transit?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  We implement industry-leading transport methods, including protective padding, secure tie-downs, and load stabilisation techniques to minimise movement. Our experienced team ensures that furniture shipments are handled with precision to prevent damage, whether in transit or during loading and unloading.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you offer door-to-door service for furniture freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Yes, our door-to-door delivery service ensures that furniture is transported directly from the supplier or manufacturer to the retailer, warehouse, or business location. This reduces handling time, minimises risks, and ensures efficient delivery schedules.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for furniture freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To get a personalised quote, visit our <a href="https://qfmlogistics.com.au/contact-us">Contact Us</a> page or call our team. Provide details such as shipment volume, pickup and delivery locations, furniture type, and any handling requirements. We will tailor a logistics solution that meets your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Furniture;