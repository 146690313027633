import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';

  // Conversion factors to meters (for accurate m³ calculation)
  const conversionFactors = {
    mm: 0.001,
    cm: 0.01,
    inches: 0.0254,
    feet: 0.3048,
    meters: 1,
  };

function Cubic() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    const servicesLink = document.querySelector(
      'a[href="/resources"]'
    );
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ==========================
  // Cubic Weight Logic
  // ==========================

  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [measurement, setMeasurement] = useState('cm');
  const [itemType, setItemType] = useState('carton'); // Default: Carton
  const [conversionFactor, setConversionFactor] = useState('250'); // Default to 250
  const [cubicWeight, setCubicWeight] = useState('0.00');

  // Update conversion factor automatically when itemType changes
  useEffect(() => {
    if (itemType === 'carton') {
      setConversionFactor('250'); // ✅ Auto-set to 250 for cartons
    } else if (itemType === 'pallet') {
      setConversionFactor('333'); // ✅ Auto-set to 333 for pallets
    }
  }, [itemType]);

  // Calculate weights
  const calculateWeights = useCallback(() => {
    if (length && width && height) {
      const factor = conversionFactors[measurement] || 1;

      // Convert dimensions to meters
      const convertedLength = length * factor;
      const convertedWidth = width * factor;
      const convertedHeight = height * factor;

      // ✅ Calculate cubic volume (m³)
      const cubicMetersValue =
        convertedLength * convertedWidth * convertedHeight;

      // ✅ Calculate cubic weight (kg)
      const cubicWeightValue =
        cubicMetersValue * parseFloat(conversionFactor);

      setCubicWeight(cubicWeightValue.toFixed(2));
    } else {
      setCubicWeight('0.00');
    }
  }, [length, width, height, measurement, conversionFactor]);

  // Run the calculateWeights function when dependencies change
  useEffect(() => {
    calculateWeights();
  }, [calculateWeights]);


    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Cubic Weight Calculator | Logistics Solutions by QFM</title>
                <meta name="description" content="Calculate cubic weight and dead weight for freight shipments with QFM’s cubic weight calculator. Understand how cubic weight impacts shipping costs and optimise your logistics strategy." />
                <meta name="keywords" content="Cubic Weight Calculator, Freight Cost Estimation, Dead Weight, Cubic Volume, Freight Charges, Shipping Weight Calculation, Logistics Solutions, QFM, Freight Cost Australia, Freight Weight Calculation" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Cubic Weight Calculator | Logistics Solutions by QFM" />
                <meta property="og:description" content="Easily calculate cubic weight and dead weight to determine freight costs with QFM’s advanced cubic weight calculator. Ensure accurate shipping charges for your logistics needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-cubic-weight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/calculate-cubic-weight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/calculate-cubic-weight" />
                {/* NEED TO UPDATE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/calculate-cubic-weight#webpage",
                                "url": "https://qfmlogistics.com.au/calculate-cubic-weight",
                                "name": "Cubic Weight Calculator | Freight Pricing by QFM",
                                "description": "Easily calculate cubic weight for freight shipments using QFM’s cubic weight calculator. Determine accurate shipping costs for cartons, pallets, and oversized items across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/calculate-cubic-weight#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/calculate-cubic-weight#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Management Technology",
                                        "item": "https://qfmlogistics.com.au/resources"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Cubic Weight Calculator",
                                        "item": "https://qfmlogistics.com.au/calculate-cubic-weight"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/calculate-cubic-weight#calculator-service",
                                "name": "Cubic Weight Calculator",
                                "description": "Use QFM’s cubic weight calculator to determine accurate freight costs for cartons, pallets, and oversized goods across Australia. Ensure precise pricing for your shipments.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "serviceType": "Freight Cost Calculation",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-cubic-weight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-technology-cubic-weight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-technology-cubic-weight.jpg",
                                "description": "Cubic weight calculation for precise freight pricing and efficient transport solutions in Australia",
                                "name": "Cubic Weight Calculation - Maximise Freight Efficiency with QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                                "isPartOf": { "@id": "https://qfmlogistics.com.au/calculate-cubic-weight#webpage" }
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="ourstory-intro-section-text-content">
                        <h1>Calculate Cubic Weight</h1>

                        <p>
                            Dead weight refers to the actual weight of an item, while cubic weight—also known as volume weight or dimensional weight—takes into account the length, width, and height of the package. In the freight industry, cubic weight is a standard billing method used to determine shipping costs, ensuring that both the physical weight and the space occupied by a package are considered.
                        </p>
                        {!isMobile && (
                            <p>
                                Cubic weight includes all packaging materials, such as cartons, crates, or pallets, affecting freight charges. Carriers use it to price shipments, especially bulky yet lightweight items. Accurate cubic weight calculations help businesses optimise costs, improve logistics efficiency, and comply with carrier requirements.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-technology-cubic-weight.jpg"
                            alt="Cubic weight calculation for precise freight pricing and efficient transport solutions in Australia"
                            title="Cubic Weight Calculation - Maximise Freight Efficiency with QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect" style={isMobile ? { marginBottom: "10px" } : {}}>

                    <div className="default-quote-quote">
                        <Link to="/freight-management-technology">
                            <h4>QFM Technology</h4>
                        </Link>
                    </div>

                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Learn More About Our Freight Solutions</h5>
                            <p>
                                Get in touch to explore how our technology-driven approach can enhance your freight operations across Australia.
                            </p>
                        </div>
                    )}
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content">

                        <div className="calculator-container">
                            <h3 className="calculator-title">Cubic Weight Calculator</h3>

                            {/* Row 1: Item Type & Conversion Factor */}
                            <div className="calculator-row">
                                <div className="row-flex">
                                    <div className="input-group">
                                        <label className="calculator-label">Item</label>
                                        <select
                                            value={itemType}
                                            onChange={(e) => setItemType(e.target.value)}
                                            className="dropdown-input"
                                        >
                                            <option value="carton">Carton</option>
                                            <option value="pallet">Pallet</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div className="input-group">
                                        <label className="calculator-label">Conversion</label>
                                        <select
                                            value={conversionFactor}
                                            onChange={(e) => setConversionFactor(e.target.value)}
                                            className="dropdown-input"
                                        >
                                            <option value="250">250</option>
                                            <option value="333">333</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Measurement Dropdown - Show Above Dimensions on Mobile */}
                            {isMobile && (
                                <div className="calculator-row">
                                    <label className="calculator-label">Measurement</label>
                                    <select
                                        value={measurement}
                                        onChange={(e) => setMeasurement(e.target.value)}
                                        className="dropdown-input"
                                    >
                                        <option value="mm">Millimeters (mm)</option>
                                        <option value="cm">Centimeters (cm)</option>
                                        <option value="inches">Inches (in)</option>
                                        <option value="feet">Feet (ft)</option>
                                        <option value="meters">Meters (m)</option>
                                    </select>
                                </div>
                            )}

                            {/* Row 2: Parcel/Pallet Dimensions */}
                            <div className="calculator-row">
                                <label className="calculator-label">Dimensions</label>
                                <div className="dimension-inputs">
                                    <input
                                        type="number"
                                        placeholder="Length"
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                        className="dimension-input"
                                    />
                                    <input
                                        type="number"
                                        placeholder="Width"
                                        value={width}
                                        onChange={(e) => setWidth(e.target.value)}
                                        className="dimension-input"
                                    />
                                    <input
                                        type="number"
                                        placeholder="Height"
                                        value={height}
                                        onChange={(e) => setHeight(e.target.value)}
                                        className="dimension-input"
                                    />
                                </div>
                            </div>

                            {/* Measurement Dropdown - Show Below Dimensions on Desktop */}

                            <div className="calculator-row row-flex">
                                {!isMobile && (
                                    <div className="input-group">
                                        <label className="calculator-label">Measurement</label>
                                        <select
                                            value={measurement}
                                            onChange={(e) => setMeasurement(e.target.value)}
                                            className="dropdown-input"
                                        >
                                            <option value="mm">Millimeters (mm)</option>
                                            <option value="cm">Centimeters (cm)</option>
                                            <option value="inches">Inches (in)</option>
                                            <option value="feet">Feet (ft)</option>
                                            <option value="meters">Meters (m)</option>
                                        </select>
                                    </div>
                                )}
                                {/* Cubic Volume Output */}
                                <div className="input-group">
                                    <label className="calculator-label">Cubic Volume (kg)</label>
                                    <input
                                        type="text"
                                        value={cubicWeight}
                                        readOnly
                                        className="cubic-weight-output"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Cubic;