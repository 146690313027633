import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Machinery() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const servicesLink = document.querySelector('a[href="/goods-and-products"]');
    if (servicesLink) {
      servicesLink.classList.add('active');
    }
    return () => {
      if (servicesLink) {
        servicesLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Machinery Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised machinery freight services for transporting heavy industrial equipment and specialised machinery. Our secure, customised logistics solutions ensure safe and timely delivery across Australia."
        />
        <meta
          name="keywords"
          content="Machinery Freight Services, Heavy Machinery Transport, Industrial Equipment Shipping, Machinery Logistics, Australia Freight, Secure Machinery Transport, Melbourne Machinery Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Machinery Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Our machinery freight services are designed to safely transport heavy industrial equipment and specialised machinery. Trust QFM for secure, customised logistics solutions across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-machinery.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/machinery-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/machinery-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/machinery-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/machinery-freight-services",
                "name": "Machinery Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised freight services for transporting heavy industrial equipment and specialised machinery. Our secure, customised logistics ensure safe and timely delivery across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/machinery-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/machinery-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Machinery Freight Services",
                    "item": "https://qfmlogistics.com.au/machinery-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/machinery-freight-services#service",
                "name": "Machinery Freight Services",
                "description": "Our machinery freight services are designed to safely transport heavy industrial equipment and specialised machinery. Whether it's construction machinery or manufacturing equipment, our secure, customised logistics solutions ensure your assets are delivered safely and on schedule.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-machinery.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-machinery-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your machinery freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-machinery.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-machinery.jpg",
                "description": "Secure transport solutions for heavy industrial equipment and specialised machinery across Australia.",
                "name": "Machinery Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/machinery-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-machinery-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-machinery-two.jpg",
                "description": "Specialised logistics for heavy machinery ensuring secure and efficient delivery across Australia.",
                "name": "Machinery Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/machinery-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/machinery-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of machinery do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of industrial and heavy machinery, including construction equipment, manufacturing systems, mining machinery, and factory components. Our freight services are designed to handle oversized, high-value, and sensitive machinery with precision."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure the safe transport of heavy machinery?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our team uses industry-approved loading equipment, reinforced tie-down systems, and expert handling techniques to secure heavy machinery during transit. We also provide real-time tracking, giving you full visibility of your shipment from pickup to delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer customised freight solutions for specialised machinery?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide tailored freight solutions for specialised machinery, including secure transport for high-tech manufacturing equipment, delicate calibration instruments, and heavy-duty industrial machines. Our logistics team works closely with you to create a custom transport plan that meets your unique needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you handle bulk shipments of industrial machinery?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we are equipped to manage high-volume machinery shipments, whether for a large infrastructure project, manufacturing facility, or industrial warehouse. Our scalable freight solutions accommodate both small and bulk consignments while maintaining efficiency and security."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide door-to-door service for machinery freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our door-to-door freight service covers the entire shipping process, from pickup at your facility to delivery at the final destination. This service minimises handling, reduces transit time, and ensures a smooth and reliable shipping experience."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for machinery freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, visit our Contact Us page or call our support team. Provide details such as pickup and delivery locations, machinery type, shipment volume, and any special handling requirements. Our team will assess your request and provide a competitive freight solution tailored to your needs."
                    }
                  }
                ],
                "isPartOf": {
                  "@id": "https://qfmlogistics.com.au/machinery-freight-services#webpage"
                }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Machinery Freight Services</h1>

            <p>
              Whether you are transporting heavy industrial machinery or specialised equipment, our secure freight solutions are designed to handle the unique challenges of moving large, high-value assets. We utilise precision loading techniques and reinforced securing methods to safeguard your equipment during transit, minimising risk and ensuring reliability.
            </p>
            {!isMobile && (
              <p>
                We specialise in the secure transport of heavy industrial machinery and specialised equipment, ensuring each shipment is managed with expert care.

                Our network spans Australia, offering reliable delivery from urban centres to remote regions, catering to industries such as manufacturing, construction, and mining.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-machinery.jpg"
              alt="Secure transport for heavy industrial machinery across Australia"
              title="Machinery Freight Services - Secure and Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Robust Machinery Freight Solutions Tailored to Your Needs</h5>
            )}
            <p>
              Our customised freight services ensure that your heavy machinery and specialised equipment are transported securely and efficiently. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Machinery Freight by QFM</h2>
            <ul>
              <li>
                <b>Specialist Handling for Heavy Machinery:</b> Secure transport solutions for industrial equipment, construction machinery, and agricultural vehicles with expert load management.
              </li>
              <li>
                <b>Reliable Freight for Oversized & High-Value Equipment:</b> Ensuring safe and efficient delivery of large-scale machinery using heavy-duty transport and precision logistics.
              </li>
              <li>
                <b>Nationwide Transport Network:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas with seamless logistics for factories, job sites, and workshops.
              </li>
              <li>
                <b>Custom Freight Solutions:</b> Offering tailored logistics, including multi-drop deliveries, scheduled shipments, and express freight for urgent machinery transport.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-machinery-two.jpg"
              loading="lazy"
              alt="Professional freight for heavy machinery ensuring secure and timely delivery"
              title="Machinery Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="express-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of machinery do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of industrial and heavy machinery, including construction equipment, manufacturing systems, mining machinery, and factory components. Our freight services are designed to handle oversized, high-value, and sensitive machinery with precision.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>How do you ensure the safe transport of heavy machinery?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  Our team uses industry-approved loading equipment, reinforced tie-down systems, and expert handling techniques to secure heavy machinery during transit. We also provide real-time tracking, giving you full visibility of your shipment from pickup to delivery.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you offer customised freight solutions for specialised machinery?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, we provide tailored freight solutions for specialised machinery, including secure transport for high-tech manufacturing equipment, delicate calibration instruments, and heavy-duty industrial machines. Our logistics team works closely with you to create a custom transport plan that meets your unique needs.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Can you handle bulk shipments of industrial machinery?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Yes, we are equipped to manage high-volume machinery shipments, whether for a large infrastructure project, manufacturing facility, or industrial warehouse. Our scalable freight solutions accommodate both small and bulk consignments while maintaining efficiency and security.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>Do you provide door-to-door service for machinery freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  Yes, our door-to-door freight service covers the entire shipping process, from pickup at your facility to delivery at the final destination. This service minimises handling, reduces transit time, and ensures a smooth and reliable shipping experience.
                </p>
              </div>
            </div>

            {/* FAQ 6 */}
            <div className={`faq-item ${openFAQ === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a quote for machinery freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? '300px' : '0px' }}>
                <p>
                  To request a quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our support team. Provide details such as pickup and delivery locations, machinery type, shipment volume, and any special handling requirements. Our team will assess your request and provide a competitive freight solution tailored to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Machinery;