// import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

import Home from './components/Home.js';
import OurServices from './components/OurServices.js';
import OurStory from './components/OurStory';
import Technology from './components/Technology.js';
import GetInTouch from './components/GetInTouch.js';
import DevelopmentMessage from './components/DevelopmentMessage';
import TermsandConditions from './components/TermsandConditions.js';
import ItemTypes from './components/ItemTypes.js';
import PalletServices from './components/ItemPallet.js';
import CartonServices from './components/ItemCarton.js';
import CrateServices from './components/ItemCrate.js';
import SatchelServices from './components/ItemSatchel.js';
import EnvelopeServices from './components/ItemEnvelope.js';
import DrumServices from './components/ItemDrum.js';
import IBCServices from './components/ItemIBC.js';
import PanelServices from './components/ItemPanel.js';
import StillageServices from './components/ItemStillage.js';
import SemiServices from './components/ItemSemi.js';
import BdoubleServices from './components/ItemBdouble.js';
import ContainerServices from './components/ItemContainer.js';
import ServicesRoad from './components/ServicesRoad.js';
import ServicesRail from './components/ServicesRail.js';
import ServicesAir from './components/ServicesAir.js';
import ServicesLocal from './components/ServicesLocal.js';
import ServicesProject from './components/ServicesProject.js';
import ServicesDG from './components/ServicesDG.js';
import ServicesGeneral from './components/ServicesGeneral.js';
import ServicesExpress from './components/ServicesExpress.js';
import TrackShipment from './components/TrackShipment.js';
import Commodity from './components/Commodity.js';
import Audio from './components/CommodityAudio.js';
import Automotive from './components/CommodityAutomotive.js';
import Books from './components/CommodityBooks.js';
import Bricks from './components/CommodityBricks.js';
import Camping from './components/CommodityCamping.js';
import Caravan from './components/CommodityCaravan.js';
import Cardboard from './components/CommodityCardboard.js';
import Cement from './components/CommodityCement.js';
import Cleaning from './components/CommodityCleaning.js';
import Construction from './components/CommodityConstruction.js';
import Electronics from './components/CommodityElectronics.js';
import Energy from './components/CommodityEnergy.js';
import Fitness from './components/CommodityFitness.js';
import Furniture from './components/CommodityFurniture.js';
import Garden from './components/CommodityGarden.js';
import Industrial from './components/CommodityIndustrial.js';
import Machinery from './components/CommodityMachinery.js';
import Medical from './components/CommodityMedical.js';
import Metal from './components/CommodityMetal.js';
import Office from './components/CommodityOffice.js';
import Packaging from './components/CommodityPackaging.js';
import Party from './components/CommodityParty.js';
import Pet from './components/CommodityPet.js';
import Retail from './components/CommodityRetail.js';
import Signage from './components/CommoditySignage.js';
import Storage from './components/CommodityStorage.js';
import Textiles from './components/CommodityTextiles.js';
import Timber from './components/CommodityTimber.js';
import Firearms from './components/CommodityFirearms.js';
import Chemical from './components/CommodityChemical.js';
import Integration from './components/TechnologyIntegration.js';
import CubicWeight from './components/TechnologyCubic.js';
import FreightGuide from './components/FreightGuide.js';
import Resources from './components/Resources.js';
import ZoneGuide from './components/ZoneGuide.js';
import FreightLocations from './components/Locations.js';
import LocationMelbourne from './components/LocationMelbourne.js';
import LocationSydney from './components/LocationSydney.js';
import LocationBrisbane from './components/LocationBrisbane.js';
import LocationPerth from './components/LocationPerth.js';
import LocationAdelaide from './components/LocationAdelaide.js';
import LocationHobart from './components/LocationHobart.js';

function App() {
  const router = createBrowserRouter(
    [
      { path: '/', element: <Home /> },
      { path: '/our-story', element: <OurStory /> },
      { path: '/our-services', element: <OurServices /> },
      { path: '/freight-management-technology', element: <Technology /> },
      { path: '/contact-us', element: <GetInTouch /> },
      { path: '/developmentmessage', element: <DevelopmentMessage /> },
      { path: '/terms-and-conditions', element: <TermsandConditions /> },
      { path: '/types-of-freight', element: <ItemTypes /> },
      { path: '/pallet-freight-services', element: <PalletServices /> },
      { path: '/carton-freight-services', element: <CartonServices /> },
      { path: '/crate-freight-services', element: <CrateServices /> },
      { path: '/satchel-freight-services', element: <SatchelServices /> },
      { path: '/envelope-freight-services', element: <EnvelopeServices /> },
      { path: '/drum-freight-services', element: <DrumServices /> },
      { path: '/ibc-freight-services', element: <IBCServices /> },
      { path: '/panel-freight-services', element: <PanelServices /> },
      { path: '/stillage-freight-services', element: <StillageServices /> },
      { path: '/semi-freight-services', element: <SemiServices /> },
      { path: '/bdouble-freight-services', element: <BdoubleServices /> },
      { path: '/container-freight-services', element: <ContainerServices /> },
      { path: '/road-freight-services', element: <ServicesRoad /> },
      { path: '/rail-freight-services', element: <ServicesRail /> },
      { path: '/air-freight-services', element: <ServicesAir /> },
      { path: '/local-freight-services', element: <ServicesLocal /> },
      { path: '/project-freight-services', element: <ServicesProject /> },
      { path: '/dangerous-goods-freight-services', element: <ServicesDG /> },
      { path: '/general-freight-services', element: <ServicesGeneral /> },
      { path: '/express-freight-services', element: <ServicesExpress /> },
      { path: '/track-shipment', element: <TrackShipment /> },
      { path: '/goods-and-products', element: <Commodity /> },
      { path: '/audio-freight-services', element: <Audio /> },
      { path: '/automotive-freight-services', element: <Automotive /> },
      { path: '/books-freight-services', element: <Books /> },
      { path: '/bricks-freight-services', element: <Bricks /> },
      { path: '/camping-freight-services', element: <Camping /> },
      { path: '/caravan-freight-services', element: <Caravan /> },
      { path: '/cardboard-freight-services', element: <Cardboard /> },
      { path: '/cement-freight-services', element: <Cement /> },
      { path: '/cleaning-freight-services', element: <Cleaning /> },
      { path: '/construction-freight-services', element: <Construction /> },
      { path: '/electronics-freight-services', element: <Electronics /> },
      { path: '/energy-freight-services', element: <Energy /> },
      { path: '/fitness-freight-services', element: <Fitness /> },
      { path: '/furniture-freight-services', element: <Furniture /> },
      { path: '/garden-freight-services', element: <Garden /> },
      { path: '/industrial-freight-services', element: <Industrial /> },
      { path: '/machinery-freight-services', element: <Machinery /> },
      { path: '/medical-freight-services', element: <Medical /> },
      { path: '/metal-freight-services', element: <Metal /> },
      { path: '/office-freight-services', element: <Office /> },
      { path: '/packaging-freight-services', element: <Packaging /> },
      { path: '/party-freight-services', element: <Party /> },
      { path: '/pet-freight-services', element: <Pet /> },
      { path: '/retail-freight-services', element: <Retail /> },
      { path: '/signage-freight-services', element: <Signage /> },
      { path: '/storage-freight-services', element: <Storage /> },
      { path: '/textiles-freight-services', element: <Textiles /> },
      { path: '/timber-freight-services', element: <Timber /> },
      { path: '/firearms-freight-services', element: <Firearms /> },
      { path: '/chemical-freight-services', element: <Chemical /> },
      { path: '/integrated-freight-solutions', element: <Integration /> },
      { path: '/calculate-cubic-weight', element: <CubicWeight /> },
      { path: '/freight-guide', element: <FreightGuide /> },
      { path: '/resources', element: <Resources /> },
      { path: '/zone-guide', element: <ZoneGuide /> },
      { path: '/locations-we-service', element: <FreightLocations /> },
      { path: '/melbourne-freight-services', element: <LocationMelbourne /> },
      { path: '/melbourne-to-sydney-freight', element: <LocationSydney /> },
      { path: '/melbourne-to-brisbane-freight', element: <LocationBrisbane /> },
      { path: '/melbourne-to-perth-freight', element: <LocationPerth /> },
      { path: '/melbourne-to-adelaide-freight', element: <LocationAdelaide /> },
      { path: '/melbourne-to-hobart-freight', element: <LocationHobart /> },
    ],
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      },
    }
  );
  return (
    <HelmetProvider>
      <RouterProvider router={router}>
        <div className="App">        
          <div className="App-content">
          </div>
        </div>
        
      </RouterProvider>
    </HelmetProvider>    
  );
}

export default App;