import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Timber() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button (desktop only)
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Timber Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM offers specialised timber freight services for transporting wood products and timber shipments. Our secure logistics ensure that your timber loads are handled with care and delivered on time across Australia."
        />
        <meta
          name="keywords"
          content="Timber Freight Services, Wood Transport, Timber Shipping, Engineered Wood Logistics, Secure Timber Freight, Australia Freight, Reliable Timber Transport, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Timber Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure timber freight services for transporting wood products, raw logs, and engineered wood materials. Trust QFM for reliable, customised logistics solutions across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-timber.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/timber-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/timber-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/timber-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/timber-freight-services",
                "name": "Timber Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised timber freight services for transporting wood products, raw logs, and engineered wood materials. Our secure logistics ensure that your timber loads are handled with care and delivered on time across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/timber-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/timber-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Timber Freight Services",
                    "item": "https://qfmlogistics.com.au/timber-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/timber-freight-services#service",
                "name": "Timber Freight Services",
                "description": "Our timber freight services are engineered to transport wood products, raw logs, and processed timber with specialised packaging and secure handling. We tailor our logistics solutions to preserve the quality of your timber shipments.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-timber.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-timber-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your timber freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-timber.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-timber.jpg",
                "description": "Robust transport for wood products and timber shipments, ensuring quality preservation during transit.",
                "name": "Timber Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/timber-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-timber-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-timber-two.jpg",
                "description": "Specialised handling for timber shipments, ensuring secure loading and protection throughout transit.",
                "name": "Timber Freight - Professional Handling by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/timber-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/timber-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of timber products do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of timber products, including raw logs, processed lumber, plywood, MDF, and engineered wood. Whether for construction, furniture manufacturing, or wholesale distribution, our freight solutions cater to various industry requirements."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What industries do you service for timber freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We work with a diverse range of industries, including construction, carpentry, furniture manufacturing, and wholesale timber distribution. Our logistics services are designed to accommodate both small-scale suppliers and large commercial operations, ensuring consistent and efficient delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer door-to-door service for timber freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our door-to-door service simplifies logistics by collecting shipments from your supplier or warehouse and delivering them directly to your required location. This reduces handling, minimises delays, and ensures efficient transport for all timber products."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you handle oversized or custom timber freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we specialise in transporting oversized and custom-cut timber. Whether you need long beams, prefabricated panels, or uniquely shaped wood products, we have the right freight solutions to ensure safe and efficient delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for timber freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Getting a quote is easy. Visit our Contact Us page or call our team with details about your shipment, including timber type, dimensions, weight, pickup and delivery locations, and any specific handling requirements. We’ll provide a customised freight solution tailored to your needs."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/timber-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Timber Freight Services</h1>
            {!isMobile && (
              <p>
                Our timber freight services are designed to ensure the safe and efficient transport of wood products across Australia. Whether you need to move raw logs, processed lumber, or engineered wood materials, we provide expert handling and secure loading solutions to protect your shipments throughout transit.
              </p>
            )}
            <p>
              Backed by a robust nationwide logistics network, we provide timely and dependable deliveries to construction sites, manufacturing facilities, and distribution hubs.
              We specialise in the secure transport of timber products, using industry-leading handling techniques to minimise risks during transit.
              Our extensive network spans Australia, ensuring seamless delivery from urban centres to remote locations.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-timber.jpg"
              alt="Secure transport for wood products and timber shipments across Australia"
              title="Timber Freight Services - Reliable and Secure Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Tailored Timber Freight Solutions for Your Wood Products</h5>
            )}
            <p>
              Our customised logistics ensure that your timber shipments are handled securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive logistics support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Timber Freight by QFM</h2>
            <ul>
              <li>
                <b>Reliable Transport for Timber Products:</b> We handle the safe and efficient freight of raw logs, processed lumber, plywood, and engineered wood, ensuring secure delivery for construction and manufacturing needs.
              </li>
              <li>
                <b>Tailored Freight Solutions:</b> Whether transporting bulk shipments for large-scale projects or smaller consignments for wholesalers, we provide flexible logistics to meet your supply chain demands.
              </li>
              <li>
                <b>Nationwide & Regional Coverage:</b> Servicing Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas, we ensure smooth timber freight distribution across Australia.
              </li>
              <li>
                <b>Expert Handling & Secure Loading:</b> Our freight solutions include reinforced strapping, protective load management, and expert coordination to minimise movement and prevent damage in transit.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-timber-two.jpg"
              loading="lazy"
              alt="Professional freight for timber shipments ensuring secure and timely delivery"
              title="Timber Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of timber products do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of timber products, including raw logs, processed lumber, plywood, MDF, and engineered wood. Whether for construction, furniture manufacturing, or wholesale distribution, our freight solutions cater to various industry requirements.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>What industries do you service for timber freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '300px' : '0px' }}>
                <p>
                  We work with a diverse range of industries, including construction, carpentry, furniture manufacturing, and wholesale timber distribution. Our logistics services are designed to accommodate both small-scale suppliers and large commercial operations, ensuring consistent and efficient delivery.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you offer door-to-door service for timber freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, our door-to-door service simplifies logistics by collecting shipments from your supplier or warehouse and delivering them directly to your required location. This reduces handling, minimises delays, and ensures efficient transport for all timber products.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Can you handle oversized or custom timber freight?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Yes, we specialise in transporting oversized and custom-cut timber. Whether you need long beams, prefabricated panels, or uniquely shaped wood products, we have the right freight solutions to ensure safe and efficient delivery.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for timber freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  Getting a quote is easy. Visit our <Link to="/contact-us">Contact Us</Link> page or call our team with details about your shipment, including timber type, dimensions, weight, pickup and delivery locations, and any specific handling requirements. We’ll provide a customised freight solution tailored to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Timber;