import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function LocalFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Local Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management offers reliable and efficient local freight services in Melbourne. From same-day couriers to taxi trucks and permanent hire, we provide tailored logistics solutions to meet your delivery needs." />
                <meta name="keywords" content="Local Freight Services, Quan Freight Management, QFM Logistics, Melbourne Local Freight, Same-Day Couriers, Taxi Trucks Melbourne, Permanent Hire Logistics, Freight Solutions, Reliable Local Logistics, Melbourne Courier Services, Flexible Freight Options, Cost-Effective Local Transport, Efficient Logistics Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Local Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's local freight services in Melbourne. Offering same-day couriers, taxi trucks, and permanent hire options, we ensure efficient and dependable logistics solutions tailored to your needs." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-local-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/local-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/local-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/local-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/local-freight-services",
                                "name": "Local Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in Melbourne-based local freight services, offering same-day couriers, taxi trucks, and permanent hire solutions tailored to meet your transport needs.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/local-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/local-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Local Freight Services",
                                        "item": "https://qfmlogistics.com.au/local-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/local-freight-services#service",
                                "name": "Local Freight Services",
                                "description": "We offer same-day couriers, taxi trucks, and permanent hire solutions exclusively within Melbourne, tailored to meet your logistics requirements.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Melbourne, Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-services-local-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-local-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-local-freight.jpg",
                                "description": "Reliable local freight services including same-day couriers, taxi trucks, and permanent hire solutions in Melbourne.",
                                "name": "Local Freight Services – Efficient Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-local-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-local-freight-two.jpg",
                                "description": "Comprehensive local freight services featuring a diverse fleet for all delivery requirements in Melbourne.",
                                "name": "Local Freight Services – Trusted Logistics Partner in Melbourne",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/local-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What areas do your local freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We provide local freight services exclusively within Melbourne, covering the city and surrounding suburbs to ensure reliable and efficient deliveries."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer same-day courier services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we specialise in same-day courier services for urgent deliveries. Depending on your needs, we can deliver within a few hours or by the end of the day."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What vehicle types are available for local freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our fleet includes courier vans, taxi trucks, and hydraulic tailgate-equipped trucks to accommodate a variety of shipment sizes and requirements."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I hire a truck on a permanent basis?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we provide permanent truck hire solutions for businesses needing dedicated transport for daily or regular operations."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How quickly can you arrange a taxi truck service?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We can arrange a taxi truck service within a few hours, depending on your location and requirements. Contact us to schedule your transport."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What service levels do you offer for local freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We provide multiple service levels, including urgent deliveries, same-day services, and permanent hire to meet your specific needs."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I book recurring local freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer flexible options for recurring deliveries to ensure seamless transport solutions for your business."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Local Freight Services</h1>

                        <p>
                            Exclusively serving Melbourne, QFM offers reliable and efficient local freight solutions tailored to meet your specific needs. Whether you require ad hoc taxi-truck services for urgent deliveries or permanent transport arrangements for ongoing operations, our dedicated team ensures seamless management of all your local delivery requirements.
                        </p>
                        {!isMobile && (
                            <p>
                                From small parcels to larger loads, our versatile fleet—including tray trucks and vehicles equipped with hydraulic tailgates—guarantees that every shipment is handled with precision and care, delivering exceptional service across Melbourne.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-services-local-freight.jpg"
                            alt="Fast and reliable air freight services connecting Australia-wide with speed and precision"
                            title="Air Freight Services – Swift and Secure Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Dependable and efficient local freight solutions tailored for Melbourne businesses</h5>
                        )}
                        <p>
                            QFM's local freight services ensure fast and reliable deliveries across Melbourne, whether for urgent parcels or scheduled shipments. <Link to="/our-services">Explore how we can support your logistics needs</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Local Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Ad Hoc Taxi-Truck Services:</b> Rapid-response transport solutions for urgent or one-off deliveries within Melbourne and surrounding areas, ensuring quick turnaround times.
                            </li>
                            <li>
                                <b>Permanent Taxi-Truck Solutions:</b> Dedicated vehicles and drivers for businesses requiring consistent local freight support, offering reliable scheduled deliveries.
                            </li>
                            <li>
                                <b>Diverse Fleet Options:</b> Access to a variety of vehicles, including vans, trays, and hydraulic tailgate trucks, catering to different cargo sizes and handling requirements.
                            </li>
                            <li>
                                <b>Same-Day & Express Delivery:</b> Fast-tracked local freight services designed for time-sensitive shipments, keeping your supply chain moving efficiently.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-services-local-freight-two.jpg"
                            loading="lazy"
                            alt="Efficient and reliable local freight services for Melbourne businesses"
                            title="Local Freight Services – Tailored Solutions for Melbourne by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/our-services"><h4>View all Services</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="local-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What areas do your local freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our local freight services operate exclusively within Melbourne, offering reliable delivery solutions for businesses across the city and surrounding suburbs. Whether it’s an urgent delivery or a recurring transport need, we ensure dependable and efficient service.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you provide same-day courier services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we specialise in same-day courier services within Melbourne. Depending on your requirements, we offer flexible delivery timeframes, including a few hours or end-of-day delivery. Speak to our team to find the best solution for your timeline.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What vehicle types are available for local freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Our fleet includes a range of vehicles, from courier vans for small parcels to taxi trucks and hydraulic tailgate-equipped trucks for larger shipments. This ensures we can accommodate a wide variety of freight sizes and requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Can I hire a truck on a permanent basis?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we offer permanent truck hire for businesses in Melbourne. Whether you need a dedicated vehicle for daily operations or regular deliveries, we can provide a tailored solution to ensure your logistics run seamlessly.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>How quickly can you arrange a taxi truck service?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    We can arrange a taxi truck service within just a few hours, depending on your location and requirements. Our priority is to provide fast and efficient transport for urgent and same-day delivery needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>What service levels do you offer for local freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "260px" : "0px" }}
                            >
                                <p>
                                    Our local freight services include multiple service levels to match your requirements:
                                </p>
                                <p><b>Urgent Deliveries:</b> For time-critical shipments within hours.</p>
                                <p><b>Same-Day Services:</b> For deliveries completed by the end of the day.</p>
                                <p><b>Permanent Hire:</b> For businesses needing consistent transport solutions.</p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>Can I book recurring local freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. We provide flexible options for recurring deliveries, ensuring your business operates smoothly without the need to arrange transport each time. Contact us to discuss your regular freight requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default LocalFreight;