import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Office() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Show/hide scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button (desktop only)
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" navigation link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Office Freight Services | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="QFM provides specialised office freight services for transporting office furniture, IT equipment, and essential supplies across Australia. Our customised logistics ensure secure, reliable delivery to corporate environments."
        />
        <meta
          name="keywords"
          content="Office Freight Services, Office Equipment Transport, Office Furniture Shipping, Corporate Logistics, Secure Office Transport, Australia Freight, Reliable Office Freight, QFM"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Office Freight Services | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Secure office freight services for transporting office furniture, IT equipment, and supplies. Trust QFM for customised logistics that minimise disruption and ensure timely corporate deliveries across Australia."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-office.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/office-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/office-freight-services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/office-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/office-freight-services",
                "name": "Office Freight Services | Logistics Solutions by QFM",
                "description":
                  "Specialised office freight services for transporting office furniture, IT equipment, and essential supplies. Our customised logistics ensure secure and reliable delivery to corporate environments across Australia.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/office-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/office-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Office Freight Services",
                    "item": "https://qfmlogistics.com.au/office-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/office-freight-services#service",
                "name": "Office Freight Services",
                "description": "Our office freight services provide secure transport for office furniture, computers, and essential supplies. We offer customised logistics solutions that ensure your corporate shipments are handled with care and delivered on schedule across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-office.jpg",
                  "https://qfmlogistics.com.au/images/qfm-commodity-office-two.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a tailored quote for your office freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-office.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-office.jpg",
                "description": "Secure transport for office furniture and equipment, ensuring a smooth relocation process.",
                "name": "Office Freight Services - Secure Transport by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/office-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "@id": "https://qfmlogistics.com.au/images/qfm-commodity-office-two.jpg",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-office-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-office-two.jpg",
                "name": "Secure Office Freight Services by QFM",
                "description": "Reliable transport solutions for office furniture, IT equipment, and essential workplace materials, ensuring safe and timely delivery across Australia.",
                "author": {
                  "@type": "Organization",
                  "name": "Quan Freight Management",
                  "url": "https://qfmlogistics.com.au"
                },
                "license": {
                  "@type": "CreativeWork",
                  "url": "https://qfmlogistics.com.au/terms-and-conditions"
                },
                "creator": {
                  "@type": "Organization",
                  "name": "Quan Freight Management"
                },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/office-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What types of office equipment do you transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We transport a wide range of office essentials, including desks, chairs, storage cabinets, IT hardware, printers, and office supplies. Whether you're moving a few pieces or a complete office setup, we provide efficient and professional freight services."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you offer scheduled deliveries for office installations?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide flexible delivery schedules to align with office fit-outs and renovations. Whether you need staged deliveries or a full installation shipment, we work with your timeline to ensure minimal disruption to your business operations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you provide door-to-door freight services for office relocations?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, our office freight solutions include direct pickup and delivery to ensure smooth transitions for corporate relocations, workspace upgrades, and supplier distributions. We minimise handling to reduce transit risks and delays."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Can you transport fragile office equipment like IT hardware?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we handle delicate IT equipment such as computers, servers, and networking devices with care. We recommend secure packaging, and we use careful load management to ensure safe transport for high-value electronics."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I request a quote for office freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To receive a quote, visit our Contact Us page or call our team with details including the pickup and delivery locations, shipment size, and any specific requirements. We'll provide a tailored solution that fits your needs."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/office-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Office Freight Services</h1>

            <p>
              Whether you're relocating your corporate office, setting up a new branch, or upgrading your workspace, our office freight services provide secure transport for furniture, IT equipment, and essential office supplies. We ensure your items are handled with precision and care, minimising the risk of damage during transit.


            </p>

            {!isMobile && (
              <p>
                We specialise in the secure transport of office equipment, including desks, computers, filing systems, and bulk stationery.

                With a comprehensive network covering major cities and regional areas across Australia, we deliver customised logistics that minimise downtime, support seamless transitions, and keep your business operations running without disruption.

              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-office.jpg"
              alt="Secure transport for office furniture and equipment"
              title="Office Freight Services - Secure & Efficient Logistics by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Customised Office Freight Solutions for Your Business</h5>
            )}
            <p>
              Our tailored logistics ensure your office shipments are handled securely and delivered on time. <Link to="/goods-and-products">Explore our full range of goods and products</Link> for comprehensive support.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Office Freight by QFM</h2>
            <ul>
              <li>
                <b>Secure Transport for Office Equipment:</b> From desks and chairs to IT hardware and filing cabinets, we ensure office freight is handled safely and delivered without delays.
              </li>
              <li>
                <b>Reliable Logistics for Business Relocations:</b> Whether setting up a new office or upgrading an existing workspace, we provide seamless freight solutions to keep your operations running smoothly.
              </li>
              <li>
                <b>Nationwide Office Supply Distribution:</b> Supporting corporate offices, co-working spaces, and furniture suppliers across Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional locations.
              </li>
              <li>
                <b>Real-Time Shipment Tracking:</b> Gain full visibility over your office freight with live tracking and scheduled deliveries, ensuring precision in every shipment.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-office-two.jpg"
              loading="lazy"
              alt="Professional freight for office equipment ensuring secure and timely delivery"
              title="Office Freight Services - Professional Handling by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 */}
            <div className={`faq-item ${openFAQ === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
              <h5>What types of office equipment do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? '300px' : '0px' }}>
                <p>
                  We transport a wide range of office essentials, including desks, chairs, storage cabinets, IT hardware, printers, and office supplies. Whether you're moving a few pieces or a complete office setup, we provide efficient and professional freight services.
                </p>
              </div>
            </div>

            {/* FAQ 2 */}
            <div className={`faq-item ${openFAQ === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              <h5>Do you offer scheduled deliveries for office installations?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? '270px' : '0px' }}>
                <p>
                  Yes, we provide flexible delivery schedules to align with office fit-outs and renovations. Whether you need staged deliveries or a full installation shipment, we work with your timeline to ensure minimal disruption to your business operations.
                </p>
              </div>
            </div>

            {/* FAQ 3 */}
            <div className={`faq-item ${openFAQ === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              <h5>Do you provide door-to-door freight services for office relocations?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? '300px' : '0px' }}>
                <p>
                  Yes, our office freight solutions include direct pickup and delivery to ensure smooth transitions for corporate relocations, workspace upgrades, and supplier distributions. We minimise handling to reduce transit risks and delays.
                </p>
              </div>
            </div>

            {/* FAQ 4 */}
            <div className={`faq-item ${openFAQ === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              <h5>Can you transport fragile office equipment like IT hardware?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? '300px' : '0px' }}>
                <p>
                  Yes, we handle delicate IT equipment such as computers, servers, and networking devices with care. We recommend secure packaging, and we use careful load management to ensure safe transport for high-value electronics.
                </p>
              </div>
            </div>

            {/* FAQ 5 */}
            <div className={`faq-item ${openFAQ === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              <h5>How can I request a quote for office freight services?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? '300px' : '0px' }}>
                <p>
                  To receive a quote, visit our <Link to="/contact-us">Contact Us</Link> page or call our team with details including the pickup and delivery locations, shipment size, and any specific requirements. We'll provide a tailored solution that fits your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Scroll Up Buttons */}
        {isVisible && (
          <div className="scroll-up" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {isMobile && isScrollUpVisible && (
          <div className="scroll-up-mobile" onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Office;