import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Perth() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/locations-we-service"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Melbourne to Perth Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM offers secure and efficient freight services from Melbourne to Perth, delivering reliable transport solutions via road, rail, and air freight." />
                <meta name="keywords" content="Melbourne to Perth freight, interstate freight transport, QFM Logistics, road freight, rail freight, air freight, Melbourne Perth logistics, express freight, bulk transport, freight carriers" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-WA" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Melbourne to Perth Freight Services | Reliable Interstate Transport by QFM" />
                <meta property="og:description" content="QFM provides expert freight solutions from Melbourne to Perth, ensuring secure and efficient transport for businesses with flexible delivery options." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-melbourne-to-perth.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/melbourne-to-perth-freight" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/melbourne-to-perth-freight" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight#webpage",
                                "url": "https://qfmlogistics.com.au/melbourne-to-perth-freight",
                                "name": "Melbourne to Perth Freight Services | Logistics Solutions by QFM",
                                "description": "QFM provides premium freight services from Melbourne to Perth, ensuring secure and timely deliveries via road, rail, and air transport.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Locations",
                                        "item": "https://qfmlogistics.com.au/locations-we-service"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Melbourne to Perth Freight Services",
                                        "item": "https://qfmlogistics.com.au/melbourne-to-perth-freight"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight#service",
                                "name": "Melbourne to Perth Freight Services",
                                "description": "Reliable freight solutions from Melbourne to Perth, servicing businesses with secure and efficient transport via road, rail, and air freight.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-melbourne-to-perth.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-perth.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-perth.jpg",
                                "description": "Reliable Melbourne to Perth freight services by QFM, ensuring secure and timely interstate deliveries.",
                                "name": "Melbourne to Perth Freight - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-melbourne-to-perth-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-melbourne-to-perth-two.jpg",
                                "description": "Melbourne to Perth freight solutions tailored for businesses across various industries, offering reliable transport options.",
                                "name": "Melbourne to Perth Freight Solutions - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight#faq",
                                "isPartOf": {
                                    "@type": "WebPage",
                                    "@id": "https://qfmlogistics.com.au/melbourne-to-perth-freight",
                                    "url": "https://qfmlogistics.com.au/melbourne-to-perth-freight",
                                    "name": "Melbourne to Perth Freight Services | Logistics Solutions by QFM"
                                },
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What freight options are available from Melbourne to Perth?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We offer road, rail, and air freight services from Melbourne to Perth. Whether you need express shipping, bulk transport, or cost-effective rail freight, QFM ensures a seamless freight experience tailored to your business requirements."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How long does freight take from Melbourne to Perth?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Standard road freight typically takes 3-5 business days. For urgent shipments, we offer express freight and air transport options to ensure next-day delivery. We also provide a cost-effective rail freight option for bulk shipments."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide freight services to regional Western Australia from Perth?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, QFM extends its Melbourne to Perth freight services to regional Western Australia. We handle freight distribution to locations such as Mandurah, Bunbury, Geraldton, Kalgoorlie, and beyond."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I get a quote for Melbourne to Perth freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "You can request a quote by visiting our Contact Us page at https://qfmlogistics.com.au/contact-us. Simply provide details such as shipment size, weight, and urgency, and our team will provide a competitive and tailored quote."
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}


                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Melbourne to Perth Freight Services</h1>
                        <p>
                            QFM offers reliable and efficient freight transport from Melbourne to Perth, ensuring your goods reach Western Australia securely and on time.
                            With an extensive network covering road, rail, and air freight, we provide flexible logistics solutions tailored to your needs.
                        </p>
                        {!isMobile && (
                            <p>
                                Whether you need express shipping, full truckload (FTL) transport, or cost-effective less-than-truckload (LTL) options, QFM delivers
                                comprehensive freight solutions across Perth and regional WA. Our tracking systems provide real-time shipment visibility, ensuring seamless deliveries.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-melbourne-to-perth.jpg"
                            alt="Reliable Melbourne to Perth Freight Services by QFM"
                            title="Melbourne to Perth Freight – Secure & Efficient Transport by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>


                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Efficient and Secure Freight Transport to Perth</h5>
                        )}
                        <p>
                            Whether you require express freight, bulk transportation, or custom logistics solutions, our Melbourne to Perth freight services provide timely and secure deliveries.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect perth-add">
                        <h2>Melbourne to Perth Freight by QFM</h2>
                        <ul>
                            <li><b>Comprehensive Interstate Freight:</b> Specialised transport solutions from Melbourne to Perth, covering industries such as mining, construction, and retail.</li>
                            <li><b>Express & Priority Freight Services:</b> Fast-tracked road, rail, and air freight options to ensure urgent deliveries across Western Australia.</li>
                            <li><b>Reliable Bulk & Palletised Freight:</b> Secure transport for high-volume shipments, ensuring safe and efficient transit to Perth and surrounding areas.</li>
                            <li><b>Advanced Freight Tracking & Support:</b> Real-time shipment updates, transparent delivery scheduling, and dedicated customer support for seamless logistics.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-melbourne-to-perth-two.jpg"
                            loading="lazy"
                            alt="Melbourne to Perth freight transport solutions by QFM"
                            title="Efficient and Secure Melbourne to Perth Freight by QFM"
                        />
                        {isMobile && (
                            <div className="default-quote appearing-effect">
                                <div className="default-quote-quote">
                                    <Link to="/locations-we-service"><h4>View all Locations</h4></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="general-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
                            <h5>What freight options are available from Melbourne to Perth?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                                <p>
                                    QFM provides comprehensive freight solutions from Melbourne to Perth, including road, rail, and air freight.
                                    Whether you require full truckload (FTL), less-than-truckload (LTL), or specialised logistics, we ensure timely and secure deliveries across Western Australia.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
                            <h5>How long does freight take from Melbourne to Perth?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                                <p>
                                    Standard road freight transit times from Melbourne to Perth typically range from 3 to 5 business days, depending on shipment size and urgency.
                                    For faster delivery, we offer express freight and priority air transport services that ensure next-day or two-day arrivals.
                                    Additionally, we provide a <strong>cost-effective rail freight option</strong>, ideal for bulk shipments requiring reliable and economical transit across Australia.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div className={`faq-item ${openFAQ === 2 ? "active" : ""}`} onClick={() => toggleFAQ(2)}>
                            <h5>Do you provide freight services to regional Western Australia from Perth?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 2 ? "300px" : "0px" }}>
                                <p>
                                    Yes, QFM extends its Melbourne to Perth freight services to regional Western Australia.
                                    We handle deliveries to locations such as Mandurah, Bunbury, Geraldton, Kalgoorlie, and beyond, ensuring businesses in WA receive reliable shipping solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
                            <h5>What types of freight can QFM transport to Perth?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                                <p>
                                    We transport a variety of freight types, including palletised goods, oversized cargo, construction materials, mining equipment, and e-commerce shipments.
                                    Our logistics solutions ensure safe and secure deliveries across Perth and greater WA.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
                            <h5>How can I get a quote for Melbourne to Perth freight?</h5>
                            <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                                <p>
                                    You can request a freight quote by visiting our <Link to="/contact-us">Contact Us</Link> page.
                                    Provide details such as shipment size, weight, and delivery requirements, and our team will offer a tailored, competitive quote for your Melbourne to Perth freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Perth;
