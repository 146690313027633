import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/Technology.css';

function Technology() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="technology-main-content">
            <Helmet>
                <title>Resources | Freight Management Insights by QFM</title>
                <meta name="description" content="Access valuable logistics insights, industry updates, and freight management resources with QFM Logistics. Stay informed about efficient transport solutions, technology innovations, and best practices for seamless freight movement across Australia." />
                <meta name="keywords" content="Freight Management Resources, Logistics Insights, Quan Freight Management, QFM, QFM Logistics, Freight Industry Updates, Transport Solutions, Freight Technology, Supply Chain Efficiency, Australian Freight Services, Freight Best Practices, Route Optimisation, Real-Time Tracking, Freight Compliance" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Resources | Freight Management Insights by QFM" />
                <meta property="og:description" content="Explore essential freight management resources from QFM Logistics. Gain industry insights, transport solutions, and expert guidance to optimise freight operations across Australia." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-logistics-resources.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/resources" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/resources" />
                {/* Update the JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/resources#webpage",
                                "url": "https://qfmlogistics.com.au/resources",
                                "name": "Freight Resources | Logistics Solutions by QFM",
                                "description": "Access essential freight management resources from Quan Freight Management, including transport solutions, industry insights, and logistics technology for efficient freight movement across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/resources#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/resources#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Freight Resources",
                                        "item": "https://qfmlogistics.com.au/resources"
                                    }
                                ]
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-resources.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-resources.jpg",
                                "description": "Explore QFM's freight management resources, providing insights into logistics solutions, transport efficiency, and industry advancements across Australia.",
                                "name": "Freight Management Resources - QFM Logistics",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="ourstory-intro-section-text-content">
                        <h1>Resources</h1>
                        {!isMobile && (
                            <p>
                                Explore essential freight management resources and industry insights with Quan Freight Management. Our commitment to innovation, efficiency, and reliability ensures businesses across Australia benefit from optimised logistics solutions tailored to their needs. Whether you're managing local deliveries or complex interstate freight, our resources provide the knowledge and tools to streamline your operations.
                            </p>
                        )}
                        <p>
                            Stay ahead with the latest in freight technology, real-time tracking, and data-driven logistics strategies. From transport regulations and compliance updates to best practices for reducing transit times and costs, QFM provides expert guidance to help you optimise supply chain efficiency.
                        </p>

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-resources.jpg"
                            alt="Explore QFM's freight management resources for transport solutions across Australia"
                            title="QFM Logistics - Freight Management Resources"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect" style={isMobile ? { marginBottom: "10px" } : {}}>

                    <div className="default-quote-quote">
                        <Link
                            to="/contact-us"
                        >
                            <h4>Get In touch</h4>
                        </Link>
                    </div>

                    {!isMobile && (
                        <div className="default-quote-text-content">
                            <h5>Seamless Freight Solutions Across Australia</h5>
                            <p>
                                With advanced tracking systems and predictive analytics, we proactively manage your freight for optimal efficiency and reliability.
                            </p>
                        </div>
                    )}
                </div>


                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ borderBottom: "2px solid #000", paddingBottom: "20px" }}>

                        <h3>Freight Management Technology</h3>
                        <ul>
                            <li>
                                <Link to="/freight-management-technology">Real-time tracking</Link> enhances shipment visibility, allowing proactive management to reduce delays and improve delivery accuracy.
                            </li>
                            <li>
                                Advanced route optimisation leverages data-driven insights to minimise transit times, lower costs, and increase overall freight efficiency.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ borderBottom: "2px solid #000", paddingBottom: "20px", paddingTop: "15px" }}>
                        <h3>Integrated Freight Solutions</h3>
                        <ul>
                            <li>
                                <Link to="/integrated-freight-solutions">Seamless eCommerce integration</Link> synchronises your online store with real-time freight management, automating shipping rates, tracking, and order fulfillment for a smoother checkout experience.
                            </li>
                            <li>
                                Automated freight calculations provide instant shipping quotes at checkout, ensuring accurate pricing and improving transparency for your customers.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ borderBottom: "2px solid #000", paddingBottom: "20px", paddingTop: "15px" }}>
                        <h3>Calculate Cubic Weight</h3>
                        <ul>
                            <li>
                                Understand cubic weight calculations to ensure accurate freight pricing and avoid unexpected shipping costs when sending bulky or lightweight items.
                            </li>
                            <li>
                                <Link to="/calculate-cubic-weight">Use our cubic weight calculator</Link> to determine the chargeable weight of your shipments, helping you plan freight costs efficiently.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ borderBottom: "2px solid #000", paddingBottom: "20px", paddingTop: "15px" }}>
                        <h3>Australian Zone Guide</h3>
                        <ul>
                            <li>
                                Search any suburb Australia-wide to find the correct freight zone, streamline planning, and reduce transit times.
                                <Link to="/zone-guide"> Explore our Zone Guide</Link> for accurate zone mapping.
                            </li>
                            <li>
                                Compare freight zones and service availability to choose the most efficient shipping options across metro, regional, and remote areas.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ borderBottom: "2px solid #000", paddingBottom: "20px", paddingTop: "15px" }}>
                        <h3>Australian Freight Guide</h3>
                        <ul>
                            <li>
                                <Link to="/freight-guide">Essential freight tips</Link> to help you streamline shipping, reduce costs, and avoid common logistics challenges across Australia.
                            </li>
                            <li>
                                Understanding freight classifications ensures accurate pricing, compliance with carrier requirements, and smoother transportation of your goods.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="technology-section-3 appearing-effect">
                    <div className="technology-section-3-text-content appearing-effect" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <h3>Our Journey in Freight Management</h3>
                        <ul>
                            <li>
                                <Link to="/our-story">Discover our origins</Link> and how QFM Logistics grew into a leading freight management provider, delivering reliable transport solutions across Australia.
                            </li>

                            <li>
                                Our expertise in road, rail, and air freight ensures seamless supply chain solutions, from Melbourne to all corners of Australia.
                            </li>
                        </ul>
                    </div>
                </div>

                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Technology;