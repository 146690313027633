import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const DestinationInput = ({ id, value, onChange, style, onFocus, ...rest }) => {
  // Use the passed value for the input
  const [searchTerm, setSearchTerm] = useState(value || "");
  const [suburbs, setSuburbs] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Update internal state when the parent value changes
  useEffect(() => {
    setSearchTerm(value || "");
  }, [value]);

  const handleSuggestionClick = (suburb) => {
    const newVal = `${suburb.SUBURB} ${suburb.POSTCODE}`;
    setSearchTerm(newVal);
    if (onChange) {
      onChange({ target: { value: newVal } });
    }
    setSuggestions([]);
    setShowSuggestions(false);
  };

  useEffect(() => {
    fetch("/documents/QFM-Suburbs-Melbourne.csv")
      .then((res) => res.text())
      .then((csv) => {
        Papa.parse(csv, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            const data = result.data.filter(
              (item) => item.SUBURB && item.POSTCODE && item.STATE
            );
            setSuburbs(data);
          },
        });
      });
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (onChange) onChange(e);
    if (e.target.value.trim() === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }
    const filtered = suburbs.filter(
      (s) =>
        s.SUBURB.toLowerCase().includes(e.target.value.toLowerCase()) ||
        s.POSTCODE.toString().includes(e.target.value)
    );
    setSuggestions(filtered.slice(0, 5));
    setShowSuggestions(true);
  };

  const handleInputFocus = (e) => {
    if (suggestions.length) setShowSuggestions(true);
    if (onFocus) onFocus(e);
  };

  return (
    <div className="homequote-autocomplete-wrapper">
      <input
        type="text"
        id={id}
        name={id}
        value={searchTerm}
        onChange={handleInputChange}
        style={style}
        onFocus={handleInputFocus}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
        className="homequote-input"
        placeholder="Type Suburb or Postcode"
        {...rest}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="homequote-suggestions">
          {suggestions.map((s, idx) => (
            <li
              key={idx}
              onMouseDown={(e) => {
                e.preventDefault();
                handleSuggestionClick(s);
              }}
            >
              {s.SUBURB} - {s.POSTCODE} ({s.STATE})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DestinationInput;