import React from 'react';
import '../css/GetInTouch.css'; // Ensure to create and link a CSS file for custom styles

function CustomAlert({ title, message, type, onClose }) {
  return (
    <div className={`custom-alert ${type}`}>
      <div className="custom-alert-header">
        <span className="custom-alert-title">{title}</span>
        <button className="custom-alert-close" onClick={onClose}>&times;</button>
      </div>
      <div className="custom-alert-body">
        <p>{message}</p>
      </div>
    </div>
  );
}

export default CustomAlert;


