import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Panel() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Panel Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides expert panel freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective solutions ensure the secure and efficient transport of flat and oversized panels using advanced tracking systems and expert care." />
                <meta name="keywords" content="Panel Freight Services, Quan Freight Management, QFM Logistics, Panel Transport Australia, Melbourne Panel Freight, Brisbane Panel Logistics, Freight Solutions, Reliable Logistics, Oversized Panel Delivery, Safe Freight Handling, Cost-Effective Logistics Solutions, Advanced Freight Technology, Construction Freight Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Panel Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's panel freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport of flat and oversized panels with advanced logistics solutions and professional handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-panel.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/panel-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/panel-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/panel-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/panel-freight-services",
                                "name": "Panel Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in panel freight services, offering secure transport for large and oversized panels across Australia, including Melbourne, Sydney, Brisbane, Perth, and Adelaide.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/panel-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/panel-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Panel Freight Services",
                                        "item": "https://qfmlogistics.com.au/panel-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/panel-freight-services#service",
                                "name": "Panel Freight Services",
                                "description": "Secure and efficient transport of panels, including oversized and fragile materials, with nationwide coverage across Australia.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-panel.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-panel.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-panel.jpg",
                                "description": "Expert handling and secure transport of large panel shipments across Australia",
                                "name": "Panel Freight Services - Secure Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-panel-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-panel-two.jpg",
                                "description": "Secure transport of oversized and fragile panel shipments with expert logistics solutions",
                                "name": "Panel Freight Services - Comprehensive Panel Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/panel-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which locations do your panel freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our panel freight services cover Melbourne, Sydney, Brisbane, Perth, Adelaide, and regional areas across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you handle oversized panels?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we handle oversized panels with secure logistics solutions tailored to your shipment needs."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What materials are suitable for panel freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We transport materials such as glass, wood, composite panels, and metal sheets, tailored to diverse industries."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight restrictions for panels?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We accommodate panels of various sizes and weights, including oversized shipments. Contact us with details for tailored solutions."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for panel freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page to provide shipment details, and our team will promptly provide a customised quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide secure handling for fragile panels?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we offer specialised handling for fragile panels such as glass to ensure safe transport."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/panel-freight-services",
                                            "name": "Panel Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Panel Freight Services</h1>
                        <p>
                            At QFM, our panel freight services are meticulously engineered for the transport of large, flat panels—whether for construction, industrial applications, or commercial installations. We develop bespoke logistics strategies that safeguard your panels from loading through to delivery, ensuring they remain intact and undamaged.
                        </p>
                        {!isMobile && (
                            <p>
                                Our service network spans from Melbourne to major hubs including Sydney, Brisbane, Perth, and Adelaide, and extends to regional centres. Utilizing a blend of road, rail, and air transport, we offer versatile and reliable solutions crafted to overcome the unique challenges of panel shipping.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="images/qfm-item-types-panel.jpg"
                            alt="Secure panel freight services that ensure the safe transport of large, flat panels across Australia"
                            title="Panel Freight Services – Bespoke logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/contact-us"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Specialised panel freight solutions engineered for precision and efficiency</h5>
                        )}
                        <p>
                            Our panel freight offerings from Melbourne ensure seamless integration with major logistics hubs nationwide, delivering your panels safely and on schedule. <Link to="/types-of-freight">Get a detailed overview of the freight types we cover</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Panel Freight by QFM</h2>
                        <ul>
                            <li><b>Specialised Handling:</b> Designed specifically for large, flat panels, ensuring every shipment is carefully managed.</li>
                            <li><b>Precision Delivery:</b> Our logistics streamline transit, ensuring panels arrive at the correct destination on time.</li>
                            <li><b>Bespoke Solutions:</b> We tailor our services to accommodate a variety of panel dimensions and weights, meeting your project-specific requirements.</li>
                            <li><b>Robust Network:</b> Our extensive reach covers all major cities and regional areas, providing comprehensive nationwide logistics.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="images/qfm-item-types-panel-two.jpg"
                            loading="lazy"
                            alt="Expert handling and secure transport of large panel shipments across Australia"
                            title="Melbourne Freight Handling – Expert Panel Logistics Solutions by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="panel-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which locations do your panel freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our panel freight services connect Melbourne to major Australian cities, including Sydney, Brisbane, Perth, and Adelaide. We also service regional and remote areas, providing comprehensive nationwide coverage.
                                    <br />
                                    Whether your panels need to be delivered locally or interstate, we ensure reliable and efficient transport.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you handle oversized panels?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we specialise in transporting oversized panels and other large-format items. Our team uses secure handling techniques and customised logistics solutions to ensure your panels are delivered safely and intact.
                                    <br />
                                    Contact us to discuss your specific requirements for oversized shipments.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What materials are suitable for panel freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Our panel freight services are ideal for materials such as metal, wood, composite panels, and other flat or sheet-like items. We tailor our services to accommodate a wide range of industries, including construction, manufacturing, and retail.
                                    <br />
                                    If you're unsure about the suitability of your panels, feel free to reach out to our team.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Are there size or weight restrictions for panels?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    We can handle panels of various sizes and weights, including oversized and heavy panels. While some restrictions may apply depending on transport mode, we are equipped to find a solution for most shipment requirements.
                                    <br />
                                    Share your panel dimensions and weight with our team, and we’ll provide customised transport recommendations.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>How can I request a quote for panel freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/contact-us">Contact Us</Link> page. Provide details such as panel dimensions, weight, pickup and delivery locations, and any special requirements.
                                    <br />
                                    Our team will promptly assess your needs and provide a customised quote.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>Do you provide secure handling for fragile panels?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    Absolutely. Our logistics team ensures secure packaging, loading, and transport to protect your shipment throughout the journey.
                                    <br />
                                    Let us know if your panels require extra care, and we’ll make the necessary arrangements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Panel;


