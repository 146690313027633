import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import CustomAlert from './CustomAlert';
import '../css/GetInTouch.css';
import PickupInput from "./PickupSearch.js";
import DestinationInput from "./DeliverySearch.js";
import emailjs from 'emailjs-com';

function GetInTouch() {
  const [isVisible, setIsVisible] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [items, setItems] = useState([1]);
  const [nextItemId, setNextItemId] = useState(2);
  const addItem = () => {
    if (items.length < 5) {
      setItems([...items, nextItemId]);
      setNextItemId(nextItemId + 1);
    }
  };

  const deleteItem = (id) => {
    if (items.length > 1) {
      setItems(items.filter((itemId) => itemId !== id));
    }
  };

  const [formData, setFormData] = useState({
    pickup: "",
    destination: "",
    pickupResidential: false,
    destResidential: false,
    items: {},
    name: "",
    email: "",
    phone: "",
    company: "",
    comments: ""
  });

  const [itemTypes, setItemTypes] = useState({ 1: "Pallet" });
  const disableDimensionsTypes = ["A-Trailer", "B-Trailer", "Semi-Truck", "B-Double", "B-Double Mez Deck", "20' Container", "40' Container"];

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button immediately
    console.log("Submit button clicked");
    const newErrors = {};
    if (!formData.name.trim()) newErrors["contactquote-name"] = true;
    if (!formData.email.trim()) {
      newErrors["contactquote-email"] = true;
    } else if (!validateEmail(formData.email)) {
      newErrors["contactquote-email"] = true;
    }
    if (!formData.phone.trim()) newErrors["contactquote-phone"] = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false); // Re-enable if validation fails
      return;
    }

    const itemsDetails = Object.entries(formData.items)
      .map(([key, item]) => {
        const type = itemTypes[key] || 'Pallet';
        return `Item ${key}:\nType: ${type}\nQuantity: ${item.quantity || ''}\nDangerous Goods: ${item.dg || 'no'}\nWeight: ${item.weight || ''}kg\nLength: ${item.length || ''}cm\nWidth: ${item.width || ''}cm\nHeight: ${item.height || ''}cm`;
      })
      .join('\n\n');

    const templateParams = {
      pickup: formData.pickup,
      destination: formData.destination,
      pickupResidential: formData.pickupResidential ? 'Yes' : 'No',
      destResidential: formData.destResidential ? 'Yes' : 'No',
      items: itemsDetails,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      comments: formData.comments,
    };

    
    // emailjs.send('service_ck7pml9', 'template_cy4jff2', templateParams, '5aleywHLj9YTnXLCy')
    emailjs.send('service_eqjos09', 'template_1bmxjm7', templateParams, '6hHglwaeH0hQQmjcJ')
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setAlertTitle('Success');
        setAlertMessage('Thank you for submitting your quote request. Your details have been successfully sent, and a member of the QFM team will be in touch with you shortly.');
        setAlertType('success');
        setAlertVisible(true);
        setFormData({
          pickup: "",
          destination: "",
          pickupResidential: false,
          destResidential: false,
          items: {},
          name: "",
          email: "",
          phone: "",
          company: "",
          comments: ""
        });
        setFormStep(1);
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setAlertTitle('Error');
        setAlertMessage('We’re sorry, but we encountered an error while processing your request. Please try again later or contact us at contact@qfmlogistics.com.au for assistance.');
        setAlertType('error');
        setAlertVisible(true);
      })
      .finally(() => {
        // Re-enable the button after 2 seconds
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      });
  };

  const handleGetQuote = () => {
    const newErrors = {};

    // Validate Pickup & Destination using the new IDs
    const pickupValue = document.getElementById("contactquote-pickup")?.value;
    const destinationValue = document.getElementById("contactquote-destination")?.value;
    if (!pickupValue) newErrors["contactquote-pickup"] = true;
    if (!destinationValue) newErrors["contactquote-destination"] = true;

    // Validate each item's required fields using new IDs
    for (let item of items) {
      const quantity = document.getElementById(`contactquote-quantity-${item}`)?.value;
      const weight = document.getElementById(`contactquote-weight-${item}`)?.value;
      if (!quantity) newErrors[`contactquote-quantity-${item}`] = true;
      if (!weight) newErrors[`contactquote-weight-${item}`] = true;

      // Only validate dimensions if not disabled
      if (!disableDimensionsTypes.includes(itemTypes[item])) {
        const length = document.getElementById(`contactquote-length-${item}`)?.value;
        const width = document.getElementById(`contactquote-width-${item}`)?.value;
        const height = document.getElementById(`contactquote-height-${item}`)?.value;
        if (!length) newErrors[`contactquote-length-${item}`] = true;
        if (!width) newErrors[`contactquote-width-${item}`] = true;
        if (!height) newErrors[`contactquote-height-${item}`] = true;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
      setFormStep(2);
    }
  };
  const customStyles = `
  .homequote-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    overflow-y: auto;
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0.3rem 0 0 0;
  }
  
  .homequote-suggestions li {
    padding: 0.5rem 0.5rem;
    margin: 0;
    cursor: pointer;
    color: #000;
    font-size: 0.8rem !important;
    border-bottom: 1px solid #eee;
  }
  
  .homequote-suggestions li:hover {
    background-color: #f0f0f0;
  }
  `;
  return (
    <div className="getintouch-main-content">
      <Helmet>
        <title>Get In Touch | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="Contact Quan Freight Management for expert freight solutions across Australia. From Melbourne to major cities, our dedicated team ensures seamless logistics with advanced technology and tailored services. Reach out to us today."
        />
        <meta
          name="keywords"
          content="Get In Touch, Contact QFM, Freight Solutions, Quan Freight Management, QFM Logistics, Melbourne Freight Services, Major Cities Freight, Seamless Logistics, Advanced Freight Technology, Tailored Freight Services, Freight Expertise, Reliable Freight Solutions, Australia-Wide Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Get In Touch | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Connect with Quan Freight Management to discover seamless freight solutions across Australia. From Melbourne to major cities, our expert team provides reliable, customer-first logistics services tailored to your needs."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-contact-us.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/contact-us" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/contact-us" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "ContactPage",
                "@id": "https://qfmlogistics.com.au/contact-us#webpage",
                "url": "https://qfmlogistics.com.au/contact-us",
                "name": "Get in Touch | Logistics Solutions by QFM",
                "description": "Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/contact-us#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "CreativeWork",
                "@id": "https://qfmlogistics.com.au#license",
                "name": "Terms and Conditions",
                "url": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/contact-us#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Get In Touch",
                    "item": "https://qfmlogistics.com.au/contact-us"
                  }
                ]
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-get-in-touch.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-get-in-touch.jpg",
                "description": "Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions",
                "name": "Get in Touch - Reliable Freight Services Across Australia",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
        </script>
      </Helmet>
      <div className="getintouch-content-container">
        {alertVisible && (
          <CustomAlert
            title={alertTitle}
            message={alertMessage}
            type={alertType}
            onClose={() => setAlertVisible(false)}
          />
        )}
        <style>{customStyles}</style>
        <Header isScrolledInitially={true} />
        {!isMobile && (
          <div className="vertical-line"></div>
        )}
        <div className="getintouch-intro-section getintouch-intro-section-add appearing-effect">
          <div className="getintouch-intro-section-text-content">
            <h1>Contact Us</h1>
            <p>
              Whether you have a question about our freight services or need a customised logistics solution,
              our team at Quan Freight Management is here to help. With years of expertise, a customer-first
              approach, and a dedication to innovation, we’re committed to delivering seamless, reliable,
              and efficient transport solutions across Australia.
            </p>
            {!isMobile && (
              <p>
                Reach out to discuss your freight needs, request a quote, or learn more about how we can support
                your business. From Melbourne to Sydney, Brisbane, and beyond, our advanced logistics network
                and dedicated team are ready to assist. Let us partner with you to optimise your supply chain
                and provide reliable, cost-effective logistics solutions you can trust.
              </p>
            )}
          </div>
          <div className="getintouch-intro-section-image-container">
            <img
              src="images/qfm-get-in-touch.jpg"
              alt="Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions"
              title="Get in Touch - Reliable Freight Services Across Australia"
              loading="lazy"
            />
          </div>
        </div>

        <div className="getintouch-section-3 appearing-effect">
          <div className="getintouch-section-3-text-content">
            <div className="contact-us-form">
              <div className="step-indicator">
                Fill out the form below or email us at{' '}
                <a href="mailto:contact@qfmlogistics.com.au" style={{ color: 'rgb(0,0,128)', textDecoration: 'none' }}>
                  <b>contact@qfmlogistics.com.au</b>
                </a>.
              </div>
              <div className="contact-content-wrapper">
                <h6 className="contactquote-heading">
                  {formStep === 1 ? "Freight Quote" : "Final Details"}
                </h6>
                <div className="contact-content-quote">
                  <form className="homequote-form" onSubmit={handleSubmit}>
                    {formStep === 1 && (
                      <>
                        <table className="homequote-table">
                          <tbody>
                            <tr>
                              <td>
                                <label htmlFor="contactquote-pickup">Pickup</label>
                              </td>
                              <td>
                                <label htmlFor="contactquote-destination">Destination</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <PickupInput
                                  id="contactquote-pickup"
                                  value={formData.pickup}
                                  onChange={(e) => setFormData({ ...formData, pickup: e.target.value })}
                                  style={errors['contactquote-pickup'] ? { border: "1px solid red" } : {}}
                                  autoComplete="off"
                                  onFocus={() => {
                                    if (errors['contactquote-pickup']) {
                                      const newErr = { ...errors };
                                      delete newErr['contactquote-pickup'];
                                      setErrors(newErr);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <DestinationInput
                                  id="contactquote-destination"
                                  value={formData.destination}
                                  onChange={(e) => setFormData({ ...formData, destination: e.target.value })}
                                  style={errors['contactquote-destination'] ? { border: "1px solid red" } : {}}
                                  autoComplete="off"
                                  onFocus={() => {
                                    if (errors['contactquote-destination']) {
                                      const newErr = { ...errors };
                                      delete newErr['contactquote-destination'];
                                      setErrors(newErr);
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="homequote-switch">
                                  <input
                                    type="checkbox"
                                    className="homequote-checkbox homequote-pickup-residential"
                                    checked={formData.pickupResidential}
                                    onChange={(e) => setFormData({ ...formData, pickupResidential: e.target.checked })}
                                  />
                                  <span className="homequote-slider"></span>
                                  <span className="homequote-switch-label">Residential Pickup</span>
                                </label>
                              </td>
                              <td>
                                <label className="homequote-switch">
                                  <input
                                    type="checkbox"
                                    className="homequote-checkbox homequote-dest-residential"
                                    checked={formData.destResidential}
                                    onChange={(e) => setFormData({ ...formData, destResidential: e.target.checked })}
                                  />
                                  <span className="homequote-slider"></span>
                                  <span className="homequote-switch-label">Residential Delivery</span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {items.map((item, index) => (
                          <div key={item}>
                            <hr className="homequote-divider"></hr>
                            <table className="homequote-table homequote-item-table">
                              <tbody>
                                <tr>
                                  <td colSpan={2}>
                                    <label htmlFor={`contactquote-item-type-${item}`}>Item {index + 1}</label>
                                  </td>
                                  <td>
                                    <label htmlFor={`contactquote-quantity-${item}`}>Quantity</label>
                                  </td>
                                  <td>
                                    <label htmlFor={`contactquote-dg-${item}`}>DG</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <select
                                      id={`contactquote-item-type-${item}`}
                                      name={`contactquote-item-type-${item}`}
                                      className="homequote-input"
                                      value={itemTypes[item] || "Pallet"}
                                      onChange={(e) => {
                                        const newItemType = e.target.value;
                                        setItemTypes({ ...itemTypes, [item]: newItemType });
                                        const newErrors = { ...errors };
                                        delete newErrors[`contactquote-quantity-${item}`];
                                        delete newErrors[`contactquote-weight-${item}`];
                                        delete newErrors[`contactquote-length-${item}`];
                                        delete newErrors[`contactquote-width-${item}`];
                                        delete newErrors[`contactquote-height-${item}`];
                                        setErrors(newErrors);
                                        if (disableDimensionsTypes.includes(newItemType)) {
                                          const lengthInput = document.getElementById(`contactquote-length-${item}`);
                                          const widthInput = document.getElementById(`contactquote-width-${item}`);
                                          const heightInput = document.getElementById(`contactquote-height-${item}`);
                                          if (lengthInput) lengthInput.value = "";
                                          if (widthInput) widthInput.value = "";
                                          if (heightInput) heightInput.value = "";
                                        }
                                      }}
                                    >
                                      <option value="Carton">Carton</option>
                                      <option value="Pallet">Pallet</option>
                                      <option disabled>────────────</option>
                                      <option value="Envelope">Envelope</option>
                                      <option value="Satchel">Satchel</option>
                                      <option value="Bag">Bag</option>
                                      <option value="Tube">Tube</option>
                                      <option value="Bundle">Bundle</option>
                                      <option value="Drum">Drum</option>
                                      <option value="Skid">Skid</option>
                                      <option value="Panel">Panel</option>
                                      <option value="Crate">Crate</option>
                                      <option value="Ibc">IBC</option>
                                      <option value="Stillage">Stillage</option>
                                      <option value="A-Trailer">A-Trailer</option>
                                      <option value="Semi-Truck">Semi-Truck</option>
                                      <option value="B-Double">B-Double</option>
                                      <option value="B-Double Mez Deck">B-Double Mez Deck</option>
                                      <option value="20' Container">20' Container</option>
                                      <option value="40' Container">40' Container</option>
                                      <option value="other">Other/Misc</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      id={`contactquote-quantity-${item}`}
                                      name={`contactquote-quantity-${item}`}
                                      className="homequote-input"
                                      autoComplete="off"
                                      placeholder="Eg: 1"
                                      value={(formData.items[item] && formData.items[item].quantity) || ""}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              quantity: e.target.value
                                            }
                                          }
                                        })
                                      }
                                      style={errors[`contactquote-quantity-${item}`] ? { border: "1px solid red" } : {}}
                                      onFocus={() => {
                                        if (errors[`contactquote-quantity-${item}`]) {
                                          const newErr = { ...errors };
                                          delete newErr[`contactquote-quantity-${item}`];
                                          setErrors(newErr);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      id={`contactquote-dg-${item}`}
                                      name={`contactquote-dg-${item}`}
                                      className="homequote-input"
                                      autoComplete="off"
                                      value={(formData.items[item] && formData.items[item].dg) || "no"}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              dg: e.target.value
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label htmlFor={`contactquote-length-${item}`}>Length (cm)</label>
                                  </td>
                                  <td>
                                    <label htmlFor={`contactquote-width-${item}`}>Width (cm)</label>
                                  </td>
                                  <td>
                                    <label htmlFor={`contactquote-height-${item}`}>Height (cm)</label>
                                  </td>
                                  <td>
                                    <label htmlFor={`contactquote-weight-${item}`}>Weight (kg)</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="number"
                                      id={`contactquote-length-${item}`}
                                      name={`contactquote-length-${item}`}
                                      className="homequote-input"
                                      autoComplete="off"
                                      placeholder="In cm"
                                      value={(formData.items[item] && formData.items[item].length) || ""}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              length: e.target.value
                                            }
                                          }
                                        })
                                      }
                                      disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                      required={!disableDimensionsTypes.includes(itemTypes[item])}
                                      style={errors[`contactquote-length-${item}`] ? { border: "1px solid red" } : {}}
                                      onFocus={() => {
                                        if (errors[`contactquote-length-${item}`]) {
                                          const newErr = { ...errors };
                                          delete newErr[`contactquote-length-${item}`];
                                          setErrors(newErr);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      id={`contactquote-width-${item}`}
                                      name={`contactquote-width-${item}`}
                                      className="homequote-input"
                                      placeholder="In cm"
                                      value={(formData.items[item] && formData.items[item].width) || ""}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              width: e.target.value,
                                            },
                                          },
                                        })
                                      }
                                      disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                      required={!disableDimensionsTypes.includes(itemTypes[item])}
                                      style={errors[`contactquote-width-${item}`] ? { border: "1px solid red" } : {}}
                                      onFocus={() => {
                                        if (errors[`contactquote-width-${item}`]) {
                                          const newErr = { ...errors };
                                          delete newErr[`contactquote-width-${item}`];
                                          setErrors(newErr);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      id={`contactquote-height-${item}`}
                                      name={`contactquote-height-${item}`}
                                      className="homequote-input"
                                      placeholder="In cm"
                                      value={(formData.items[item] && formData.items[item].height) || ""}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              height: e.target.value,
                                            },
                                          },
                                        })
                                      }
                                      disabled={disableDimensionsTypes.includes(itemTypes[item])}
                                      required={!disableDimensionsTypes.includes(itemTypes[item])}
                                      style={errors[`contactquote-height-${item}`] ? { border: "1px solid red" } : {}}
                                      onFocus={() => {
                                        if (errors[`contactquote-height-${item}`]) {
                                          const newErr = { ...errors };
                                          delete newErr[`contactquote-height-${item}`];
                                          setErrors(newErr);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      id={`contactquote-weight-${item}`}
                                      name={`contactquote-weight-${item}`}
                                      className="homequote-input"
                                      autoComplete="off"
                                      placeholder="In kg"
                                      value={(formData.items[item] && formData.items[item].weight) || ""}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          items: {
                                            ...formData.items,
                                            [item]: {
                                              ...formData.items[item],
                                              weight: e.target.value
                                            }
                                          }
                                        })
                                      }
                                      required
                                      style={errors[`contactquote-weight-${item}`] ? { border: "1px solid red" } : {}}
                                      onFocus={() => {
                                        if (errors[`contactquote-weight-${item}`]) {
                                          const newErr = { ...errors };
                                          delete newErr[`contactquote-weight-${item}`];
                                          setErrors(newErr);
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {items.length > 1 && (
                              <div>
                                <button type="button" onClick={() => deleteItem(item)} className="delete-button">
                                  <span className="delete-icon"><i className="fas fa-trash"></i></span>
                                  <span className="delete-text">Delete Item</span>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        <hr className="homequote-divider" />
                        <table className="homequote-table homequote-button-table">
                          <tbody>
                            <tr>
                              <td>
                                {items.length < 5 && (
                                  <button
                                    type="button"
                                    className="homequote-btn homequote-btn-add"
                                    onClick={addItem}
                                  >
                                    + Add Item
                                  </button>
                                )}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  type="button"
                                  className="homequote-btn homequote-btn-quote"
                                  onClick={handleGetQuote}
                                >
                                  Get Quote
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}

                    {formStep === 2 && (
                      <>
                        <table className="homequote-table">
                          <tbody>
                            <tr>
                              <td>
                                <label htmlFor="contactquote-name">
                                  Name <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                </label>
                              </td>
                              <td>
                                <label htmlFor="contactquote-email">
                                  Email <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  id="contactquote-name"
                                  name="contactquote-name"
                                  className="homequote-input"
                                  placeholder="Your name"
                                  value={formData.name}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData({ ...formData, name: value });
                                    if (value.trim() !== "" && errors["contactquote-name"]) {
                                      const newErr = { ...errors };
                                      delete newErr["contactquote-name"];
                                      setErrors(newErr);
                                    }
                                  }}
                                  style={errors["contactquote-name"] ? { border: "1px solid red" } : {}}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="contactquote-email"
                                  name="contactquote-email"
                                  className="homequote-input"
                                  placeholder="Your Email"
                                  value={formData.email}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData({ ...formData, email: value });
                                    if (value.trim() !== "" && errors["contactquote-email"]) {
                                      const newErr = { ...errors };
                                      delete newErr["contactquote-email"];
                                      setErrors(newErr);
                                    }
                                  }}
                                  style={errors["contactquote-email"] ? { border: "1px solid red" } : {}}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="contactquote-phone">
                                  Phone <span style={{ fontSize: "0.6rem" }}>(Required)</span>
                                </label>
                              </td>
                              <td>
                                <label htmlFor="contactquote-company">Company</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  id="contactquote-phone"
                                  name="contactquote-phone"
                                  className="homequote-input"
                                  placeholder="Your Phone"
                                  value={formData.phone}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData({ ...formData, phone: value });
                                    if (value.trim() !== "" && errors["contactquote-phone"]) {
                                      const newErr = { ...errors };
                                      delete newErr["contactquote-phone"];
                                      setErrors(newErr);
                                    }
                                  }}
                                  style={errors["contactquote-phone"] ? { border: "1px solid red" } : {}}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="contactquote-company"
                                  name="contactquote-company"
                                  className="homequote-input"
                                  placeholder="Your Company"
                                  value={formData.company}
                                  onChange={(e) =>
                                    setFormData({ ...formData, company: e.target.value })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <label htmlFor="contactquote-comments">Comments</label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <textarea
                                  id="contactquote-comments"
                                  name="contactquote-comments"
                                  className="homequote-input"
                                  placeholder="Your Comments"
                                  rows="4"
                                  maxLength={280}
                                  value={formData.comments}
                                  onChange={(e) =>
                                    setFormData({ ...formData, comments: e.target.value })
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="homequote-table homequote-button-table">
                          <tbody>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="homequote-btn go-back-button"
                                  onClick={() => setFormStep(1)}
                                >
                                  Go Back
                                </button>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  type="submit"
                                  className="homequote-btn homequote-btn-quote submit-btn-quote"
                                  disabled={isSubmitting}
                                  style={isSubmitting ? { backgroundColor: 'green', cursor: 'not-allowed' } : {}}
                                >
                                  Submit Quote
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isVisible && !isMobile && (
          <div className="scroll-up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default GetInTouch;