import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Firearms() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);

  // Scroll listener for showing/hiding the scroll-up button
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollUpVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll-to-top function
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Resize listener to update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Hide scrollbar at top for desktop only
  useEffect(() => {
    if (isMobile) return;
    const handleScrollbar = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScrollbar);
    handleScrollbar();
    return () => window.removeEventListener('scroll', handleScrollbar);
  }, [isMobile]);

  // Show floating scroll-up button on desktop only
  useEffect(() => {
    if (isMobile) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  // Intersection Observer for fade-in effects
  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);
    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Highlight the "goods and products" nav link
  useEffect(() => {
    const navLink = document.querySelector('a[href="/goods-and-products"]');
    if (navLink) {
      navLink.classList.add('active');
    }
    return () => {
      if (navLink) {
        navLink.classList.remove('active');
      }
    };
  }, []);

  // FAQ toggle handler
  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(null);
      setTimeout(() => setOpenFAQ(index), 100);
    }
  };

  return (
    <div className="brisbane-main-content">
      <Helmet>
        <title>Firearms Freight Services | Secure & Compliant Transport by QFM</title>
        <meta
          name="description"
          content="QFM provides secure and legally compliant firearms freight services, ensuring safe transportation of firearms across Australia with strict adherence to regulations."
        />
        <meta
          name="keywords"
          content="Firearms Freight Services, Secure Gun Transport, Licensed Firearms Logistics, Ammunition Shipping, Weapons Transport Australia, Regulated Cargo, QFM Freight Solutions"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Firearms Freight Services | Secure & Compliant Transport by QFM" />
        <meta
          property="og:description"
          content="QFM offers licensed firearms freight services, ensuring legal and secure transportation of firearms across Australia. Trust us for professional handling and compliance."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-commodity-firearms.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/firearms-freight-services" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/firearms-freight-services" />
        {/* NEED TO WORK ON THIS */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://qfmlogistics.com.au/firearms-freight-services#webpage",
                "url": "https://qfmlogistics.com.au/firearms-freight-services",
                "name": "Firearms Freight Services | Secure Transport by QFM",
                "description": "QFM provides secure and compliant firearms freight services for licensed businesses across Australia. Our logistics solutions ensure safe and professional transport of firearms while adhering to strict regulations.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/firearms-freight-services#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": ["https://www.linkedin.com/company/qfm-logistics"]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/firearms-freight-services#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Goods and Products",
                    "item": "https://qfmlogistics.com.au/goods-and-products"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Firearms Freight Services",
                    "item": "https://qfmlogistics.com.au/firearms-freight-services"
                  }
                ]
              },
              {
                "@type": "Service",
                "@id": "https://qfmlogistics.com.au/firearms-freight-services#service",
                "name": "Firearms Freight Services",
                "description": "QFM offers compliant firearms freight services exclusively for licensed businesses, ensuring secure and regulated transport across Australia.",
                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                "areaServed": "Australia",
                "availableChannel": {
                  "@type": "ServiceChannel",
                  "serviceUrl": "https://qfmlogistics.com.au/contact-us"
                },
                "image": [
                  "https://qfmlogistics.com.au/images/qfm-commodity-firearms.jpg"
                ],
                "potentialAction": {
                  "@type": "Action",
                  "name": "Request a Quote",
                  "url": "https://qfmlogistics.com.au/contact-us",
                  "description": "Get a customised quote for your firearms freight requirements."
                }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-firearms.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-firearms.jpg",
                "description": "Secure and compliant transport of firearms for licensed businesses across Australia.",
                "name": "Firearms Freight Services - Secure Logistics by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/firearms-freight-services#webpage" }
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-commodity-firearms-two.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-commodity-firearms-two.jpg",
                "description": "Professional and compliant transport solutions for firearms, ensuring secure and lawful delivery across Australia.",
                "name": "Firearms Freight Services - Secure & Regulated Logistics by QFM",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions",
                "isPartOf": { "@id": "https://qfmlogistics.com.au/firearms-freight-services#webpage" }
              },
              {
                "@type": "FAQPage",
                "@id": "https://qfmlogistics.com.au/firearms-freight-services#faq",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "Who can use QFM's firearms freight services?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our firearms freight services are available exclusively for licensed businesses, including firearm dealers, manufacturers, and law enforcement agencies."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Do you transport ammunition or explosives?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, we do not transport ammunition, explosives, or any hazardous materials. Our services strictly focus on compliant firearms transport for licensed businesses."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which locations do you service for firearms freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide firearms freight services across major Australian cities and regional areas, ensuring secure transport solutions for businesses nationwide."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I get a quote for firearms freight?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To request a quote, visit our <a href='https://qfmlogistics.com.au/contact-us'>Contact Us</a> page or call our support team with details about your shipment."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What are your compliance requirements for firearms transport?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We strictly adhere to Australian firearms transport regulations. Customers must provide valid licensing and adhere to all legal transport requirements before shipping firearms."
                    }
                  }
                ],
                "isPartOf": { "@id": "https://qfmlogistics.com.au/firearms-freight-services#webpage" }
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-C758R2N8C5');
          `}
        </script>
      </Helmet>

      <div className="ourservices-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && <div className="vertical-line"></div>}

        {/* Intro Section */}
        <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
          <div className="melbourne-intro-section-text-content">
            <h1>Firearms Freight Services</h1>
            <p>
              QFM offers licensed and compliant firearms freight services, ensuring the secure transport of firearms across Australia. We understand the strict legal requirements surrounding firearm logistics and adhere to all regulatory guidelines to guarantee safe and authorised handling.
            </p>
            {!isMobile && (
              <p>
                Our services cater to licensed firearm dealers, law enforcement agencies, and authorised businesses requiring reliable transport solutions. With a nationwide logistics network, we ensure firearms are moved discreetly, securely, and in accordance with all legal requirements.
                Our transport process follows strict chain-of-custody procedures, minimising risks while ensuring firearms are delivered safely and efficiently to their approved destinations.
              </p>
            )}
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="images/qfm-commodity-firearms.jpg"
              alt="Licensed and compliant freight solutions for secure firearms transport across Australia"
              title="Firearms Freight Services - Secure and Compliant Transport by Quan Freight Management"
              loading="lazy"
            />
          </div>
        </div>

        {/* Quote/Call-to-Action Section */}
        <div className="default-quote appearing-effect">
          <div className="default-quote-quote">
            <Link to="/contact-us">
              <h4>Request a Quote</h4>
            </Link>
          </div>
          <div className="default-quote-text-content">
            {!isMobile && (
              <h5>Licensed & Secure Firearms Transport Solutions</h5>
            )}
            <p>
              Our specialised freight services ensure that firearms are transported securely, discreetly, and in full compliance with Australian regulations.
              <Link to="/goods-and-products"> Explore our full range of logistics solutions</Link>.
            </p>
          </div>
        </div>

        {/* Main Content with Second Image */}
        <div className="default-section-content-left">
          <div className="default-section-content-left-text-content appearing-effect sydney-add">
            <h2>Melbourne Firearms Freight by QFM</h2>
            <ul>
              <li>
                <b>Licensed & Compliant Transport:</b> We adhere to all Australian firearms transportation regulations, ensuring secure and lawful freight solutions.
              </li>
              <li>
                <b>Secure Handling & Transit:</b> Every shipment is transported in locked, tamper-proof containers with strict chain-of-custody protocols.
              </li>
              <li>
                <b>Discreet & Professional Service:</b> Our team ensures confidentiality and professionalism when transporting firearms for licensed dealers  and law enforcement, maintaining secure logistics at every step.
              </li>
              <li>
                <b>Nationwide Coverage:</b> Reliable delivery across Melbourne, Sydney, Brisbane, Perth, and regional areas, supporting licensed firearm dealers and registered businesses.
              </li>
            </ul>
          </div>
          <div className="default-section-content-left-image-container appearing-effect">
            <img
              src="images/qfm-commodity-firearms-two.jpg"
              loading="lazy"
              alt="Trusted and compliant transport solutions for licensed firearms across Australia"
              title="Firearms Freight Services - Secure and Regulated Transport by Quan Freight Management"
            />
            {isMobile && (
              <div className="default-quote appearing-effect">
                <div className="default-quote-quote">
                  <Link to="/goods-and-products">
                    <h4>View all Commodities</h4>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="general-faq-section appearing-effect">
          <div className="faq-text-content">
            <h2>FAQ</h2>

            {/* FAQ 1 - B2B Only */}
            <div className={`faq-item ${openFAQ === 0 ? "active" : ""}`} onClick={() => toggleFAQ(0)}>
              <h5>Do you transport firearms for private individuals?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}>
                <p>
                  No, we only provide firearms freight services for licensed businesses. Our services are strictly B2B, catering to firearm dealers, wholesalers, licensed security firms, and government agencies. We do not handle individual firearm shipments.
                </p>
              </div>
            </div>

            {/* FAQ 2 - Types of Firearms */}
            <div className={`faq-item ${openFAQ === 1 ? "active" : ""}`} onClick={() => toggleFAQ(1)}>
              <h5>What types of firearms do you transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}>
                <p>
                  We provide secure and compliant transport for legally licensed firearms, including handguns, rifles, and shotguns. All shipments must meet state and federal regulations, with proper documentation and licensing from both sender and recipient.
                </p>
              </div>
            </div>

            {/* FAQ 4 - Ammunition & Explosives */}
            <div className={`faq-item ${openFAQ === 3 ? "active" : ""}`} onClick={() => toggleFAQ(3)}>
              <h5>Do you transport ammunition or explosives?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 3 ? "300px" : "0px" }}>
                <p>
                  No, we do not transport ammunition, explosives, or any hazardous firearm-related materials. Our services strictly focus on the secure transport of legally licensed firearms in compliance with Australian regulations.
                </p>
              </div>
            </div>

            {/* FAQ 5 - Service Coverage */}
            <div className={`faq-item ${openFAQ === 4 ? "active" : ""}`} onClick={() => toggleFAQ(4)}>
              <h5>Which regions do you cover for firearms transport?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 4 ? "300px" : "0px" }}>
                <p>
                  We offer firearms freight services nationwide, covering Melbourne, Sydney, Brisbane, Perth, Adelaide, and major regional areas. Our logistics network ensures firearms are transported in compliance with all state and territory laws.
                </p>
              </div>
            </div>

            {/* FAQ 6 - Request a Quote */}
            <div className={`faq-item ${openFAQ === 5 ? "active" : ""}`} onClick={() => toggleFAQ(5)}>
              <h5>How can I request a firearms freight quote?</h5>
              <div className="faq-content" style={{ maxHeight: openFAQ === 5 ? "300px" : "0px" }}>
                <p>
                  Visit our <Link to="/contact-us">Contact Us</Link> page and provide your business details, shipment type, and locations. Our team will assess compliance requirements and provide a tailored quote.
                </p>
              </div>
            </div>
          </div>
        </div>




        {
          isVisible && (
            <div className="scroll-up" onClick={handleScrollToTop}>
              <i className="fas fa-chevron-up"></i>
            </div>
          )
        }
        {
          isMobile && isScrollUpVisible && (
            <div className="scroll-up-mobile" onClick={handleScrollToTop}>
              <i className="fas fa-chevron-up"></i>
            </div>
          )
        }

        <Footer />
      </div >
    </div >
  );
}

export default Firearms;